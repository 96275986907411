import {axios} from '@/utils/request'
//投保模块

//得到所有方案简介
export const getInsuranceList = () => {
    return axios.request({
        url: '/getInsuranceList',
        method: 'POST'
    })
};

//得到所有方案简介,包括方案里的套餐
export const getAllInsuranceDetail = () => {
    return axios.request({
        url: '/getAllInsuranceDetail',
        method: 'POST'
    })
};

//进入下单
export const placeOrder = (insuranceId) => {
    console.log("test order");
    return axios.request({
        url: '/placeOrder',
        params:{"insuranceId":insuranceId},
        method: 'POST'
    })
};


//下单
export const createOrder = (data) => {
console.log("data的数据");
console.log(data);
    return axios.request({
        url: '/createOrder',
        data: data,
        method: 'POST'
    })
};






