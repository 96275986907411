import {axios} from '@/utils/request'


export const uploaderHandle = (fileData) => {
    let formData = new FormData();
    formData.append('fileData', fileData);

    return axios.request({
        url: '/uploader/handle',
        data: formData,
        method: 'POST',
    })
};

export const findExportPage = (data) => {
    return axios.request({
        url: '/uploader/exportPage',
        data: data,
        method: 'POST'
    })
};
