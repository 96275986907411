import {axios} from '@/utils/request'
import {deleteEmptyProperty} from '@/utils/util'
//发票模块

//得到经过处理的公司信息
export const getPreInfo = (orderId) => {
    return axios.request({
        url: '/getPreInfo',
        params:{"orderId":orderId},
        method: 'POST'
    })
};
//请求开发票
export const createInvoice = (data) => {
    console.log("请求开票");
    return axios.request({
        url: '/createInvoice',
        data:data,
        method: 'POST'
    })
};






