export default {
    //token在Cookie中存储的天数，默认1天
    cookieExpires: 30,
    //数据接口
    baseServerHostUrl: {
        dev:  'https://server.jiazhengxian.com',
        prod: process.env.VUE_APP_SERVEURL
    },
    //webapp地址
    webappUrl:{
        dev:  'http://localhost:8002',
        prod: process.env.VUE_APP_BASEURL
    }
}