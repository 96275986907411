<template>
     <div class="qui-layout">
             <div class="qui-header qui-header-black gracolor">
                <h2 class="qui-fs35">首页</h2>
                <div class="left">
                    <a href="javascript:history.go(-1)"><i class="iconfont icon-fanhui"></i></a>
                </div>

            </div>

            <div class="qui-pd-0-30">
                <iframe   :src="indexUrl" name = "iframeChild" id = "iframeChild"
                width="100%" :height="iframeHeight"
                frameborder="0" scrolling="auto" ref="iframeDom">
                </iframe>
            </div>



            <Bottom v-bind:activeLi=activeIndex></Bottom>
        </div>

</template>
<script>
    import config from '@/config'
    import Bottom from './Bottom'
    export default {
        components: {Bottom},
        data: function () {
            return {
                webappUrl:process.env.NODE_ENV === 'development' ? config.webappUrl.dev : config.webappUrl.prod,
                indexUrl:null,
                iframeHeight:0,
            }
        },
        created() {
            this.indexUrl = this.webappUrl+"/static/index.html";
        },
         mounted: function () {
            this.$nextTick(() => {
              if(this.$refs.iframeDom)
                this.iframeHeight = window.innerHeight;
            })

          },
    }
</script>

<style>

</style>


