import {axios} from '@/utils/request'
import {deleteEmptyProperty} from '@/utils/util'
//个人中心模块

//得到个人信息
export const getUserInfo = () => {
    return axios.request({
        url: '/getUserInfo',
        method: 'POST'
    })
};

export const updateUserInfo = (data) => {
    console.log('test');
    console.log(data);
    return axios.request({
        url: '/updateUserInfo',
        data: data,
        method: 'POST'
    })
};







