import Vue from 'vue'
import {ACCESS_TOKEN} from '@/store/mutation-types'
import {getOauthInfo, login, logout} from '@/api/oauth'

const user = {
    state: {
        oauthInfo: {},
        authorities: '',
    },

    mutations: {
        SET_OAUTH_INFO: (state, oauthInfo) => {
            state.oauthInfo = oauthInfo
        },
        SET_AUTHORITIES: (state, authorities) => {
            state.authorities = authorities
        }
    },

    actions: {
        getOauthInfo({commit}) {
            return new Promise((resolve, reject) => {
                getOauthInfo().then(oauthInfo => {
                    commit('SET_OAUTH_INFO', oauthInfo);
                    commit('SET_AUTHORITIES', oauthInfo.authorities);
                    Vue.ls.set(ACCESS_TOKEN, oauthInfo.accessToken, 7 * 24 * 60 * 60 * 1000)
                    resolve(oauthInfo);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        Login({commit}, {loginAccount, loginPassword, captcha, accessToken}) {
            return new Promise((resolve, reject) => {
                login({
                    loginAccount, loginPassword, captcha, accessToken
                }).then(oauthInfo => {
                    commit('SET_OAUTH_INFO', oauthInfo);
                    commit('SET_AUTHORITIES', oauthInfo.authorities);
                    Vue.ls.set(ACCESS_TOKEN, oauthInfo.accessToken, 7 * 24 * 60 * 60 * 1000)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 登出
        Logout({commit}) {
            return new Promise((resolve) => {
                logout().finally(() => {
                    commit('SET_OAUTH_INFO', {});
                    commit('SET_AUTHORITIES', []);
                    Vue.ls.remove(ACCESS_TOKEN);
                    resolve()
                });
            })
        }

    }
}

export default user
