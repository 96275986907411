<template>
    <a-modal
            :title="'保障内容'"
            :width="550"
            :height="500"
            v-model="visible"
            :footer='null'>
        <div>
        <div class="Section0" style="layout-grid:15.6000pt;"><p class="MsoNormal" align="center" style="text-align:center;line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
        mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;
        font-size:22.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">中国</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:22.0000pt;
        mso-font-kerning:1.0000pt;"><font face="宋体">人民财产保险股份有限公司</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:22.0000pt;
        mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" align="center" style="text-align:center;line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
        mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;
        font-size:22.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">家政</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:22.0000pt;
        mso-font-kerning:1.0000pt;"><font face="宋体">服务机构责任保险</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:22.0000pt;
        mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" align="center" style="text-align:center;line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:22.0000pt;
        mso-font-kerning:1.0000pt;"><font face="宋体">附加家政服务人员责任保险条款</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:22.0000pt;
        mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" align="center" style="text-align:center;line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:22.0000pt;
        mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></b></p><p class="MsoNormal" align="center" style="text-align:center;line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">投保附加险的条件</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="text-indent:32.1500pt;mso-char-indent-count:2.0000;line-height:30.0000pt;
        mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">第一条</font> </span></b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">本条款为《中国人民财产保险股份有限公司家政服务机构责任保险》（以下</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">简称主险</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">）的附加险条款，只有在投保了主险的基础上，方可投保本附加险。</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" align="center" style="margin-top:15.6000pt;mso-para-margin-top:1.0000gd;text-align:center;
        line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">保险</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">责任</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="text-indent:33.0000pt;line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">第二条</font> </span></b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">在保险期间内，被保险人的家政服务人员按照被保险人的分配</font>,从事接受服务家庭指定的家政服务工作时,因其</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">故意行为</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">造成第三者人身损害或</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">财产损失</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">的，依法应由被保险人承担的经济赔偿责任，保险人按照本附加险合同约定负责赔偿。</font></span><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="text-indent:33.0000pt;line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">第三条</font> </span></b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">在保险期间内，被保险人的家政服务人员按照被保险人的分配，从事接受服务家庭指定的家政服务工作时，发生</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">意外事故造成人身损害的</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">，依法应由被保险人承担的经济赔偿责任，保险人按照本附加险合同约定也负责赔偿。</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="text-indent:33.0000pt;line-height:30.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">上述</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">两项保险责任投保人</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">可</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">分别投保，也可同时投保，</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">具体</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">以保险单载明为准。</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" align="center" style="margin-top:15.6000pt;mso-para-margin-top:1.0000gd;text-align:center;
        line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">责任</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">免除</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="text-indent:32.1500pt;mso-char-indent-count:2.0000;text-align:left;
        line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">第四条</font> <font face="仿宋_GB2312">下列原因造成的损失、费用和责任，保险人不负责赔偿：</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（一）家政服务人员犯罪、自杀自残、斗殴，或因受酒精、毒品、药品影响造成自身人身伤亡的；</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（二）家政服务人员因疾病（包括职业病）、分娩、流产以及因上述原因接受医疗救治的；</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（三）家政服务人员无有效驾驶证驾驶机动车辆或无有效资格证书而使用各种专用机械、特种设备、特种车辆或类似设备装置，造成自身人身伤亡的。</font></span></b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="text-indent:32.1500pt;mso-char-indent-count:2.0000;line-height:30.0000pt;
        mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">第五条</font></span></b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">下列损失、费用和责任，保险人不负责赔偿：</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（一）保险人按照投保时被保险人提供的家政服务人员名单承担赔偿责任，被保险人对名单范围以外的家政服务人员承担的赔偿责任，保险人不负责赔偿；</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（二）精神损害赔偿；</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（三）超出家政服务人员所在地工伤保险诊疗项目目录、工伤保险药品目录、工伤保险住院服务标准的医疗费用；</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（四）工伤保险已经支付的医疗费用；</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（五）家政服务人员在中华人民共和国境外以及港、澳、台地区发生的人身伤亡；</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（六）本保险单中载明的免赔额。</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" align="center" style="margin-top:15.6000pt;mso-para-margin-top:1.0000gd;text-align:center;
        line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">责任限额与</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">免赔额</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="text-indent:32.1500pt;mso-char-indent-count:2.0000;text-align:left;
        line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">第六条</font></span></b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="仿宋_GB2312">除另有约定外，本附加险责任限额包括每次事故责任限额、每人伤亡责任限额、每人医疗费用责任限额。</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">各项责任限额由投保人和保险人协商确定，并在保险单中载明。</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="text-indent:32.1500pt;mso-char-indent-count:2.0000;text-align:left;
        line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">第七条</font></span></b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;</span><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">每次事故每人医疗费用免赔额由投保人和保险人协商确定，并在保险单中载明。</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" align="center" style="margin-top:15.6000pt;mso-para-margin-top:1.0000gd;text-align:center;
        line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">赔偿处理</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="text-indent:32.1500pt;mso-char-indent-count:2.0000;text-align:left;
        line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">第八条</font></span></b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="仿宋_GB2312">被保险人向保险人请求赔偿时，应提交下列索赔材料：</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（一）保险单；</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（二）索赔申请书；</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（三）能够确认被保险人与受伤害家政服务人员存在劳动关系的人事、薪资证明；</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（四）该家政服务人员就医治疗的诊疗证明、病历（原件）及医疗费用原始单据；该家政服务人员残疾的，由保险人认可的伤残鉴定机构出具的伤残程度证明；该家政服务人员死亡的，由公安机关或医疗机构出具的死亡证明；宣告死亡的，由人民法院出具的宣告死亡判决；</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（五）被保险人与该家政服务人员或其代理人签订的赔偿协议书或和解书，经判决或仲裁的，应提供判决文书或仲裁裁决文书；</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（六）投保人、被保险人所能提供的其他与确认保险事故性质、原因、损失程度等有关的证明和资料。</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="text-indent:24.1000pt;mso-char-indent-count:1.5000;text-align:left;
        line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">第九条</font> </span></b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">发生</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">保险责任第二条</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">约定</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">的</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">保险</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">事故后，</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">保险人在</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">主险的各项限额内</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">，</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">按照主险约定的赔偿方式进行赔偿。</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="text-indent:24.1000pt;mso-char-indent-count:1.5000;line-height:30.0000pt;
        mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">第十条</font></span></b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="仿宋_GB2312">发生保险</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">责任第三条约定的保险事故后</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">，保险人按照以下方式计算赔偿</font>:</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（一）家政服务人员死亡的，保险人按照保险单载明的每人伤亡责任限额赔偿；</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（二）家政服务人员残疾的，由保险人认可的伤残鉴定机构依据职工工伤与职业病致残等级的现行国家标准鉴定残疾程度，保险人按照本保险合同所附残疾赔偿比例表规定的百分比，乘以每人伤亡责任限额赔偿；</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（三）家政服务人员暂时丧失工作能力超过五天（不包括五天）的，经二级以上（含）或保险人认可的医疗机构证明，保险人依据所在地的最低工资标准，按照每人／天补助误工费用，医疗期满或确定残疾程度后停发，最长不超过</font>365天；如最终鉴定为残疾的，保险人对残疾赔偿金与误工费用的赔偿金额之和，以本条第（二）款计算的责任限额为限；</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（四）被保险人承担的诊疗项目、药品、住院服务及辅助器具配置费用，保险人均按照国家工伤保险待遇规定的标准，在依据本款下列第</font>1项至第4项计算的基础上，扣除每次事故每人医疗费用免赔额，在每人医疗费用责任限额内据实赔偿。除另有约定外，医疗费用具体项目包括：</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">1.挂号费、治疗费、手术费、检查费、医药费；</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">2.住院期间的床位费、医院护理</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">人员</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">陪护费、患者伙食费、取暖费、空调费；</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">3.就（转）诊交通费、急救车费；</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">4.安装假肢、假牙、假眼和残疾用具费用。</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">除紧急抢救外，家政服务人员均应在二级以上（含）或保险人认可的医疗机构就诊。</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">（五）对每次事故造成的损失，保险人在本附加险每次事故责任限额内负责赔偿；在保险期间内，保险人对主险及附加险的累计赔偿金额不超过主险累计责任限额。</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" align="center" style="margin-top:15.6000pt;mso-para-margin-top:1.0000gd;text-align:center;
        line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">其他</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">事项</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;<font face="仿宋_GB2312">第</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">十</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">一</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">条</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;">&nbsp;</span></b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">发生保险责任范围内的损失，应由有关责任方负责赔偿的，被保险人应行使或者保留向该责任方请求赔偿的权利。</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="text-indent:32.0000pt;mso-char-indent-count:2.0000;line-height:30.0000pt;
        mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">在保险人向有关责任方行使代位请求赔偿权利时，被保险人应当向保险人提供必要的文件和其所知道的有关情况。</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" align="center" style="margin-top:15.6000pt;mso-para-margin-top:1.0000gd;text-align:center;
        line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">主险与附加险的关系</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="text-align:left;line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;<font face="仿宋_GB2312">第十二条</font></span></b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="仿宋_GB2312">凡涉及本附加险合同的约定，均应采用书面形式。主险合同与本附加险合同相抵触之处，以本附加险合同为准；本附加险合同未约定事项，以主险合同为准。主险合同效力终止，本附加险合同效力亦同时终止；主险合同无效，本附加险合同亦无效。</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="mso-pagination:widow-orphan;text-align:left;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><br clear="all" style="page-break-before:always"></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:16.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-bottom:7.8000pt;mso-para-margin-bottom:0.5000gd;line-height:30.0000pt;
        mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">附表：</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;">&nbsp;<font face="仿宋_GB2312">残疾赔偿比例表</font></span></b><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><div align="center"><table class="MsoNormalTable" border="1" cellspacing="0" style="border-collapse:collapse;width:385.2500pt;mso-table-layout-alt:fixed;
        border:none;mso-border-left-alt:0.5000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;
        mso-border-right-alt:0.5000pt solid windowtext;mso-border-bottom-alt:0.5000pt solid windowtext;mso-border-insideh:0.5000pt solid windowtext;
        mso-border-insidev:0.5000pt solid windowtext;mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;"><tbody><tr><td width="279" valign="center" style="width:209.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
        mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="center" style="text-align:center;line-height:20.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:14.0000pt;
        mso-font-kerning:1.0000pt;">残疾程度</span></b><b><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-weight:bold;font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p></td><td width="233" valign="center" style="width:175.4500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
        mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="center" style="text-align:center;line-height:20.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:14.0000pt;
        mso-font-kerning:1.0000pt;">每次事故每人伤亡</span></b><b><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-weight:bold;font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" align="center" style="text-align:center;line-height:20.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:14.0000pt;
        mso-font-kerning:1.0000pt;">责任限额的百分比</span></b><b><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-weight:bold;font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p></td></tr><tr><td width="279" valign="center" style="width:209.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
        mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" style="text-align:left;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">永久丧失工作能力或一级伤残</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="233" valign="top" style="width:175.4500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
        mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="right" style="text-align:right;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">100％</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="279" valign="center" style="width:209.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
        mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" style="text-align:left;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">二级伤残</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="233" valign="top" style="width:175.4500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
        mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="right" style="text-align:right;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;">8</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;">5</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;">％</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="279" valign="center" style="width:209.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
        mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" style="text-align:left;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">三级伤残</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="233" valign="top" style="width:175.4500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
        mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="right" style="text-align:right;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">65</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;">％</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="279" valign="center" style="width:209.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
        mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" style="text-align:left;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">四级伤残</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="233" valign="top" style="width:175.4500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
        mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="right" style="text-align:right;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">55</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;">％</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="279" valign="center" style="width:209.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
        mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" style="text-align:left;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">五级伤残</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="233" valign="top" style="width:175.4500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
        mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="right" style="text-align:right;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">45</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;">％</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="279" valign="center" style="width:209.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
        mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" style="text-align:left;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">六级伤残</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="233" valign="top" style="width:175.4500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
        mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="right" style="text-align:right;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">25</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;">％</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="279" valign="center" style="width:209.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
        mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" style="text-align:left;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">七级伤残</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="233" valign="top" style="width:175.4500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
        mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="right" style="text-align:right;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">15</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;">％</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="279" valign="center" style="width:209.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
        mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" style="text-align:left;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">八级伤残</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="233" valign="top" style="width:175.4500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
        mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="right" style="text-align:right;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;">1</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">0％</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="279" valign="center" style="width:209.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
        mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" style="text-align:left;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">九级伤残</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="233" valign="top" style="width:175.4500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
        mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="right" style="text-align:right;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;">4</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;">％</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="279" valign="center" style="width:209.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
        mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" style="text-align:left;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:14.0000pt;mso-font-kerning:1.0000pt;">十级伤残</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="233" valign="top" style="width:175.4500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
        mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
        border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
        mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="right" style="text-align:right;line-height:20.0000pt;mso-line-height-rule:exactly;"><span style="font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;">1</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;">％</span><span style="font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:'Times New Roman';
        font-size:14.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr></tbody></table></div><p class="MsoNormal" style="text-align:left;line-height:30.0000pt;mso-line-height-rule:exactly;"><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">注</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">：</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">残疾</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">程度</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">分级</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">参考</font></span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="仿宋_GB2312">《劳动能力鉴定、职工工伤与职业病致残程度鉴定》（</font>GB/T16180-2014）。</span><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="line-height:30.0000pt;mso-line-height-rule:exactly;"><b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
        mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:16.0000pt;
        mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></b></p></div>
        </div>
    </a-modal>
</template>
<script>

    export default {

        data: function () {
            return {
                visible: false,

                pic:null,
                // 表头

                loadData: parameter => {

                },
                selectedRows: [],
                selectedRowKeys: [],
            }
        },
        computed: {

        },
        beforeCreate() {
        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
        },
        mounted() {

        },
        destroyed() {
        },
        methods: {
            show(data) {
                this.pic = data;
                this.visible = true;
            },
        },
        watch: {}
    }
</script>
<style>
@font-face{
font-family:"Times New Roman";
}

@font-face{
font-family:"宋体";
}

@font-face{
font-family:"Calibri";
}

@font-face{
font-family:"Wingdings";
}

@font-face{
font-family:"仿宋_GB2312";
}

p.MsoNormal{
mso-style-name:正文;
mso-style-parent:"";
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:justify;
text-justify:inter-ideograph;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;
mso-font-kerning:1.0000pt;
}

span.10{
font-family:Calibri;
}

span.15{
font-family:Calibri;
}

span.16{
font-family:Calibri;
font-size:9.0000pt;
}

span.17{
font-family:Calibri;
font-size:9.0000pt;
}

span.18{
font-family:Calibri;
font-size:10.5000pt;
}

span.19{
font-family:Calibri;
font-weight:bold;
}

span.20{
font-family:Calibri;
font-size:9.0000pt;
}

p.MsoCommentText{
mso-style-name:批注文字;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:left;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;
mso-font-kerning:1.0000pt;
}

p.MsoCommentSubject{
mso-style-name:批注主题;
mso-style-noshow:yes;
mso-style-parent:批注文字;
mso-style-next:批注文字;
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:left;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-weight:bold;
font-size:10.5000pt;
mso-font-kerning:1.0000pt;
}

p.MsoHeader{
mso-style-name:页眉;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.7500pt solid windowtext;
padding:0pt 0pt 1pt 0pt ;
layout-grid-mode:char;
mso-pagination:none;
text-align:center;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;
mso-font-kerning:1.0000pt;
}

p.MsoFooter{
mso-style-name:页脚;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
layout-grid-mode:char;
mso-pagination:none;
text-align:left;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;
mso-font-kerning:1.0000pt;
}

p.MsoAcetate{
mso-style-name:批注框文本;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:justify;
text-justify:inter-ideograph;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;
mso-font-kerning:1.0000pt;
}

span.msoIns{
mso-style-type:export-only;
mso-style-name:"";
text-decoration:underline;
text-underline:single;
color:blue;
}

span.msoDel{
mso-style-type:export-only;
mso-style-name:"";
text-decoration:line-through;
color:red;
}

table.MsoNormalTable{
mso-style-name:普通表格;
mso-style-parent:"";
mso-style-noshow:yes;
mso-tstyle-rowband-size:0;
mso-tstyle-colband-size:0;
mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt;
mso-para-margin:0pt;
mso-para-margin-bottom:.0001pt;
mso-pagination:widow-orphan;
font-family:'Times New Roman';
font-size:10.0000pt;
mso-ansi-language:#0400;
mso-fareast-language:#0400;
mso-bidi-language:#0400;
}
@page{mso-page-border-surround-header:no;
	mso-page-border-surround-footer:no;}@page Section0{
margin-top:72.0000pt;
margin-bottom:72.0000pt;
margin-left:90.0000pt;
margin-right:90.0000pt;
size:595.3000pt 841.9000pt;
layout-grid:15.6000pt;
}
div.Section0{page:Section0;}
</style>