var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qui-fnav"},[_c('ul',{staticClass:"qui-fnav-wp imgend"},[_c('li',{class:{'active':_vm.activeLi == 1}},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.goOtherPage('Insured')}}},[_vm._m(0),_vm._m(1),_c('span',[_vm._v("在线投保")])])]),_c('li',{class:{'active':_vm.activeLi == 2}},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.goOtherPage('Introduction')}}},[_vm._m(2),_vm._m(3),_c('span',[_vm._v("方案介绍")])])]),_c('li',{class:{'active':_vm.activeLi == 3}},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.goOtherPage('MyOrder')}}},[_vm._m(4),_vm._m(5),_c('span',[_vm._v("我的订单")])])]),_c('li',{class:{'active':_vm.activeLi == 4}},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.goOtherPage('PersonalCenter')}}},[_vm._m(6),_vm._m(7),_c('span',[_vm._v("个人中心")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('sup',[_c('img',{attrs:{"src":require("../assets/imgs/fy06.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('sub',[_c('img',{attrs:{"src":require("../assets/imgs/fy06-1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('sup',[_c('img',{attrs:{"src":require("../assets/imgs/fy07.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('sub',[_c('img',{attrs:{"src":require("../assets/imgs/fy07-1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('sup',[_c('img',{attrs:{"src":require("../assets/imgs/fy08.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('sub',[_c('img',{attrs:{"src":require("../assets/imgs/fy08-1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('sup',[_c('img',{attrs:{"src":require("../assets/imgs/fy09.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('sub',[_c('img',{attrs:{"src":require("../assets/imgs/fy09-1.png")}})])
}]

export { render, staticRenderFns }