<template>
    <a-modal
            :title="'图片'"
            :width="550"
            :height="500"
            v-model="visible"
            :footer='null'>
        <div>
            <img  style="max-width:500px;max-height:500px" :src="baseServerHostUrl+'/uploader/showImage?src='+pic" />
        </div>
    </a-modal>
</template>
<script>

    import {ACCESS_TOKEN} from '@/store/mutation-types'
    import config from '@/config'
    import Vue from 'vue'
    export default {

        data: function () {
            return {
                visible: false,
                baseServerHostUrl:
                    process.env.NODE_ENV === 'development' ? config.baseServerHostUrl.dev : config.baseServerHostUrl.prod,
                pic:null,
                // 表头

            }
        },
        computed: {
            accessToken: function () {
                return Vue.ls.get(ACCESS_TOKEN);
            }
        },
        beforeCreate() {
        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
        },
        mounted() {

        },
        destroyed() {
        },
        methods: {
            show(data) {
                this.pic = data;
                this.visible = true;
            },
        },
        watch: {}
    }
</script>
