<template>
    <a-modal
            :title="'保障内容'"
            :width="550"
            :height="500"
            v-model="visible"
            :footer='null'>
        <div>


            <div class=WordSection1 style='layout-grid:15.6pt'>

            <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:22.0pt;font-family:宋体'>中国人民财产保险股份有限公司<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:22.0pt;font-family:宋体'>家政服务机构责任保险条款<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal align=center style='margin-top:7.8pt;mso-para-margin-top:
            .5gd;text-align:center;line-height:30.0pt;mso-line-height-rule:exactly'><b
            style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;font-family:
            仿宋_GB2312;mso-hansi-font-family:宋体'>总则<span lang=EN-US><o:p></o:p></span></span></b></p>



            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>第一条</span></b><span style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'> 本保险合同由保险条款、投保单、保险单、保险凭证以及批单组成，凡涉及本保险合同的约定，均应采用书面形式。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>第二条</span></b><span style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'> 凡依法经工商行政管理部门登记注册，以家政服务业为经营范围的家政服务机构，均可作为本保险合同的被保险人。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal align=center style='margin-top:7.8pt;mso-para-margin-top:
            .5gd;text-align:center;line-height:30.0pt;mso-line-height-rule:exactly'><b
            style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;font-family:
            仿宋_GB2312;mso-hansi-font-family:宋体'>保险责任<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>第三条</span></b><span style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'> 在保险期间内，被保险人的家政服务员按照被保险人的分配从事接受服务家庭指定的家政服务工作时，因过失造成接受服务家庭成员或第三者的人身伤亡或财产损失，依法应由被保险人承担的经济赔偿责任，保险人按照本保险合同约定负责赔偿。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>第四条 </span></b><span style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体;mso-bidi-font-weight:bold'>保险事故发生后，</span><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>被保险人因保险事故被提起仲裁或者诉讼的，</span><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>对应由被保险人支付的仲裁或诉讼费用以及其它必要的、合理的费用（简称“法律费用”），由保险人承担。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal align=center style='margin-top:7.8pt;mso-para-margin-top:
            .5gd;text-align:center;line-height:30.0pt;mso-line-height-rule:exactly'><b
            style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;font-family:
            仿宋_GB2312;mso-hansi-font-family:宋体'>责任免除<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>第五条</span></b><span style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'> <b style='mso-bidi-font-weight:normal'>下列原因造成的损失、费用和责任，保险人不负责赔偿：<span
            lang=EN-US><o:p></o:p></span></b></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>(</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>一<span
            lang=EN-US>) </span>被保险人或家政服务员的故意行为或重大过失行为、犯罪行为；<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>(</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>二<span
            lang=EN-US>) </span>接受服务家庭成员或第三者的故意行为、犯罪行为；<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>(</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>三<span
            lang=EN-US>) </span>家政服务员使用各种机动、非机动车辆；<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>(</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>四<span
            lang=EN-US>) </span>家政服务员未满<span lang=EN-US>16</span>周岁；<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>(</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>五<span
            lang=EN-US>) </span>家政服务员患有精神病、传染病；<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>(</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>六<span
            lang=EN-US>) </span></span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>家政服务员醉酒、吸毒、受精神药品影响或精神失常状态下；</span></b><b
            style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:16.0pt;
            font-family:仿宋_GB2312;mso-hansi-font-family:宋体'><o:p></o:p></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>(</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>七<span
            lang=EN-US>) </span>家政服务员从事被保险人或接受服务家庭分配或指定的工作以外的行为。 <span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>第六条 下列损失、费用和责任，保险人不负责赔偿：<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>(</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>一<span
            lang=EN-US>) </span>家政服务员在中华人民共和国境外（包括港、澳、台地区）造成的人身伤亡或财产损失；<span lang=EN-US> <o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>(</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>二<span
            lang=EN-US>) </span>被保险人或家政服务员的人身伤亡或其财产损失；<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>(</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>三<span
            lang=EN-US>) </span>被保险人根据与他人签订的协议应承担的责任，但即使没有这种协议，被保险人依法仍应承担的责任不在此限；<span
            lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:31.5pt;mso-char-indent-count:1.96'><b
            style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:16.0pt;
            font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>(</span></b><b
            style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;font-family:
            仿宋_GB2312;mso-hansi-font-family:宋体'>四<span lang=EN-US>) </span>艺术品、动植物、金银玉器、珠宝首饰、古玩字画、邮票货币、稀有金属、有价证券、文件账册、技术资料、电脑数据资料；</span></b><span
            lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;color:black'><o:p></o:p></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>(</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>五<span
            lang=EN-US>) </span>被保险人的间接损失；<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>(</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>六<span
            lang=EN-US>) </span>精神损害赔偿，但由法院判决支持的不在此限；<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>(</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>七<span
            lang=EN-US>) </span>本保险单中载明的免赔额。<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal align=center style='margin-top:7.8pt;mso-para-margin-top:
            .5gd;text-align:center;line-height:30.0pt;mso-line-height-rule:exactly'><b
            style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;font-family:
            仿宋_GB2312;mso-hansi-font-family:宋体'>责任限额与免赔额<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>第七条 </span></b><span style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>除另有约定外，责任限额包括累计责任限额、每次事故责任限额、每次事故每人伤亡责任限额、每次事故每人医疗费用责任限额、每次事故财产损失责任限额。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.0pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><span style='font-size:16.0pt;
            font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>各项责任限额由投保人和保险人协商确定，并在保险单中载明。<span lang=EN-US><o:p></o:p></span></span></p>

             <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>第八条 </span></b><span style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'> 每次事故财产损失免赔额及每次事故每人医疗费用免赔额由投保人与保险人在签订保险合同时协商确定，并在保险单中载明。<span
            lang=EN-US><o:p></o:p></span></span></p>



            <p class=MsoNormal align=center style='margin-top:7.8pt;mso-para-margin-top:
            .5gd;text-align:center;line-height:30.0pt;mso-line-height-rule:exactly'><b
            style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;font-family:
            仿宋_GB2312;mso-hansi-font-family:宋体'>保险期间<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>第九条 </span></b><span style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>除另有约定外，保险期间为一年，以保险单载明的起讫时间为准。<span lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal align=center style='text-align:center;line-height:30.0pt;
            mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>保险人义务<span
            lang=EN-US><o:p></o:p></span></span></b></p>



            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>第十条 </span></b><span style='font-size:16.0pt;font-family:仿宋_GB2312'>本保险合同成立后，保险人应当及时向投保人签发保险单或其他保险凭证。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>第十一条 </span></b><span style='font-size:16.0pt;font-family:仿宋_GB2312'>保险事故发生后，投保人、被保险人提供的有关索赔的证明和资料不完整的，保险人应当及时一次性通知投保人、被保险人补充提供。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312'>第十二条 </span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>保险人收到被保险人的赔偿请求后，应当及时就是否属于保险责任作出核定，并将核定结果通知被保险人。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal align=center style='margin-top:7.8pt;mso-para-margin-top:
            .5gd;text-align:center;line-height:30.0pt;mso-line-height-rule:exactly'><b
            style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;font-family:
            仿宋_GB2312'>投</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>保人、被保险人义务<span
            lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312'>第十三条 </span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>投保人应履行如实告知义务，如实回答保险人提出的询问，并如实填写投保单。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312'>第十四条 </span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>如未约定分期交付保险费的，投保人应当在保险合同成立时交清保险费。<span
            lang=EN-US><o:p></o:p></span></span></p>


            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312'>第十五条 </span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>被保险人应严格遵守国家及政府有关部门制定的相关法律、法规及规定，加强管理，采取合理的预防措施，尽力避免或减少责任事故的发生。<span
            lang=EN-US><o:p></o:p></span></span></p>


            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312'>第十六条 </span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>在合同有效期内，如保险标的的危险程度显著增加的，被保险人应当按照合同的约定及时通知保险人，保险人可以按照合同约定增加保险费或者解除合同。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312'>第十七条 </span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>保险事故发生时，被保险人应当尽力采取必要的措施，防止或者减少损失。投保人、被保险人知道保险事故发生后，应当及时通知保险人。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312'>第十八条 </span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>被保险人收到受害人的损害赔偿请求或得知可能产生损害赔偿时，应及时以书面形式通知保险人，并就损害赔偿请求的处理与保险人进行协商。<span
            lang=EN-US><o:p></o:p></span></span></p>


            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312'>第十九条 </span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>发生保险责任范围内的损失，应由有关责任方负责赔偿的，被保险人应行使或者保留向该责任方请求赔偿的权利。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.25pt;line-height:30.0pt;mso-line-height-rule:
            exactly'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体;mso-bidi-font-weight:bold'>在保险人向有关责任方行使代位请求赔偿权利时，被保险人应当向保险人提供必要的文件和其所知道的有关情况。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312'>第二十条 </span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>被保险人向保险人索赔时，应根据保险事故具体情形，提交下列索赔材料：<span
            lang=EN-US><o:p></o:p></span></span></p>



            <p class=MsoNormal align=left style='text-align:left;text-indent:32.0pt;
            line-height:30.0pt;mso-line-height-rule:exactly;mso-pagination:widow-orphan'><span
            lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            Calibri;mso-bidi-font-family:宋体;mso-font-kerning:0pt'>1</span><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
            mso-bidi-font-family:宋体;mso-font-kerning:0pt'>、保险单、批单、投保清单、保费发票；<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal align=left style='text-align:left;text-indent:32.0pt;
            line-height:30.0pt;mso-line-height-rule:exactly;mso-pagination:widow-orphan'><span
            lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            Calibri;mso-bidi-font-family:宋体;mso-font-kerning:0pt'>2</span><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
            mso-bidi-font-family:宋体;mso-font-kerning:0pt'>、有关主管部门出具的事故证明或医疗机构出具的死亡证明、伤残鉴定书；<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal align=left style='text-align:left;text-indent:32.0pt;
            line-height:30.0pt;mso-line-height-rule:exactly;mso-pagination:widow-orphan'><span
            lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            Calibri;mso-bidi-font-family:宋体;mso-font-kerning:0pt'>3</span><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
            mso-bidi-font-family:宋体;mso-font-kerning:0pt'>、医疗机构出具的医疗证明及费用清单，以及受害人因就医治疗所支出费用的相关证明；<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal align=left style='text-align:left;text-indent:32.0pt;
            line-height:30.0pt;mso-line-height-rule:exactly;mso-pagination:widow-orphan'><span
            lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            Calibri;mso-bidi-font-family:宋体;mso-font-kerning:0pt'>4</span><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
            mso-bidi-font-family:宋体;mso-font-kerning:0pt'>、受害人身份证明及提出损害赔偿请求的书面文件；<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal align=left style='text-align:left;text-indent:32.0pt;
            line-height:30.0pt;mso-line-height-rule:exactly;mso-pagination:widow-orphan'><span
            lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            Calibri;mso-bidi-font-family:宋体;mso-font-kerning:0pt'>5</span><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
            mso-bidi-font-family:宋体;mso-font-kerning:0pt'>、能够确定被保险人责任及赔偿金额的有关法律文书或经保险人同意，被保险人与受害人达成的赔偿协议及赔偿金支付凭据；<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal align=left style='text-align:left;text-indent:32.0pt;
            mso-char-indent-count:2.0;line-height:30.0pt;mso-line-height-rule:exactly;
            mso-pagination:widow-orphan'><span lang=EN-US style='font-size:16.0pt;
            font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;mso-bidi-font-family:宋体;
            mso-font-kerning:0pt'>6</span><span style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:Calibri;mso-bidi-font-family:宋体;mso-font-kerning:0pt'>、保险出险<span
            lang=EN-US>/</span>索赔通知书；<span lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal align=left style='text-align:left;text-indent:32.0pt;
            line-height:30.0pt;mso-line-height-rule:exactly;mso-pagination:widow-orphan'><span
            lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            Calibri;mso-bidi-font-family:宋体;mso-font-kerning:0pt'>7</span><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:Calibri;
            mso-bidi-font-family:宋体;mso-font-kerning:0pt'>、投保人、被保险人所能提供的其他与确认保险事故性质、原因、损失程度等有关的证明和资料。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal align=center style='margin-top:7.8pt;mso-para-margin-top:
            .5gd;text-align:center;line-height:30.0pt;mso-line-height-rule:exactly'><b
            style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;font-family:
            仿宋_GB2312;mso-hansi-font-family:宋体'>赔偿处理<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>第二十一条 </span></b><span style='font-size:16.0pt;font-family:仿宋_GB2312'>以下列方式确定的被保险人的赔偿责任，保险人按照保险合同的约定进行赔偿：<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:30.0pt;line-height:30.0pt;mso-line-height-rule:
            exactly'><span style='font-size:16.0pt;font-family:仿宋_GB2312'>（一）被保险人和向其提出损害赔偿请求的受害人协商并经保险人确认；<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.0pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><span style='font-size:16.0pt;
            font-family:仿宋_GB2312'>（二）仲裁机构裁决；<span lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.0pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><span style='font-size:16.0pt;
            font-family:仿宋_GB2312'>（三）人民法院判决；<span lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.0pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><span style='font-size:16.0pt;
            font-family:仿宋_GB2312'>（四）保险人认可的其它方式。<span lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312'>第二十二条</span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'> 在保险责任范围内，</span><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>保险人按照</span><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>保险事故发生时侵权损害赔偿法律法规规定的赔偿范围、项目和标准</span><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>负责赔偿：<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.0pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><span style='font-size:16.0pt;
            font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>（一）受害人死亡或残疾的，保险人在保险单载明的每次事故每人伤亡责任限额内赔偿；<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.0pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><span style='font-size:16.0pt;
            font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>（二）被保险人对受害人依法应承担的就医治疗支出的各项费用以及因误工减少的收入，保险人扣除每次事故每人医疗费用免赔额后，在每次事故每人医疗费用责任限额内赔偿，其中医疗费用按照国家基本医疗保险报销范围据实赔偿。除紧急抢救外，受伤人员应在二级及以上或保险人认可的医疗机构就诊；<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.0pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><span style='font-size:16.0pt;
            font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>（三）被保险人对受害人依法应承担的直接财产损失赔偿责任，保险人在扣除每次事故财产损失免赔额后，按照损失发生时该损毁财产的市场价格，在每次事故财产损失责任限额内赔偿；<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.0pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><span style='font-size:16.0pt;
            font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>（四）对于每次事故造成的损害，保险人对人身伤亡、财产损失及法律费用的赔偿金额不超过保险单载明的每次事故责任限额。在保险期间内，保险人的累计赔偿金额不超过累计责任限额。<span
            lang=EN-US><o:p></o:p></span></span></p>



            <p class=MsoNormal align=center style='margin-top:7.8pt;mso-para-margin-top:
            .5gd;text-align:center;line-height:30.0pt;mso-line-height-rule:exactly'><b
            style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;font-family:
            仿宋_GB2312;mso-hansi-font-family:宋体'>争议处理<span lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312'>第二十三条 </span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>合同争议解决方式由当事人在合同约定的下列两种方式中选择一种：<span
            lang=EN-US><o:p></o:p></span></span></p>



            <p class=MsoCommentText style='text-indent:32.0pt;mso-char-indent-count:2.0'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>（一）因履行本合同发生的争议，由当事人协商解决，协商不成的，提交保险单载明的仲裁委员会仲裁；<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.0pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><span style='font-size:16.0pt;
            font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>（二）因履行本合同发生的争议，由当事人协商解决，协商不成的，依法向人民法院起诉。</span><span
            lang=EN-US style='font-size:16.0pt;font-family:仿宋_GB2312'><o:p></o:p></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>第二十四条 </span></b><span style='font-size:16.0pt;font-family:仿宋_GB2312'>本保险合同的争议处理适用中华人民共和国法律（不包括港、澳、台地区法律）。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal align=center style='margin-top:15.6pt;mso-para-margin-top:
            1.0gd;text-align:center;line-height:30.0pt;mso-line-height-rule:exactly'><b
            style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;font-family:
            仿宋_GB2312;mso-hansi-font-family:宋体'>其他事项</span></b><span lang=EN-US
            style='font-size:16.0pt;font-family:仿宋_GB2312'><o:p></o:p></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>第二十五条</span></b><span style='font-size:16.0pt;font-family:仿宋_GB2312'> 保险责任开始前，投保人要求解除保险合同的，应当向保险人支付相当于保险费<span
            lang=EN-US>5%</span>的退保手续费，保险人应当退还剩余部分保险费；保险人要求解除保险合同的，不得向投保人收取手续费并应退还已收取的保险费。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.0pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><span style='font-size:16.0pt;
            font-family:仿宋_GB2312'>保险责任开始后，投保人要求解除保险合同的，自通知保险人之日起，保险合同解除，保险人按照保险责任开始之日起至合同解除之日止期间与保险期间的日比例计收保险费，并退还剩余部分保险费。<span
            lang=EN-US> <o:p></o:p></span></span></p>


            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312'>第二十六条 </span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>本保险合同约定与《中华人民共和国保险法》等法律规定相悖之处，以法律规定为准。本保险合同未尽事宜，以法律规定为准。<span
            lang=EN-US><o:p></o:p></span></span></p>


            <b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:16.0pt;
            font-family:仿宋_GB2312;mso-hansi-font-family:宋体;mso-bidi-font-family:"Times New Roman";
            mso-font-kerning:1.0pt;mso-ansi-language:EN-US;mso-fareast-language:ZH-CN;
            mso-bidi-language:AR-SA'><br clear=all style='page-break-before:always'>
            </span></b>

            <p class=MsoNormal align=center style='text-align:center;line-height:30.0pt;
            mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:宋体'>释义<span
            lang=EN-US><o:p></o:p></span></span></b></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>（一）</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>家政服务业：</span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>是以家庭为服务对象、以家庭事务为服务内容的有偿服务，主要包括家庭保姆、家庭保洁、家庭护理、家庭教育、家庭餐饮以及其它有偿家政服务活动，也包括为企事业单位、社区等提供的类似社会化服务。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>（二）</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>家政服务机构：</span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>是指在中华人民共和国境内依法设立，经工商行政管理部门登记注册，以家政服务业为经营范围的企事业单位、个体工商户等。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>（三）</span></b><b style='mso-bidi-font-weight:normal'><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>第三者：</span></b><span
            style='font-size:16.0pt;font-family:仿宋_GB2312'>本保险合同所指的第三者是指保险人、投保人、被保险人及其家政服务员、接受服务家庭成员以外的其他人。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='text-indent:32.15pt;mso-char-indent-count:2.0;
            line-height:30.0pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
            normal'><span style='font-size:16.0pt;font-family:仿宋_GB2312;mso-hansi-font-family:
            宋体'>（四）每次事故：</span></b><span style='font-size:16.0pt;font-family:仿宋_GB2312;
            mso-hansi-font-family:宋体'>因同一起意外事故造成多人人身伤亡，导致多人同时或先后向被保险人提出损害赔偿请求的，视为一次保险事故。<span
            lang=EN-US><o:p></o:p></span></span></p>

            <p class=MsoNormal style='margin-bottom:7.8pt;mso-para-margin-bottom:.5gd;
            line-height:30.0pt;mso-line-height-rule:exactly'><span lang=EN-US><o:p>&nbsp;</o:p></span></p>

            <p class=MsoNormal><span lang=EN-US><o:p>&nbsp;</o:p></span></p>

            </div>



        </div>
    </a-modal>
</template>
<script>

    export default {

        data: function () {
            return {
                visible: false,

                pic:null,
                // 表头

                loadData: parameter => {

                },
                selectedRows: [],
                selectedRowKeys: [],
            }
        },
        computed: {

        },
        beforeCreate() {
        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
        },
        mounted() {

        },
        destroyed() {
        },
        methods: {
            show(data) {
                this.pic = data;
                this.visible = true;
            },
        },
        watch: {}
    }
</script>