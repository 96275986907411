var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qui-layout"},[_c('a-form',{staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"qui-header qui-header-black gracolor"},[_c('h2',{staticClass:"qui-fs35"},[_vm._v("替换雇员")]),_c('div',{staticClass:"left"},[_c('a',{attrs:{"href":"javascript:history.go(-1)"}},[_c('i',{staticClass:"iconfont icon-fanhui"})])])]),(_vm.isDisplay==false)?_c('Loading'):_vm._e(),(_vm.isDisplay==true)?_c('div',{staticStyle:{"display":"block"},attrs:{"id":"employeeDiv"}},_vm._l((_vm.employeeList),function(employeeData,index){return _c('div',{key:index},[_c('div',{staticClass:"qui-row qui-bg-white qui-pd-0-30 qui-bdr-bottom"},[_c('div',{staticClass:"qui-col50 qui-fs30 qui-formlabel qui-text-black",attrs:{"data-index":"index"}},[_c('a-checkbox',{staticStyle:{"color":"red"},on:{"change":function($event){return _vm.onChange(index)}}},[_vm._v("点此替换："+_vm._s(employeeData.name))])],1)]),_c('div',{staticClass:"qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col40 qui-text-black"},[_c('font',{staticClass:"qui-important"},[_vm._v("*")]),_vm._v("姓名 ")],1),_c('a-form-item',[_c('div',{staticClass:"qui-col60 qui-formlabel qui-text-grey",attrs:{"align":"right"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              `employeeParam[${index}].id`,
                              {
                                rules: [{ required: true, message: '不能为空！' }],
                                initialValue:employeeData.id
                                }
                            ]),expression:"[\n                              `employeeParam[${index}].id`,\n                              {\n                                rules: [{ required: true, message: '不能为空！' }],\n                                initialValue:employeeData.id\n                                }\n                            ]"}],attrs:{"type":"hidden"}}),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `employeeParam[${index}].name`,
                    {
                      rules: [{ required: true, message: '不能为空！' }],
                      initialValue:employeeData.name
                      }
                  ]),expression:"[\n                    `employeeParam[${index}].name`,\n                    {\n                      rules: [{ required: true, message: '不能为空！' }],\n                      initialValue:employeeData.name\n                      }\n                  ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入姓名"}})],1)])],1),_c('div',{staticClass:"qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col40 qui-text-black"},[_c('font',{staticClass:"qui-important"},[_vm._v("*")]),_vm._v("证件类型 ")],1),_c('a-form-item',[_c('div',{staticClass:"qui-col60 qui-formlabel qui-text-grey",attrs:{"align":"right"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        `employeeParam[${index}].idType`,
                        {
                          rules: [{ required: true, message: '不能为空！' }],
                          initialValue:employeeData.idType.name

                          }

                       ]),expression:"[\n                        `employeeParam[${index}].idType`,\n                        {\n                          rules: [{ required: true, message: '不能为空！' }],\n                          initialValue:employeeData.idType.name\n\n                          }\n\n                       ]"}],attrs:{"name":"insuredRoleListIdType"}},[_c('a-select-option',{attrs:{"value":"sfz"}},[_vm._v("身份证")]),_c('a-select-option',{attrs:{"value":"hkb"}},[_vm._v("户口簿")]),_c('a-select-option',{attrs:{"value":"hz"}},[_vm._v("护照")])],1)],1)])],1),_c('div',{staticClass:"qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col40 qui-text-black"},[_c('font',{staticClass:"qui-important"},[_vm._v("*")]),_vm._v("证件号码 ")],1),_c('a-form-item',[_c('div',{staticClass:"qui-col60 qui-formlabel qui-text-grey",attrs:{"align":"right"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `employeeParam[${index}].idCard`,
                            {
                              rules: [{ required: true, message: '不能为空！' }],
                              initialValue:employeeData.idCard
                              }
                          ]),expression:"[\n                            `employeeParam[${index}].idCard`,\n                            {\n                              rules: [{ required: true, message: '不能为空！' }],\n                              initialValue:employeeData.idCard\n                              }\n                          ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入证件号码"},on:{"blur":_vm.calculationAge}})],1)])],1),_c('div',{staticClass:"qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col40 qui-text-black"},[_c('font',{staticClass:"qui-important"},[_vm._v("*")]),_vm._v("工种 ")],1),_c('div',{staticClass:"qui-col60 qui-formlabel qui-text-grey"},[_c('a-form-item',[_c('div',{staticClass:"qui-col60 qui-formlabel qui-text-grey",attrs:{"align":"right"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        `employeeParam[${index}].jobType`,
                        {
                          rules: [{ required: true, message: '不能为空！' }],
                          initialValue:employeeData.jobType
                          }
                      ]),expression:"[\n                        `employeeParam[${index}].jobType`,\n                        {\n                          rules: [{ required: true, message: '不能为空！' }],\n                          initialValue:employeeData.jobType\n                          }\n                      ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入工种"}})],1)])],1)]),_c('div',{staticClass:"qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col40 qui-text-black"},[_c('font',{staticClass:"qui-important"},[_vm._v("*")]),_vm._v("性别 ")],1),_c('a-form-item',[_c('div',{staticClass:"qui-col60 qui-formlabel qui-text-grey",attrs:{"align":"right"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        `employeeParam[${index}].sex`,
                        {
                          rules: [{ required: true, message: '不能为空！' }],
                          initialValue:employeeData.sex.name
                          }
                       ]),expression:"[\n                        `employeeParam[${index}].sex`,\n                        {\n                          rules: [{ required: true, message: '不能为空！' }],\n                          initialValue:employeeData.sex.name\n                          }\n                       ]"}],attrs:{"name":"insuredRoleList2sex"}},[_c('a-select-option',{attrs:{"value":"female"}},[_vm._v("女")]),_c('a-select-option',{attrs:{"value":"male"}},[_vm._v("男")])],1)],1)])],1),_c('div',{staticClass:"qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col40 qui-text-black"},[_c('font',{staticClass:"qui-important"},[_vm._v("*")]),_vm._v("年龄 ")],1),_c('a-form-item',[_c('div',{staticClass:"qui-col60 qui-formlabel qui-text-grey",attrs:{"align":"right"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    `employeeParam[${index}].age`,
                    {
                      rules: [{ required: true, message: '不能为空！' }],
                      initialValue:employeeData.age
                      }
                  ]),expression:"[\n                    `employeeParam[${index}].age`,\n                    {\n                      rules: [{ required: true, message: '不能为空！' }],\n                      initialValue:employeeData.age\n                      }\n                  ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请填写年龄:16~65","name":"ageInput"}})],1)])],1)])}),0):_vm._e(),(_vm.isDisplay==true)?_c('div',{staticClass:"qui-pd-30 qui-mgt30 addborde",attrs:{"align":"center"}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'id',
                {
                  rules: [{ required: true, message: '不能为空！' }],
                  initialValue:_vm.data.id
                  }
              ]),expression:"[\n                'id',\n                {\n                  rules: [{ required: true, message: '不能为空！' }],\n                  initialValue:data.id\n                  }\n              ]"}],attrs:{"type":"hidden"}})],1),_c('a-button',{staticClass:"qui-btn qui-btn-full",staticStyle:{"background-color":"#09bb07","color":"white"},attrs:{"size":"large","loading":_vm.confirmLoading,"disabled":_vm.confirmLoading},on:{"click":_vm.handleSubmit}},[_vm._v("替换勾选雇员 ")])],1):_vm._e()],1),_c('Bottom')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }