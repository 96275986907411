<template>
    <a-modal
            :title="'产品须知'"
            :width="550"
            :height="500"
            v-model="visible"
            :footer='null'>
        <div>
       <div class="Section0"  style="layout-grid:15.6000pt;" ><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:36.1500pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
font-size:18.0000pt;" >&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
font-size:18.0000pt;" >&nbsp;</span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
font-size:18.0000pt;" ><font face="宋体" >产品须知</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
font-size:18.0000pt;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:36.1500pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
font-size:18.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal ><b><span class="15"  style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(51,51,51);
letter-spacing:0.4000pt;font-weight:bold;font-size:14.5000pt;" ><font face="宋体" >一</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';color:rgb(51,51,51);letter-spacing:0.4000pt;
font-weight:bold;font-size:14.5000pt;" ><font face="宋体" >、</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(51,51,51);
letter-spacing:0.4000pt;font-weight:bold;font-size:14.5000pt;" ><font face="宋体" >产品</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';color:rgb(51,51,51);letter-spacing:0.4000pt;
font-weight:bold;font-size:14.5000pt;" ><font face="宋体" >方案</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';color:rgb(51,51,51);letter-spacing:0.4000pt;
font-weight:bold;font-size:14.5000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal ><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" >方案</font><font face="Calibri" >1</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:12.0000pt;
mso-font-kerning:1.0000pt;" ><o:p></o:p></span></b></p><table class=MsoTableGrid  border=1  cellspacing=0  style="border-collapse:collapse;width:411.1000pt;margin-left:-7.3500pt;
mso-table-layout-alt:fixed;border:none;mso-border-left-alt:0.5000pt solid windowtext;
mso-border-top-alt:0.5000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;mso-border-bottom-alt:0.5000pt solid windowtext;
mso-border-insideh:0.5000pt solid windowtext;mso-border-insidev:0.5000pt solid windowtext;mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;" ><tr style="height:35.6500pt;" ><td width=548  valign=top  colspan=6  style="width:411.1000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:132.5000pt;mso-char-indent-count:11.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;color:rgb(255,0,0);mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家庭</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;color:rgb(255,0,0);mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >综合责任保险方案</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="text-indent:90.3500pt;mso-char-indent-count:7.5000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >（适用</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >工种：家庭保姆、育婴员、母婴</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >员）</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td></tr><tr style="height:18.1500pt;" ><td width=75  valign=top  style="width:56.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0500pt;mso-char-indent-count:1.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >条款</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:36.1500pt;mso-char-indent-count:4.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >保障项目</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >标准</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >版</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="margin-left:5.8500pt;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >高级</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >版</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="margin-left:7.3500pt;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >豪华版</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >免赔</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td></tr><tr style="height:17.0000pt;" ><td width=75  valign=top  rowspan=6  style="width:56.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:none;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >主险</font></span></b><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >（家政</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务机构责任保险条款</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >雇主家庭</font></span><span style="font-family:宋体;color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >累计</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >赔偿</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >限额</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >80</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >100</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >200</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >雇主家庭人身伤害</font></span><span style="font-family:宋体;color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >每次</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >赔偿限额</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >30</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >50</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >80</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >雇主</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家庭人身伤害</font></span><span style="font-family:宋体;color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >每次医疗</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >限额</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >10</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >20</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >30</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >雇主家庭财产损失</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >3</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >5</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >8</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >200</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >第三者（雇主家庭成员外</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >1</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >1</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >1</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >200元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >法律</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >费用</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;background:rgb(255,255,255);" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >5</span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;background:rgb(255,255,255);" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >5</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;background:rgb(255,255,255);" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >5</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=75  valign=top  rowspan=4  style="width:56.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:none;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >附加险</font></span></b><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >附加</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家政服务人员责任保险</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >条款</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务员人身伤害及伤残</font></span><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >20</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >30</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >50</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务员意外伤害医</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >疗</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;background:rgb(255,255,255);" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >5</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;background:rgb(255,255,255);" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >10</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;background:rgb(255,255,255);" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >10</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >100</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >工作期间猝死责</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >任</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >20</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >30</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >50</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >误工费补</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >助（</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >最高</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >180天</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >80</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >天</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >80</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >天</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >80</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >天</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >3</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >天</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:22.9500pt;" ><td width=264  valign=top  colspan=2  rowspan=2  style="width:198.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:none;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal  style="text-indent:68.5000pt;mso-char-indent-count:6.5000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >保费</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >118</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >年</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >188</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >年</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >258</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >年</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p></td></tr><tr style="height:21.6000pt;" ><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >18</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >月</font></span></b><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >28</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >月</font></span></b><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >38</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >月</font></span></b><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></p></td></tr></table><p class=MsoNormal ><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:12.0000pt;
mso-font-kerning:1.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal ><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" >方案</font><font face="Calibri" >2</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:12.0000pt;
mso-font-kerning:1.0000pt;" ><o:p></o:p></span></b></p><table class=MsoTableGrid  border=1  cellspacing=0  style="border-collapse:collapse;width:411.1000pt;margin-left:-7.3500pt;
mso-table-layout-alt:fixed;border:none;mso-border-left-alt:0.5000pt solid windowtext;
mso-border-top-alt:0.5000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;mso-border-bottom-alt:0.5000pt solid windowtext;
mso-border-insideh:0.5000pt solid windowtext;mso-border-insidev:0.5000pt solid windowtext;mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;" ><tr style="height:37.5000pt;" ><td width=548  valign=top  colspan=6  style="width:411.1000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:132.5000pt;mso-char-indent-count:11.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;color:rgb(255,0,0);mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >养老</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;color:rgb(255,0,0);mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >护理</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >综合责任保险方案</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >（适用</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >工种：</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >医院</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >护工、病患护理、医院送检</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >员</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >、社区</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >或</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >养老机构护理员</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td></tr><tr style="height:17.0000pt;" ><td width=75  valign=top  style="width:56.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0500pt;mso-char-indent-count:1.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >条款</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:27.1000pt;mso-char-indent-count:3.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >保险项目</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >标准</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >版</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="margin-left:6.6000pt;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >高级</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >版</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="margin-left:2.8500pt;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >豪华</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >版</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >免赔</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td></tr><tr style="height:17.0000pt;" ><td width=75  valign=top  rowspan=6  style="width:56.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:none;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >主险</font></span></b><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >（家政</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务机构责任保险条款</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >护理</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >对象</font></span><span style="font-family:宋体;color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >累计</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >赔偿</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >限额</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >30</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >50</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >80</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >护理对象人身伤害</font></span><span style="font-family:宋体;color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >每次</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >赔偿</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >限额</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><span style="font-family:Calibri;mso-spacerun:'yes';" >&nbsp;</span><font face="Calibri" >10</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >10</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >20</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >护理对象人身伤害</font></span><span style="font-family:宋体;color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >每次医疗</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >限额</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >2</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >5</span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >8</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:15.9500pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >护理对象家庭财产损失</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >1</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >2</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >5</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >200</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >第三者（护理对象外</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >1</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >1</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >1</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >200</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >法律</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >费用</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >5</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >5</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >5</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=75  valign=top  rowspan=4  style="width:56.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:none;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >附加险</font></span></b><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >附加</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家政服务人员责任保险</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >条款</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务员人身伤害及伤残</font></span><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >10</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >20</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >30</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:20.1500pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务员意外伤害医疗</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >1万</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >3</span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:13.5000pt;mso-char-indent-count:1.5000;" ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >5</span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >100</span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >元</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >工作期间猝死责</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >任</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><span style="mso-spacerun:'yes';" >&nbsp;&nbsp;</span>10</span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >20</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >30</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=189  valign=top  style="width:141.7500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >误工费补</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >助（</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >最高</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >180天</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >80</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >天</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >80</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >天</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >80</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >天</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >3</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >天</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:21.7000pt;" ><td width=264  valign=top  colspan=2  rowspan=2  style="width:198.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:none;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;background:rgb(255,255,255);" ><p class=MsoNormal  style="text-indent:42.1500pt;mso-char-indent-count:4.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal  style="text-indent:68.5000pt;mso-char-indent-count:6.5000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >保费</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >1</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="Calibri" >3</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >0</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >年</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >200</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >年</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >300</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >年</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;background:rgb(255,255,255);" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p></td></tr><tr style="height:20.9500pt;" ><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >20</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >月</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >30</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >月</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=75  valign=top  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >45</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >月</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=56  valign=top  style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;background:rgb(255,255,255);" ><p class=MsoNormal ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></p></td></tr></table><p class=MsoNormal ><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:12.0000pt;
mso-font-kerning:1.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal ><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" >方案</font><font face="Calibri" >3</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:12.0000pt;
mso-font-kerning:1.0000pt;" ><o:p></o:p></span></b></p><table class=MsoTableGrid  border=1  cellspacing=0  style="border-collapse:collapse;width:404.0000pt;margin-left:-7.3500pt;
mso-table-layout-alt:fixed;border:none;mso-border-left-alt:0.5000pt solid windowtext;
mso-border-top-alt:0.5000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;mso-border-bottom-alt:0.5000pt solid windowtext;
mso-border-insideh:0.5000pt solid windowtext;mso-border-insidev:0.5000pt solid windowtext;mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;" ><tr style="height:32.8500pt;" ><td width=538  valign=top  colspan=5  style="width:404.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:132.5000pt;mso-char-indent-count:11.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;color:rgb(255,0,0);mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >预约</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;color:rgb(255,0,0);mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >综合责任保险方案</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="text-indent:72.3000pt;mso-char-indent-count:6.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >（适用</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >工种：</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >预约</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >保洁、</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >家电</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >清洗、收纳服务</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td></tr><tr style="height:17.0000pt;" ><td width=94  valign=top  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:22.6000pt;mso-char-indent-count:2.5000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >条款</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=198  valign=top  style="width:148.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:27.1000pt;mso-char-indent-count:3.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >保险项目</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=94  valign=top  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:13.5500pt;mso-char-indent-count:1.5000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >标准</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >版</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=85  valign=top  style="width:63.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:13.5500pt;mso-char-indent-count:1.5000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >高级</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >版</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=66  valign=top  style="width:49.6000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0500pt;mso-char-indent-count:1.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >额度</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td></tr><tr style="height:17.0000pt;" ><td width=94  valign=top  rowspan=5  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:none;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >主险</font></span></b><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >（家政</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务机构责任保险条款</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=198  valign=top  style="width:148.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >第三者</font></span><span style="font-family:宋体;color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >累计</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >赔偿限额</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=94  valign=top  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:2.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >50</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=85  valign=top  style="width:63.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:2.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >80</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=66  valign=top  style="width:49.6000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=198  valign=top  style="width:148.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >第三者人身伤害</font></span><span style="font-family:宋体;color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >每次</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >赔偿限额</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=94  valign=top  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:2.0000;" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >2</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >0</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=85  valign=top  style="width:63.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:2.0000;" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >3</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >0</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=66  valign=top  style="width:49.6000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=198  valign=top  style="width:148.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >第三者人身伤害</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >每次医疗</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >限额</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=94  valign=top  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:2.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >2万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=85  valign=top  style="width:63.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:2.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >3万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=66  valign=top  style="width:49.6000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=198  valign=top  style="width:148.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >第三者家庭财产损失</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=94  valign=top  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:2.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >2</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=85  valign=top  style="width:63.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:2.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >3万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=66  valign=top  style="width:49.6000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >200</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=198  valign=top  style="width:148.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >法律</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >费用</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=94  valign=top  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:2.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >5</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=85  valign=top  style="width:63.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:2.0000;" ><span style="font-family:'Times New Roman';mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >5</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=66  valign=top  style="width:49.6000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=94  valign=top  rowspan=4  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:none;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >附加险</font></span></b><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >附加</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家政服务人员责任保险</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >条款</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=198  valign=top  style="width:148.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务员人身伤害及伤残</font></span><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=94  valign=top  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:13.5000pt;mso-char-indent-count:1.5000;" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >3</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >0</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=85  valign=top  style="width:63.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:2.0000;" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >5</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >0</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=66  valign=top  style="width:49.6000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=198  valign=top  style="width:148.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务员意外伤害医</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >疗</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=94  valign=top  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:2.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >2万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=85  valign=top  style="width:63.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:2.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >3万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=66  valign=top  style="width:49.6000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >100</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=198  valign=top  style="width:148.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >工作期间猝死责</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >任</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=94  valign=top  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:13.5000pt;mso-char-indent-count:1.5000;" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >3</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >0</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=85  valign=top  style="width:63.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:2.0000;" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >5</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >0</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >万</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=66  valign=top  style="width:49.6000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >无</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:17.0000pt;" ><td width=198  valign=top  style="width:148.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >误工费补</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >助（</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >最高</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >180天</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=94  valign=top  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:4.5000pt;mso-char-indent-count:0.5000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >80</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >天</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=85  valign=top  style="width:63.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >80</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >天</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=66  valign=top  style="width:49.6000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" >3</span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >天</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:19.6500pt;" ><td width=292  valign=top  colspan=2  rowspan=2  style="width:219.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:none;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;background:rgb(255,255,255);" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal  style="text-indent:36.9000pt;mso-char-indent-count:3.5000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >保费</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:10.5000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=94  valign=top  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0500pt;mso-char-indent-count:1.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="Calibri" >260</font><font face="宋体" >元</font><font face="Calibri" >/</font><font face="宋体" >年</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=85  valign=top  style="width:63.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0500pt;mso-char-indent-count:1.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="Calibri" >380</font><font face="宋体" >元</font><font face="Calibri" >/</font><font face="宋体" >年</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=66  valign=top  style="width:49.6000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;background:rgb(255,255,255);" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p></td></tr><tr style="height:21.3000pt;" ><td width=94  valign=top  style="width:70.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >50</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >月</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=85  valign=top  style="width:63.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >65</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >元</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" >/</span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:微软雅黑;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >月</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=66  valign=top  style="width:49.6000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;background:rgb(255,255,255);" ><p class=MsoNormal ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></p></td></tr></table><p class=MsoNormal  style="text-align:left;line-height:17.0000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal ><b><span class="15"  style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;
font-size:14.5000pt;" ><font face="宋体" >二</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:14.5000pt;" ><font face="宋体" >、</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-weight:bold;
font-size:14.5000pt;" ><font face="宋体" >产品</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:14.5000pt;" ><font face="宋体" >释义</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:14.5000pt;" ><o:p></o:p></span></b></p><table class=MsoTableGrid  border=1  cellspacing=0  style="border-collapse:collapse;width:432.3500pt;margin-left:-7.3500pt;
mso-table-layout-alt:fixed;border:none;mso-border-left-alt:0.5000pt solid windowtext;
mso-border-top-alt:0.5000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;mso-border-bottom-alt:0.5000pt solid windowtext;
mso-border-insideh:0.5000pt solid windowtext;mso-border-insidev:0.5000pt solid windowtext;mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;" ><tr style="height:53.8000pt;" ><td width=75  valign=top  rowspan=6  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >主险</font></span></b><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >（家政</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务机构责任保险条款</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=132  valign=top  style="width:99.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  align=center  style="text-align:center;" ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >被</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务家庭</font></span><span style="font-family:宋体;color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >累计</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >赔偿限额</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=368  valign=top  style="width:276.4000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >在</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >保险期间内，因服务员</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >从事</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家政服务工作过程中，因</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >过失责任</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >造成</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >被服务家庭</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >成员</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >的</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >人身</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >伤害或财产损失的</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >累计赔偿</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >限额</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >，</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >依法应由被保险人（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家政</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务机构</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >承担的</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >经济赔偿</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >责任。</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:56.7000pt;" ><td width=132  valign=top  style="width:99.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  align=center  style="text-align:center;" ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >被</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务家庭</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >人身伤害</font></span><span style="font-family:宋体;color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >每次</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >赔偿限额</font></span><span style="font-family:宋体;color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=368  valign=top  style="width:276.4000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >在</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >保险期间内，因服务员从事家政服务工作过程中，因</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >过失责任</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >造成被服务家庭</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >成员</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >死亡或伤残</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >的</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >每次事故</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >赔偿限额</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >，</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >依法应由被保险人（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家政</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务机构</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >承担的</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >经济赔偿</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >责任。</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:56.8000pt;" ><td width=132  valign=top  style="width:99.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  align=center  style="text-align:center;" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >被</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务家庭</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >人身伤害</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >每次医疗</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >限额</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=368  valign=top  style="width:276.4000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >在</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >保险期间内，因服务员从事家政服务工作过程中，因</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >过失责任</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >造成被服务</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家庭成员</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >人身伤害所产生</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >医疗</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >费用</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >的</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >每次事故</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >赔偿限额</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >，</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >依法应由被保险人（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家政</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务机构</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >承担的</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >经济赔偿</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >责任。</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:56.9000pt;" ><td width=132  valign=top  style="width:99.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  align=center  style="text-align:center;" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >被</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家庭财产损失</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  align=center  style="text-align:center;" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >（含每次</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >及</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >累计）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=368  valign=top  style="width:276.4000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >在</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >保险期间</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >内</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >，因服务员从事家政服务工作过程中，因</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >过失责任</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >造成被服务</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家庭财产</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >损失</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >的</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >每次</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >（含累计）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >事故</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >赔偿</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >限额</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >，</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >依法应由被保险人（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家政</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务机构</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >承担的</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >经济赔偿</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >责任。</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:70.4500pt;" ><td width=132  valign=top  style="width:99.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >被</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务家庭</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >外</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >的第三者</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >（含</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >每次及累计</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=368  valign=top  style="width:276.4000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >在</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >保险期间内，因服务员从事家政服务工作过程中，因</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >过失责任</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >造成被服务</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家庭成员</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >以外</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >的人员</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >（第</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >三者</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >，遭受人身伤害</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >（含</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >死亡、伤残、医疗）</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）或</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >财产损失</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >的赔偿</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >限额</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >，</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >依法应由被保险人（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家政</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务机构</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >承担的</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >经济赔偿</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >责任。</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:42.4000pt;" ><td width=132  valign=top  style="width:99.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  align=center  style="text-align:center;" ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >法律</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >费用</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=368  valign=top  style="width:276.4000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >保险</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >事故发生后，被保险人（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家政服务</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >机构）</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >因保险</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >事故被提起仲裁或诉讼</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >的</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >，对应</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >由被保险人</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >（家政</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务机构</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >支付</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >的仲裁或诉讼费</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >用</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >。</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:57.2500pt;" ><td width=75  valign=top  rowspan=4  style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:none;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal ><b style="mso-bidi-font-weight:normal" ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >附加险</font></span></b><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >附加</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家政服务人员责任保险</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >条款</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=132  valign=top  style="width:99.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  style="text-indent:9.0000pt;mso-char-indent-count:1.0000;" ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务员人身伤害</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="text-indent:27.0000pt;mso-char-indent-count:3.0000;" ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >及伤残</font></span><b style="mso-bidi-font-weight:normal" ><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></td><td width=368  valign=top  style="width:276.4000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >在</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >保险期间内，服务人员</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >在</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务过程中（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >包含</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >上下班途中</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >非</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >本人</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >主要责任</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >的交通事故）发生的意外事故，导致死亡或伤残</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >，</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >依法应由被保险人（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家政</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务机构</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >承担的</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >经济赔偿</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >责任。</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:56.6500pt;" ><td width=132  valign=top  style="width:99.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  align=center  style="text-align:center;" ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务员意外伤害医</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >疗</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=368  valign=top  style="width:276.4000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >在</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >保险期间内，服务人员</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >在</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务过程中（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >包含</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >上下班途中</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >非</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >本人</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >主要责任</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >的交通事故）发生的意外事故，</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >所</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >产生的医疗费用</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >，</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >依法应由被保险人（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >家政</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务机构</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >承担的</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >经济赔偿</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >责任。</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:36.1000pt;" ><td width=132  valign=top  style="width:99.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  align=center  style="text-align:center;" ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >工作期间猝死责</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >任</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=368  valign=top  style="width:276.4000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >在</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >保险期间内，服务员在</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >工作期间和工作岗位</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >，</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >因</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >突发疾病死亡</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >或者在</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="Calibri" >48</font><font face="宋体" >小时之内</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >经抢救无效死亡。</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr><tr style="height:69.9500pt;" ><td width=132  valign=top  style="width:99.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal  align=center  style="text-align:center;" ><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >误工费补</font></span><span style="font-family:微软雅黑;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="微软雅黑" >助</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >（含</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >每次及累计）最高</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >180天</font></span><span style="font-family:宋体;font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >）</font></span><span style="font-family:Calibri;mso-fareast-font-family:'Times New Roman';mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td><td width=368  valign=top  style="width:276.4000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:none;
mso-border-left-alt:none;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.5000pt solid windowtext;" ><p class=MsoNormal ><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >在</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >保险期间内，服务人员</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >在</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >服务过程中（</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >包含</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >上下班途中</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >非</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';color:rgb(255,0,0);font-size:9.0000pt;
mso-font-kerning:0.0000pt;" ><font face="宋体" >本人</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >主要责任</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >的交通事故）发生的意外事故</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >而</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >不能正常工作</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >的</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >，根据医生开具的休假证明，按</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >80</font><font face="宋体" >元</font><font face="Calibri" >/</font><font face="宋体" >天</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >进行赔付</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >，</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >每次</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >事故</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >免赔</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="Calibri" >3</font><font face="宋体" >天</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >，</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >最高</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >赔付</font></span><span style="font-family:宋体;mso-ascii-font-family:Calibri;mso-hansi-font-family:Calibri;
mso-bidi-font-family:'Times New Roman';font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >限额</font><font face="Calibri" >180</font><font face="宋体" >天</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><font face="宋体" >。</font></span><span style="font-family:Calibri;mso-fareast-font-family:宋体;mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p></td></tr></table><p class=MsoNormal  style="line-height:20.0000pt;mso-line-height-rule:exactly;" ><b><span class="15"  style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';font-weight:bold;font-size:14.5000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;" ><font face="华文仿宋" >三、</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;" ><font face="华文仿宋" >特别约定</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" >1</span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >、财产损失：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务人员因过失责任造成被</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务家庭</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >或第三者财产损失，每次事故</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >绝对免赔责任限额</font><font face="华文仿宋" >200元</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >1）、保险人不承担平板电脑、手机、摄像（影）器材、音像制品、打火机、手表、眼镜、手包、化妆护肤品、交通工具、 金银、珠宝、钻石、玉器、首饰、古币、古玩、古书、古画、邮票、字画、艺术品、动物、植物、食品、药品、烟酒、稀有金属等珍贵财物或</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >无法确定价值</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的物品的家庭财产损失。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >2）保险人不承担马桶或下水道堵塞。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >3）保洁中对客户墙面、墙纸、墙布造成损失，最大赔偿为损失部分为所在墙面的面积。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >4）涉及护理对象家庭装修及其财产损失案件，若无法提供受损财产有效真实的购置合同、发票、订单等材料，保险人可参照市场上常用的国产普通适用性产品价格进行赔偿。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >5）因清洁玻璃造成玻璃或玻璃保护膜的划痕、划损，每次事故责任</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >限额为</font><font face="华文仿宋" >1000元，</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >但造成玻璃破损不在此类限制。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >6）雇主家庭任何财产的</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >丢失</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >，保险人不负责赔偿。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" >2</span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >、意外医疗：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >护理人员因工意外受伤，</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >二级或二级以上公立医院</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >进行治疗。如从其它途径（如社保、新农合或其它保险机构）已报销的，对未报销的部分可进行补充赔付，但进口药、进口医用器具及自费药不在保障范畴；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" >3</span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >、上下班途中责任：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >本保险保障服务人员在往返工作地点途中发生的</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >非本人主要责任的交通事故</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >或者城市轨道交通、客运轮渡、火车事故伤害。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" >4</span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >、其它</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >责任：</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >1）本保单</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >不承保</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >由于被</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >对象、</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >家庭</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >成员及第三者</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >自身原因</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（包括但不限于</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >自身疾病、自身运动伤害、自身病情自然发展等</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >）导致的人身伤害及财产损失。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >2）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >发生保险责任范围内的损失，应由</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);mso-bidi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >有关责任方</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >负责赔付的，被保险人（</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >家政</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务机构）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);mso-bidi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >应</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);mso-bidi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >行使或者保留</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >向该责任方请求赔偿的权利。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >3）家政</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务员使用</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >各种机动、非机动车辆</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >造成被服务对象</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >、</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >家庭成员</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >及</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >第三者</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >人身</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >伤害或财产损失，保险人（</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >保险</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >公司）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >不</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >负责赔付</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >。</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >4）本保单承保的雇员仅限家政服务人员，</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >不涉及高处作业</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（在距坠落高度基准面</font><font face="华文仿宋" >2m或2m以上的作业），若不属于本保险承保雇员的职业范围请勿投保；如投保错误，保险公司不承担保险责任。</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:normal;font-size:12.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;mso-bidi-font-weight:normal;font-size:12.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;mso-bidi-font-weight:normal;font-size:12.0000pt;" ><font face="华文仿宋" >四</font></span></b><b style="mso-bidi-font-weight:normal" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;mso-bidi-font-weight:normal;font-size:12.0000pt;" ><font face="华文仿宋" >、责任免除</font></span></b><b style="mso-bidi-font-weight:normal" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;mso-bidi-font-weight:normal;font-size:12.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（一）主险</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >：家政服务机构责任保险</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（保障</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >被服务的</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >家庭）</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >下列损失、费用或赔偿责任，保险人</font></span><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >不负责赔偿</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);mso-bidi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >：</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >1）被保险人或家政服务员的</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >故意</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >行为或</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >重大过失</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >行为、</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >犯罪</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >行为；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >2）接受服务家庭成员或第三者的故意行为、犯罪行为；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >3）家政服务员</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >使用各种机动、非机动车辆</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >4）家政服务员未满16周岁；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >5）家政服务员患有</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >精神病、传染病</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >6）家政服务员醉酒、吸毒、受精神药品影响或精神失常状态下；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >7）家政服务员从事被保险人或接受服务家庭分配或</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >指定的工作以外</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的行为。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >8）家政服务员在中华人民共和国</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >境外</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（包括港、澳、台地区）造成的人身伤亡或财产损失；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >9）被</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >保险人（</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >家政</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务机构）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >或</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >家政服务员的人身伤亡或其</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >财产</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >损失；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >10）被</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >保险人（</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >家政</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务机构）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >根据</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >与他人签订的协议应承担的责任，但即使</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >没有</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >这种协议，被保险人依法（</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >家政</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务机构）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >应</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >承担的责任不在此</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >限</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >11）艺术品、动植物、金银玉器、珠宝首饰、古玩字画、邮票货币、稀有金属、有价证券、文件账册、技术资料、电脑数据资料；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >12）被保险人的</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >间接</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >损失；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >13）精神损害赔偿，但由法院判决支持的不在此限；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:Wingdings;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >&#232;</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >具体</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >细则详见</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >《中国人民财产保险股份有限公司家政服务机构责任保险条款》</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（二）附加</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >险：</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >附加</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >家政</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >人员责任保险条款</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（保障</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >家政服务</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >人</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >员</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >）</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >下列原因造成的损失、费用和责任，保险人</font></span><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >不负责赔偿</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >：</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >1）家政</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务人员</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >犯罪、自杀自残、斗殴，或因受酒精、毒品、药品影响造成自身人身伤亡的；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >2）家政</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务人员</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >因</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >疾病</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（包括职业病）、</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >分娩、流产</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >以及因上述原因接受医疗救治的；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >3）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >家政服务人员</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >无有效驾驶证</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >驾驶机动车辆或无有效资格证书而使用各种专用机械、特种设备、特种车辆或类似设备装置，造成自身人身伤亡的。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >4）保险人（保险</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >公司</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >）按照</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >投保时被保险人</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（家政</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务机构</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >）提供</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的家政服务员名单承担赔偿责任，被保险人（</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >家政</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务机构）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >对</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >名单</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >范围</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >以外</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的家政服务人员承担的赔偿责任，保险人</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（保险</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >公司</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >）不</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >负责赔偿</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >5）精神损害赔偿；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >6）超出家政</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务人员</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >所在地</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >工伤保险诊疗项目目录、工伤保险药品目录、工伤保险住院服务标准</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的医疗费用；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >7）工伤保险</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >已经支付</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的医疗费用；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >8）家政</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务人员</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >在中华人民共和国</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >境外</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（包括港、澳、台地区）发生的人身伤亡；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >9）保险单载明的</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >免赔额</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:21.5000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:Wingdings;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >&#232;</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >具体</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >细则详见</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >《中国人民财产保险股份有限公司责任保险附加家政</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务人员</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >责任保险条款》</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p></div>
        </div>
    </a-modal>
</template>
<script>

    export default {

        data: function () {
            return {
                visible: false,

                pic:null,
                // 表头

                loadData: parameter => {

                },
                selectedRows: [],
                selectedRowKeys: [],
            }
        },
        computed: {

        },
        beforeCreate() {
        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
        },
        mounted() {

        },
        destroyed() {
        },
        methods: {
            show(data) {
                this.pic = data;
                this.visible = true;
            },
        },
        watch: {}
    }
</script>
<style>
@font-face{
font-family:"Times New Roman";
}

@font-face{
font-family:"宋体";
}

@font-face{
font-family:"Calibri";
}

@font-face{
font-family:"Wingdings";
}

@font-face{
font-family:"仿宋_GB2312";
}

p.MsoNormal{
mso-style-name:正文;
mso-style-parent:"";
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:justify;
text-justify:inter-ideograph;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;
mso-font-kerning:1.0000pt;
}

span.10{
font-family:Calibri;
}

span.15{
font-family:Calibri;
}

span.16{
font-family:Calibri;
font-size:9.0000pt;
}

span.17{
font-family:Calibri;
font-size:9.0000pt;
}

span.18{
font-family:Calibri;
font-size:10.5000pt;
}

span.19{
font-family:Calibri;
font-weight:bold;
}

span.20{
font-family:Calibri;
font-size:9.0000pt;
}

p.MsoCommentText{
mso-style-name:批注文字;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:left;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;
mso-font-kerning:1.0000pt;
}

p.MsoCommentSubject{
mso-style-name:批注主题;
mso-style-noshow:yes;
mso-style-parent:批注文字;
mso-style-next:批注文字;
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:left;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-weight:bold;
font-size:10.5000pt;
mso-font-kerning:1.0000pt;
}

p.MsoHeader{
mso-style-name:页眉;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.7500pt solid windowtext;
padding:0pt 0pt 1pt 0pt ;
layout-grid-mode:char;
mso-pagination:none;
text-align:center;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;
mso-font-kerning:1.0000pt;
}

p.MsoFooter{
mso-style-name:页脚;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
layout-grid-mode:char;
mso-pagination:none;
text-align:left;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;
mso-font-kerning:1.0000pt;
}

p.MsoAcetate{
mso-style-name:批注框文本;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:justify;
text-justify:inter-ideograph;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;
mso-font-kerning:1.0000pt;
}

span.msoIns{
mso-style-type:export-only;
mso-style-name:"";
text-decoration:underline;
text-underline:single;
color:blue;
}

span.msoDel{
mso-style-type:export-only;
mso-style-name:"";
text-decoration:line-through;
color:red;
}

table.MsoNormalTable{
mso-style-name:普通表格;
mso-style-parent:"";
mso-style-noshow:yes;
mso-tstyle-rowband-size:0;
mso-tstyle-colband-size:0;
mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt;
mso-para-margin:0pt;
mso-para-margin-bottom:.0001pt;
mso-pagination:widow-orphan;
font-family:'Times New Roman';
font-size:10.0000pt;
mso-ansi-language:#0400;
mso-fareast-language:#0400;
mso-bidi-language:#0400;
}
@page{mso-page-border-surround-header:no;
	mso-page-border-surround-footer:no;}@page Section0{
margin-top:72.0000pt;
margin-bottom:72.0000pt;
margin-left:90.0000pt;
margin-right:90.0000pt;
size:595.3000pt 841.9000pt;
layout-grid:15.6000pt;
}
div.Section0{page:Section0;}
</style>