//登陆
<template>
    <div class="qui-layout">
        <div class="qui-header qui-header-black gracolor">
            <h2 class="qui-fs35">登录</h2>


        </div>
        <div class="qui-mgt40" align="center">
            <img class="imgwh1" src="../assets/imgs/fy35.png"/>
        </div>
        <a-form id="formLogin" class="user-layout-login" ref="formLogin" :form="form" @submit="handleSubmit">
                <div class="qui-bg-white qui-mgt40 qui-pd-0-30">
                    <a-form-item>
                        <div class="qui-row qui-bdr-bottom ">
                            <div class="qui-col20">
                                <div class="qui-formlabel">
                                    手机号
                                </div>
                            </div>
                            <div class="qui-col80">
                                <a-input class="qui-txt" placeholder="请输入手机号"
                                              v-decorator="[
                                  'loginAccount',
                                  {
                                    rules: [{ required: true, message: '手机号不能为空！' }],
                                    }
                                    ]"
                                    />

                            </div>
                        </div>
                     </a-form-item>
                     <a-form-item>
                        <div class="qui-row qui-bdr-bottom">
                            <div class="qui-col20">
                                <div class="qui-formlabel">
                                    密码
                                </div>
                            </div>
                            <div class="qui-col60">
                                <a-input type="password" class="qui-txt" placeholder="请输入密码"
                                          v-decorator="[
                              'loginPassword',
                              {
                                rules: [{ required: true, message: '密码不能为空！' }],
                                }
                                ]"
                                />

                            </div>
                            <div class="qui-col20" align="right">
                                <a href="#" class="qui-form-label qui-text-green " @click="forgetPwd">忘记密码</a>
                            </div>
                        </div>
                    </a-form-item>
                    <a-form-item>
                         <a-input @keyup="autoSubmit" size="large" type="text" :style="{width:'80%'}" autocomplete="false" placeholder="验证码" v-decorator="[
                         'captcha',
                         {rules: [{ required: true, message: '请输入验证码' }], validateTrigger: 'blur'}
                       ]">
                             <a-icon slot="prefix" type="safety" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                         </a-input>
                        <a href="javascript:;" @click="getCaptcha"><img :src="captchaSrc" style="width:20%;height33px;"/></a>
                     </a-form-item>
                </div>
                <div class="qui-row qui-mgt100 qui-pd-0-30 addborde">
                    <div class="qui-col50 qui-pdr15">
                        <a-button class="qui-btn qui-btn-full" size="large"   @click="handleSubmit" style="background-color:#09bb07;color:white"
                        >登录
                        </a-button>
                    </div>
                    <div class="qui-col50 qui-pdl15" align="right">
                        <a-button  class="qui-btn qui-btn-full" size="large"   @click="goRegister" style="background-color:#09bb07;color:white"
                        >注册
                        </a-button>
                    </div>
                </div>
        </a-form>

    </div>
</template>

<script>
    import {mapActions} from 'vuex'
    import * as oauth from '@/api/oauth'

    export default {
        data() {
            return {
                captchaSrc: '',
                accessToken: '',
                form: this.$form.createForm(this),
                state: {
                    loginBtn: false,
                }
            }
        },
        mounted() {
            this.getCaptcha();
            this.getOauthInfoHandler_();
        },
        methods: {
            ...mapActions(['Login']),
            goRegister:function(){
                this.$router.push({name:'Register'});
            },
            handleSubmit: function (e) {
                event.preventDefault();
                let _this = this;
                _this.form.validateFields(["loginAccount", "loginPassword", "captcha"], {force: true}, (err, values) => {
                    if (!err) {
                        _this.state.loginBtn = false;
                        _this.Login({
                            loginAccount: values.loginAccount,
                            loginPassword: values.loginPassword,
                            captcha: values.captcha,
                            accessToken: _this.accessToken
                        }).then((res) => {
                            localStorage.setItem("token",_this.accessToken);
                            _this.$router.push({name:'Insured',params:{indexNum:1}});
                        }).catch(err => {
                            _this.getCaptcha();
                            _this.form.setFieldsValue({
                                "captcha": ''
                            });
                        }).finally(() => {
                            _this.state.loginBtn = false
                        });

                    }
                })
            },
            getCaptcha: function () {
                oauth.getCaptcha().then(res => {
                    this.captchaSrc = res.captcha;
                    this.accessToken = res.accessToken;
                })
            },
            forgetPwd: function () {
                oauth.getCaptcha().then(res => {
                    this.$router.push({name:'CheckVerification'});
                })
            },
            getOauthInfoHandler_: function (){
                oauth.getOauthInfoHandler().then(res => {
                    console.log("用户信息",res);
                    let token = localStorage.getItem("token");
                    console.log("token",token);
                    if(res != null&& res != undefined){
                       this.$router.push({name:'Insured',params:{indexNum:1}});
                    }
                })
            },
            autoSubmit:function(e){
                let captcha = this.form.getFieldValue('captcha');

            }
        }
    }
</script>

