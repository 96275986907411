var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":'编辑',"width":550,"height":500,"footer":null},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',[_c('a-form',{attrs:{"form":_vm.form}},[_c('div',{staticClass:"qui-bg-white qui-pd-0-30"},[(this.updateName=='companyName')?_c('a-form-item',[_c('div',{staticClass:"qui-row qui-bdr-bottom"},[_c('div',{staticClass:"qui-col100"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'companyName',
                      {
                        rules: [{ required: true, message: '公司名不能为空！' }],
                        initialValue:this.userInfo.companyName
                        }
                        ]),expression:"[\n                      'companyName',\n                      {\n                        rules: [{ required: true, message: '公司名不能为空！' }],\n                        initialValue:this.userInfo.companyName\n                        }\n                        ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入公司名"}})],1)])]):_vm._e(),(this.updateName=='tel')?_c('a-form-item',[_c('div',{staticClass:"qui-row qui-bdr-bottom"},[_c('div',{staticClass:"qui-col100"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'tel',
                      {
                        rules: [{ required: true, message: '手机号不能为空！' }],
                        initialValue:_vm.userInfo.tel
                        }
                        ]),expression:"[\n                      'tel',\n                      {\n                        rules: [{ required: true, message: '手机号不能为空！' }],\n                        initialValue:userInfo.tel\n                        }\n                        ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输手机号"}})],1)])]):_vm._e(),(this.updateName=='email')?_c('a-form-item',[_c('div',{staticClass:"qui-row qui-bdr-bottom"},[_c('div',{staticClass:"qui-col100"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'email',
                      {
                        rules: [{ type: 'email', message: '邮箱格式不正确' }],
                        initialValue:_vm.userInfo.email
                        }
                        ]),expression:"[\n                      'email',\n                      {\n                        rules: [{ type: 'email', message: '邮箱格式不正确' }],\n                        initialValue:userInfo.email\n                        }\n                        ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入电子邮箱"}})],1)])]):_vm._e(),(this.updateName=='address')?_c('a-form-item',[_c('div',{staticClass:"qui-row qui-bdr-bottom"},[_c('div',{staticClass:"qui-col100"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'address',
                      {
                        rules: [{ required: true, message: '公司地址不能为空！' }],
                        initialValue:_vm.userInfo.address
                        }
                        ]),expression:"[\n                      'address',\n                      {\n                        rules: [{ required: true, message: '公司地址不能为空！' }],\n                        initialValue:userInfo.address\n                        }\n                        ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入公司地址"}})],1)])]):_vm._e(),(this.updateName=='idCard')?_c('a-form-item',[_c('div',{staticClass:"qui-row qui-bdr-bottom"},[_c('div',{staticClass:"qui-col100"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['idType', {
                         rules: [{required: true, message: '不能为空！'}],
                         initialValue:_vm.userInfo.idType.name
                         }]),expression:"['idType', {\n                         rules: [{required: true, message: '不能为空！'}],\n                         initialValue:userInfo.idType.name\n                         }]"}],attrs:{"placeholder":"请选择证件类型"}},[_c('a-select-option',{attrs:{"value":"tysh"}},[_vm._v("统一社会信用代码")]),_c('a-select-option',{attrs:{"value":"sfz"}},[_vm._v("身份证")])],1)],1)])]):_vm._e(),(this.updateName=='idCard')?_c('a-form-item',[_c('div',{staticClass:"qui-row qui-bdr-bottom"},[_c('div',{staticClass:"qui-col100"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'idCard',
                      {
                        rules: [{ required: true, message: '证件号码不能为空！' }],
                        initialValue:_vm.userInfo.idCard
                        }
                        ]),expression:"[\n                      'idCard',\n                      {\n                        rules: [{ required: true, message: '证件号码不能为空！' }],\n                        initialValue:userInfo.idCard\n                        }\n                        ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入证件号码"}})],1)])]):_vm._e(),(this.updateName=='pic1')?_c('a-form-item',[_c('div',{staticClass:"qui-row qui-bdr-bottom"},[_c('div',{staticClass:"qui-col100"},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        `pic1`,
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '不能为空',
                                },
                              ],
                            },
                          ]),expression:"[\n                        `pic1`,\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: '不能为空',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"multiple":false,"fileList":_vm.fileList,"beforeUpload":_vm.beforeUploadAvatarPic1,"accept":"image/*"},on:{"change":_vm.handleChange}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),(_vm.userInfo.pic1)?_c('span',[_vm._v("重新上传")]):_c('span',[_vm._v("点击上传")])],1)],1),(_vm.userInfo.pic1)?_c('img',{staticStyle:{"max-width":"300px","max-height":"100px"},attrs:{"shape":"square","size":"large","src":_vm.baseServerHostUrl+'/uploader/showImage?src='+_vm.pic1}}):_vm._e()],1)])]):_vm._e(),(this.updateName=='pic2')?_c('a-form-item',[_c('div',{staticClass:"qui-row qui-bdr-bottom"},[_c('div',{staticClass:"qui-col100"},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        `pic2`,
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '不能为空',
                                },
                              ],
                            },
                          ]),expression:"[\n                        `pic2`,\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: '不能为空',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"multiple":false,"fileList":_vm.fileList,"beforeUpload":_vm.beforeUploadAvatarPic2,"accept":"image/*"},on:{"change":_vm.handleChange}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),(_vm.userInfo.pic2)?_c('span',[_vm._v("重新上传")]):_c('span',[_vm._v("点击上传")])],1)],1),(_vm.userInfo.pic2)?_c('img',{staticStyle:{"max-width":"300px","max-height":"100px"},attrs:{"shape":"square","size":"small","src":_vm.baseServerHostUrl+'/uploader/showImage?src='+_vm.pic2}}):_vm._e()],1)])]):_vm._e(),(this.updateName=='pic3')?_c('a-form-item',[_c('div',{staticClass:"qui-row qui-bdr-bottom"},[_c('div',{staticClass:"qui-col100"},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        `pic3`,
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '不能为空',
                                },
                              ],
                            },
                          ]),expression:"[\n                        `pic3`,\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: '不能为空',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"multiple":false,"fileList":_vm.fileList,"beforeUpload":_vm.beforeUploadAvatarPic3,"accept":"image/*"},on:{"change":_vm.handleChange}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),(_vm.userInfo.pic3)?_c('span',[_vm._v("重新上传")]):_c('span',[_vm._v("点击上传")])],1)],1),(_vm.userInfo.pic3)?_c('img',{staticStyle:{"max-width":"300px","max-height":"100px"},attrs:{"shape":"square","size":"small","src":_vm.baseServerHostUrl+'/uploader/showImage?src='+_vm.pic3}}):_vm._e()],1)])]):_vm._e()],1),_c('div',{staticClass:"qui-pd-30 qui-mgt30 addborde"},[_c('a-button',{staticClass:"qui-btn qui-btn-full",staticStyle:{"background-color":"#09bb07","color":"white"},attrs:{"size":"large","loading":_vm.confirmLoading,"disabled":_vm.confirmLoading},on:{"click":_vm.handleSubmit}},[_vm._v("提交 ")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }