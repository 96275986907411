//修改密码
<template>
    <div class="qui-layout">
        <div class="qui-header qui-header-black gracolor">
            <h2 class="qui-fs35">修改密码</h2>
            <div class="left">
                <a href="javascript:history.go(-1)"><i class="iconfont icon-fanhui"></i></a>
            </div>
        </div>

        <a-form id="formLogin" class="user-layout-login" ref="formLogin" :form="form" @submit="handleSubmit">
        <a-form-item>
        <div class="qui-row qui-bdr-bottom qui-bg-white qui-pd-0-30">
            <div class="qui-formlabel qui-col30 ">
                旧密码
            </div>
            <div class="qui-col60">
                <a-input type="password" class="qui-txt" placeholder="请输入旧密码"
                          v-decorator="[
              'oldPwd',
              {
                rules: [{ required: true, message: '旧密码不能为空！' }],
                }
                ]"
                />

            </div>

        </div>
        </a-form-item>
        <a-form-item>
            <div class="qui-row qui-bdr-bottom qui-bg-white qui-pd-0-30">
                <div class="qui-formlabel qui-col30 ">
                    新密码
                </div>
                <div class="qui-col60">
                    <a-input type="password" class="qui-txt" placeholder="请输入新密码"
                              v-decorator="[
                  'newPwd',
                  {
                    rules: [{ required: true, message: '新密码不能为空！' }],
                    }
                    ]"
                    />

                </div>

            </div>
            </a-form-item>
        <a-form-item>
        <div class="qui-row qui-bdr-bottom qui-bg-white qui-pd-0-30">
            <div class="qui-formlabel qui-col30 ">
                确认密码
            </div>
            <div class="qui-col60">
                <a-input type="password" class="qui-txt" placeholder="确认密码"
                          v-decorator="[
              'confirmPwd',
              {
                rules: [{ required: true, message: '确认密码不能为空！' }],
                }
                ]"
                />
            </div>

        </div>
        </a-form-item>

        <div class="qui-pd-30 qui-mgt30 addborde">
            <a-button size="large" htmlType="submit" class="qui-btn qui-btn-full" style="background-color:#09bb07;color:white"
            :loading=confirmLoading   :disabled= confirmLoading
            >提交
                 </a-button>
        </div>
        </a-form>
        <Bottom></Bottom>
    </div>
</template>
<script>
      import config from '@/config'
      import * as resetPwd from '@/api/resetPwd'
      import Bottom from './Bottom'

    export default {
        components: {Bottom},
        data() {
            return {
                tel:this.$route.params.tel,
                visible: false,
                confirmLoading: false,
                form: this.$form.createForm(this),
            }
        },
        beforeCreate() {
            //组件实例刚刚被创建，data计算之前
            this.tel = this.$route.params.tel;
            console.log("tel的值");
            console.log(this.tel);
        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
        },
        mounted() {

        },
        destroyed() {
        },
        methods: {
             handleSubmit: function (e){
                e.preventDefault();
                const {form: {validateFields}} = this;
                this.confirmLoading = true;
                let _this = this
                validateFields((errors, values) => {
                    if (!errors) {
                    if(!(values.newPwd==values.confirmPwd)){
                        _this.showErrorMess("密码和确认密码不一致");
                        _this.confirmLoading = false;
                        return ;
                    }

                    resetPwd.modifyPwd(values)
                        .then(res => {
                        _this.confirmLoading = false;
                        _this.$router.push({name:'PersonalCenter'});
                    })
                    .finally(function() {
                            _this.confirmLoading = false
                        })
                    } else {
                        _this.confirmLoading = false
                    }
                    })
            },
            showErrorMess(errorMess){
              this.$notification.open({
                   message: '提示',
                   description:errorMess,
                 });
             },

        }
    }
</script>




