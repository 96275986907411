const getters = {
    device: state => state.app.device,
    theme: state => state.app.theme,
    color: state => state.app.color,
    multiTab: state => state.app.multiTab,
    addRouters: state => state.permission.addRouters,
    oauthInfo: state => state.oauth.oauthInfo,
    authorities: state => state.oauth.authorities
}

export default getters
