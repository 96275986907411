<template>
    <a-modal
            :title="'理赔须知'"
            :width="550"
            :height="500"
            v-model="visible"
            :footer='null'>
        <div>
       <div class="Section0"  style="layout-grid:15.6000pt;" ><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:36.1500pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
font-size:18.0000pt;" >&nbsp;&nbsp;</span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
font-size:18.0000pt;" >&nbsp;&nbsp;&nbsp;&nbsp;<font face="宋体" >理赔</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
font-size:18.0000pt;" ><font face="宋体" >须知</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
font-size:18.0000pt;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:26.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:'Microsoft YaHei UI';
mso-bidi-font-family:宋体;color:rgb(0,0,0);letter-spacing:0.4000pt;
mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;font-size:14.0000pt;" ><o:p>&nbsp;</o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >一</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >、</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >出险报案</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:32.0000pt;
mso-char-indent-count:2.5000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >出险后，投</font><font face="华文仿宋" >/被保险人（家政</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >服务</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >企业）需于出险之日起</font><font face="华文仿宋" >72小时内拨打010-95518进行报案。出险过程中如有疑问，可联系家政</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >保险</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >服务</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >专员</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >：</font><font face="华文仿宋" >1</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" >3810099841</span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >或</font><font face="华文仿宋" >010-88980531转8046</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><o:p>&nbsp;</o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >二</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >、</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >理赔</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >流程</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >第</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >一步：</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >电话</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >报案</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.6000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >拨打人保</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >客服</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >010</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" >-95518</span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >或服务</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >专员</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >13810099841报案。</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >第二</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >步</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >：</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >材料收集</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.6000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >可</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >联系</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >家政</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >保险服务专员</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >协助您</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >收集索赔材料</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >。</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >第</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >三步：</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >索赔</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:28.5000pt;
text-align:justify;text-justify:inter-ideograph;line-height:19.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >家政</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >保险</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >服务专员</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >将索赔材料初步审核后提交至保险公司，并跟进理赔进度，及时向您反馈理赔结果。</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >第</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >四</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >步</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >：赔款支付</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" >&nbsp;&nbsp;&nbsp;&nbsp;<font face="华文仿宋" >若</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >属于理赔范围，保险公司将在核定理赔金额后向</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >保险金</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><font face="华文仿宋" >申请人提供的银行卡支付保险金。</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:normal;mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><o:p>&nbsp;</o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >三</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >、</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >出险</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >措施</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >主险</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >：</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >《</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >家政服务机构责任保险条款</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >》</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >（一）雇主家庭保障责任</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.6000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >在保险期间内，家政服务人员在从事家政</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务工作过程中</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >，因服务员的</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >过失</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><font face="华文仿宋" >责任</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >导致雇主或雇主家庭成员以外人员（</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >第三者）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >遭受的直接人身损害或财产损失，保险公司将依据承保条款约定负责赔付。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(51,51,51);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >1）雇主家庭人身伤害</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.6000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >在保险期间内，家政服务人员在从事家政</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务工作过程中</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >，因服务员的</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >过失责任</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >导致雇主家庭造成的直接人身伤害，包含身故、伤残、医疗。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" >&nbsp;&nbsp;</span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" >&nbsp;<font face="华文仿宋" >例如：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >因服务人员在</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务过程中，因</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >工作</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >过失责任</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >导致照顾的老人或小孩摔伤、磕伤等（自费项目、传染性、病毒性、正常生理性、</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >自身运动伤害、自身病情发展</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >等产生</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >医疗</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >费用</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >不负责赔付</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >）。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(51,51,51);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" >&nbsp;&nbsp;&nbsp;</span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >注：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >出险后第一时间，</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >二级以上（含二级）公立</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >医院进行救治，保留所有医疗单据原件（含诊断证明、收费票据、费用明细。如涉及住院</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >，</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >另需提供住院病案复印件一套），救治结束后联系服务专员提交理赔申请材料。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(51,51,51);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><o:p>&nbsp;</o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);mso-list:l0 level1 lfo1;" ><![if !supportLists]><span style="font-family:华文仿宋;color:rgb(0,0,0);font-weight:bold;" ><span style='mso-list:Ignore;' >（2）</span></span><![endif]><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><font face="华文仿宋" >雇主家庭财产损失</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.6000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >在保险期间内，家政服务人员在从事家政服务工作过程中，因服务员的</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >过失责任</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >导致雇主家庭造成的直接财产损失（有价财产）。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:24.0000pt;
text-align:justify;text-justify:inter-ideograph;line-height:19.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >例如：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >家用电器、家具等的损坏（艺术品、动植物、金银玉器、珠宝首饰、古玩字画、邮票货币、食品、药品、烟酒、稀有金属、有价证券、文件账册、技术资料、电脑数据资料等珍贵财物或无法确定价值的财产损失不予赔付）。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" >&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" >&nbsp;</span><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >注：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >出险后第一时间，</font><font face="华文仿宋" >1、</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >现场视频或拍照</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（损坏程度、部位、品牌，无论是否可以赔付，现场照片或视频很重要，一定要先保留）；</font><font face="华文仿宋" >2、了解损坏物品的</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >价值</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >是</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >多少？</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >能否</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >修复</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >？核赔</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >会</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >根据物品</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >原始</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >价值</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >或使用时间损耗进行</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >折旧赔付。</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >3）第三者（雇主家庭成员外）</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.6000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >在保险期间内，家政服务人员在从事家政</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >工作过程中</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >，因服务员的过失责任导致第三者（雇主家庭成员以外人员）造成的直接人身伤害或财产损失（有价财产）。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:24.0000pt;
text-align:justify;text-justify:inter-ideograph;line-height:19.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >例如：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >家政服务</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >员</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >在雇主家保洁时，清洁工具</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >不慎</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >坠落砸伤楼下</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >路人</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:19.2500pt;
mso-char-indent-count:1.5000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >注</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" >:</span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >出险</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >后</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的理赔流程</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >同雇主人伤及财产</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><o:p>&nbsp;</o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >附加</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >险：</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >《</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >附加家政服务人员责任</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >保险</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >条款</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >》</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >（二）保障服务</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >人员</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:24.0000pt;
text-align:justify;text-justify:inter-ideograph;line-height:19.0000pt;
mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >在保险期间内，保障家政服务员在</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >工作过程</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >中</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（包含上下班途中</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >非本人主要责任</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的交通事故）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >发生的意外事故导致员工身故或残疾，以及因意外事故所产生的医疗费用等，保险公司将依据保险理赔条款进行赔付。</font></span><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >1）服务员意外身故、残疾保障</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.6000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >在保险期间内，保障家政服务员在</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >工作过程</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >中</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（包含上下班途中</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >非本人主要责任</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的交通事故）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >发生的意外事故导致身故或残疾（服务员自身疾病不负责赔付）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(51,51,51);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:19.2500pt;
mso-char-indent-count:1.5000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >注：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >属于重大案件，出险后需</font><font face="华文仿宋" >48小时内拨打010</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" >-</span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >95518，根据语音提示报出险服务员保单号，以便保险公司立案查勘。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(51,51,51);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><o:p>&nbsp;</o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >2）服务员意外医疗保障</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.6000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >在保险期间内，保障家政服务人员在</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >工作过程</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >中</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（包含上下班途中</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >非本人主要责任</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的交通事故）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >因意外事故所产生的医疗费用。</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >二级以上（含二级）公立医院</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >治疗过程中产生的治疗费、手术费、检查费、医药费等。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.7000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >例如</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >服务员</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >工作过程中</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >摔伤、扭伤、磕伤等。</font><font face="华文仿宋" >100元以内的意外医疗费用不负责赔付，100元以上部分100%赔付（</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >如</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >已从其它途径</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >社保、新农合或其它保险机构）已</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >报销</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的，对未</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >报销</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >部分可进行补充</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >赔付</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >，</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >但</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >进口药</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >、</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >进口医用器具及自费药除外</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(51,51,51);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.7000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >注：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >出险后第一时间，二级以上（含二级）公立医院进行救治，保留所有医疗单据原件（含诊断证明、收费票据、费用明细，如涉及住院需住院病历复印件等</font><font face="华文仿宋" >),救治结束后提交理赔申请材料。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(51,51,51);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><o:p>&nbsp;</o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >3）服务员工作期间猝死保障</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.6000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >在保险期间内，保障服务员在</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >工作时间和工作岗位</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >，突发疾病死亡或者在</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >48小时之内</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >经抢救无效死亡；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(51,51,51);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:19.2500pt;
mso-char-indent-count:1.5000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >注：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >属于重大案件，出险后</font><font face="华文仿宋" >48小时内拨打010-95518，按语音提示报出险服务员保单号，以便保险公司立案查勘。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(51,51,51);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><o:p>&nbsp;</o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >4）意外重伤误工费补助</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.6000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >在保险期间内，保障服务人员在</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >工作过程中</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（包含上下班途中</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >非本人主要责任</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的交通事故）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >因意外受伤，因重伤不能正常工作，根据医生</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >开具的</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >休假</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >证明</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >及</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >伤情综合</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >核定休假时间，按每天</font><font face="华文仿宋" >80元，最高赔付180天。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >5）上下</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >班途中意外事故</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" >&nbsp;&nbsp;&nbsp;</span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" >&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >在</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >保险期间内，</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >保障服务人员在往返工作地点途中发生的</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >非本人主要责任的交通事故</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >或者城市轨道交通、客运轮渡、火车事故伤害。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:26.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >温馨提示：</font></span></b><span style="mso-spacerun:'yes';font-family:仿宋_GB2312;mso-hansi-font-family:'Microsoft YaHei UI';
mso-bidi-font-family:宋体;color:rgb(0,0,0);letter-spacing:0.4000pt;
font-size:14.0000pt;mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >①</font></span></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >如服务</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >人员</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >在上下班途中发生交通意外事故，员工</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >负全部责任</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >或</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >主要责任</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >，因属于员工</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >违反交通法规</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >，属于本人主要责任，则保险公司</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >不予赔偿</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >②</font></span></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >如员工负事故的</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >同等</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >责任、</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >次要责任</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >或</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >无责</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >，应由肇事方按</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
letter-spacing:0.4000pt;font-size:12.0000pt;mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >责任比例</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >承担赔付责任后提供</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >分割单</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >，保险公司可按剩余</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >责任比例补充</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >赔偿；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >③</font></span></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >如肇事者</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >逃逸</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的，需提供《交通事故责任认定书》证明肇事者逃逸，保险公司可进行</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >代位赔偿</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >&#9315;</font></span></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >如肇事者无赔偿能力，需提供</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >法院判决及强制执行函</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >等，证明肇事方无赔偿能力，保险公司可进行</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >代位赔偿</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >&#9316;</font></span></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >如</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >私下解决</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >的，无法提供《交通事故责任认定书》，则保险公司</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(255,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >不予赔付</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><font face="华文仿宋" >。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-size:12.0000pt;
mso-font-kerning:0.0000pt;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><o:p>&nbsp;</o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >五</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >、理赔资料</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >1、一般医疗事故（含雇主及服务员意外）</font></span></b><b style="mso-bidi-font-weight:normal" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
mso-bidi-font-weight:normal;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >①</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >《</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >理赔申请</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >书</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >》</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >（有模板，家政公司填写并盖章）；</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >②</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >医疗</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >单据</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >原件</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >（</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >含诊断证明、费用发票、费用明细、</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >检查报告单</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >等。如涉及住院的，另需提供住院病案</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >复印件一</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >套）；</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >③</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >受害</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >人</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >（</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >雇主或家政服务员）</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >身份证复印件；</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >&#9315;</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >受害人</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >（</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >雇主或家政服务员）与家政公司签订的合同复印件；</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >&#9316;</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >如涉及</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >雇主</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >或护理对象</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >（老人或小孩）</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >医疗事故</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >，</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >另</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >需提供监护人（与</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >家政</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >公司签订</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >服务</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >合同）的身份证复印件</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >及关系</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >证明（</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >户口本复印件或</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >出生证明）</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >；</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >&#9317;</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >《一次性了结协议》（有模板，家政填写盖章，受害人签字确认）；根据案件审核情况，另需提供家政公司</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >向受害人</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >已</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >支付赔款</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >的</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >凭证</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >，</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >或</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >企业</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >委托保险公司</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >将</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >赔款</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >直接</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >支付给受害人</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >；</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >&#9318;</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >保单复印件；</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >&#9319;</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >《客户信息补充单》（为</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >防止反洗钱行为，</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >赔偿申请额达</font><font face="华文仿宋" >1万元（含1万元）以上，</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >另需提供企业营业执照</font>+法人身份证复印件</span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >）。</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:normal;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:normal;" ><o:p>&nbsp;</o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" >2</span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >、雇主财产损失</font></span></b><b style="mso-bidi-font-weight:normal" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
mso-bidi-font-weight:normal;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >①</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >《</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >理赔申请书》</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >（家政公司填写并盖章）；</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >②</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >证明财产价值（原</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >购买</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >凭证或维修发票、</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >维修检测报告</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >及维修报价单）；</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >③</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >现场照片（损坏部位</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >、</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >品牌、损坏面积）</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >；</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >&#9315;</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >《一次性了结协议》（有模板参考，家政公司填写并盖章，受害人签字确认）；根据案件审核情况，另需提供</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >企业向受害人</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >已</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >支付赔款</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >的</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >凭证</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >，</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >或</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >企业</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >委托保险公司</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >将</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >赔款</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >直接</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >支付给受害人</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >）；</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >&#9316;</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >受害</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >人（雇主）及</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >家政服务员身份证复印件；</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >&#9317;</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >受害</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >人（</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >雇主）及家政服务员与公司签订的合同复印件；</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >&#9318;</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >保单复印件；</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >&#9319;</font></span></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >《客户信息补充单》（为</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >防止反洗钱行为，</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >赔偿申请金额超</font><font face="华文仿宋" >1万元（含1万元）以上，</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >另需提供企业营业执照</font>+法人</span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >、</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >股东身份证复印件</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >）。</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:normal;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:normal;" ><o:p>&nbsp;</o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >3、暂时丧失工作能力（</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >申报误工费）</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.6000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >丧失工作能力超过</font><font face="华文仿宋" >3天（不包括3天）的，除报销一般医疗费用的，需申报误工费的需提供出险前3个月工资表</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >（公司盖章），</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >误工费的核准</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >需根据</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >提供</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >的医疗单据</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >及医院开具休假</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >条</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >及伤者病情等综合情况确定赔付</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >金额</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >。</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >赔偿公式为：</font> <font face="华文仿宋" >80元/天×（实际暂时丧失工作能力天数－</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" >3</span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >天），最高赔付天数为</font><font face="华文仿宋" >180天。</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:normal;" ><o:p>&nbsp;</o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >4、死亡、永久丧失全部/部分工作能力</font></span></b><b style="mso-bidi-font-weight:normal" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
mso-bidi-font-weight:normal;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.6000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >伤残</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >赔付</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >按伤残鉴定机构出具的伤残程度鉴定书，并对照国家发布的《伤残鉴定标准》，根据伤残等级而支付相应赔偿金。</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:25.6000pt;
mso-char-indent-count:2.0000;text-align:justify;text-justify:inter-ideograph;
line-height:19.0000pt;mso-line-height-rule:exactly;background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >死亡</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >赔偿</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >需</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >提供</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >死亡、</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >火</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >化</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >、尸检</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >、户籍</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >注销等</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >证明；以及保险公司认为必要的其他单证材料，根据案件情况确定。</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><o:p>&nbsp;</o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >理赔资料邮寄</font></span></b><b style="mso-bidi-font-weight:normal" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:bold;
mso-bidi-font-weight:normal;" ><o:p></o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >北京市石景山区时代花园南路</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >17号</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >茂华大厦</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >十层</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >（中国</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >人民</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >保险）</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:normal;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >收件人</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >：</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >王红玲</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:normal;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >电话</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >：</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >13911088652</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><o:p></o:p></span></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><o:p>&nbsp;</o:p></span></b></p><p class=p  align=justify  style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-align:justify;
text-justify:inter-ideograph;line-height:19.0000pt;mso-line-height-rule:exactly;
background:rgb(255,255,255);" ><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >说明</font></span></b><b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:bold;" ><font face="华文仿宋" >：</font></span></b><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >以上理赔资料齐全，</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >一般</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >案件</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >7至10个</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >工作日内结案</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >到账</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >；特殊或重大案件一般</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >30个</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;mso-ansi-font-weight:normal;
mso-bidi-font-weight:bold;" ><font face="华文仿宋" >工作日内结案到账。</font></span><span class="15"  style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:宋体;
color:rgb(0,0,0);letter-spacing:0.4000pt;font-weight:normal;" ><o:p></o:p></span></p></div>
        </div>
    </a-modal>
</template>
<script>

    export default {

        data: function () {
            return {
                visible: false,

                pic:null,
                // 表头

                loadData: parameter => {

                },
                selectedRows: [],
                selectedRowKeys: [],
            }
        },
        computed: {

        },
        beforeCreate() {
        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
        },
        mounted() {

        },
        destroyed() {
        },
        methods: {
            show(data) {
                this.pic = data;
                this.visible = true;
            },
        },
        watch: {}
    }
</script>
<style>
@font-face{
font-family:"Times New Roman";
}

@font-face{
font-family:"宋体";
}

@font-face{
font-family:"Calibri";
}

@font-face{
font-family:"Wingdings";
}

@font-face{
font-family:"仿宋_GB2312";
}

p.MsoNormal{
mso-style-name:正文;
mso-style-parent:"";
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:justify;
text-justify:inter-ideograph;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;
mso-font-kerning:1.0000pt;
}

span.10{
font-family:Calibri;
}

span.15{
font-family:Calibri;
}

span.16{
font-family:Calibri;
font-size:9.0000pt;
}

span.17{
font-family:Calibri;
font-size:9.0000pt;
}

span.18{
font-family:Calibri;
font-size:10.5000pt;
}

span.19{
font-family:Calibri;
font-weight:bold;
}

span.20{
font-family:Calibri;
font-size:9.0000pt;
}

p.MsoCommentText{
mso-style-name:批注文字;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:left;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;
mso-font-kerning:1.0000pt;
}

p.MsoCommentSubject{
mso-style-name:批注主题;
mso-style-noshow:yes;
mso-style-parent:批注文字;
mso-style-next:批注文字;
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:left;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-weight:bold;
font-size:10.5000pt;
mso-font-kerning:1.0000pt;
}

p.MsoHeader{
mso-style-name:页眉;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.7500pt solid windowtext;
padding:0pt 0pt 1pt 0pt ;
layout-grid-mode:char;
mso-pagination:none;
text-align:center;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;
mso-font-kerning:1.0000pt;
}

p.MsoFooter{
mso-style-name:页脚;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
layout-grid-mode:char;
mso-pagination:none;
text-align:left;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;
mso-font-kerning:1.0000pt;
}

p.MsoAcetate{
mso-style-name:批注框文本;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:justify;
text-justify:inter-ideograph;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;
mso-font-kerning:1.0000pt;
}

span.msoIns{
mso-style-type:export-only;
mso-style-name:"";
text-decoration:underline;
text-underline:single;
color:blue;
}

span.msoDel{
mso-style-type:export-only;
mso-style-name:"";
text-decoration:line-through;
color:red;
}

table.MsoNormalTable{
mso-style-name:普通表格;
mso-style-parent:"";
mso-style-noshow:yes;
mso-tstyle-rowband-size:0;
mso-tstyle-colband-size:0;
mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt;
mso-para-margin:0pt;
mso-para-margin-bottom:.0001pt;
mso-pagination:widow-orphan;
font-family:'Times New Roman';
font-size:10.0000pt;
mso-ansi-language:#0400;
mso-fareast-language:#0400;
mso-bidi-language:#0400;
}
@page{mso-page-border-surround-header:no;
	mso-page-border-surround-footer:no;}@page Section0{
margin-top:72.0000pt;
margin-bottom:72.0000pt;
margin-left:90.0000pt;
margin-right:90.0000pt;
size:595.3000pt 841.9000pt;
layout-grid:15.6000pt;
}
div.Section0{page:Section0;}
</style>