<template>
    <a-modal
            :title="'投保须知'"
            :width="550"
            :height="500"
            v-model="visible"
            :footer='null'>
        <div>
        <div class="Section0"  style="layout-grid:15.6000pt;" ><p class=MsoNormal ><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;
mso-font-kerning:1.0000pt;" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;
font-size:22.0000pt;mso-font-kerning:1.0000pt;" >&nbsp;</span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:22.0000pt;
mso-font-kerning:1.0000pt;" >&nbsp;&nbsp;</span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;
font-size:18.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" >投保须知</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:18.0000pt;
mso-font-kerning:1.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="line-height:24.0000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:14.0000pt;
mso-font-kerning:1.0000pt;" ><o:p>&nbsp;</o:p></span></b></p><p class=MsoNormal  style="line-height:23.0000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" >1</span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >、</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >投保</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >服务</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="line-height:23.0000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" >&nbsp;&nbsp;&nbsp;&nbsp;<font face="华文仿宋" >本</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >产品</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >的</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >承保机构为：中国人民财产保险股份有限公司北京市分公司，</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >由</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >中京国际保险销售（</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >北京</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >有限</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >公司</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >（机构</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >编号</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >：</font><font face="华文仿宋" >201962000000800）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >为您提供全流程线上服务。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:23.0000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >2、投保时效</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="text-indent:27.7500pt;line-height:23.0000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >注册：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >首次投保</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >需注册</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >，根据</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >公司营业执照信息，填写公司名称、统一社会信用代码</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >（</font></span><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
color:rgb(255,0,0);mso-ansi-font-weight:bold;font-size:12.0000pt;
mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >注意：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >务必需按营业执照上</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >的</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >信息填写</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >，</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >否则会影响保单、发票、理赔的正常出具和办理），并</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >上传公司营业执照</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >；如是</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >个体工商户</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >，</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >另需</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >上传</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >法人身份证正反面</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="text-indent:27.7500pt;line-height:23.0000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >投保</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >注册</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >后可</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >随时</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >（</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >不分周六日、节假日）进行投保。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="text-indent:27.7500pt;line-height:23.0000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >生效</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >当日投保</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >，</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >次</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >日零时保险生效。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >例如</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >：</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >最晚于</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >当日</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >23:59分</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >前投保，次日</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >零时（</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" >0</span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >点</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >）保险生效。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:23.0000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >3、投保条件</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="line-height:23.0000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >1）投/被</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >保险人：</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >在工商局注册并正常经营的家政服务企业；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:23.0000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >2）投保标的：年龄为18至65周岁的</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >家政服务人员</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >；</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:23.0000pt;mso-line-height-rule:exactly;" ><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >4、</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >信息变更</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="line-height:23.0000pt;mso-line-height-rule:exactly;" ><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" >&nbsp;<font face="华文仿宋" >（</font><font face="华文仿宋" >1）被</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >保标的</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >变更：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >如</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >被</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >保标的（</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >服务</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >人员）离职需办理雇员变更，当日提交变更信息，次日零时</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >保险</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >生效。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:23.0000pt;mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" >&nbsp;</span><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >（</font><font face="华文仿宋" >2）投/被</font></span></b><b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >保险人变更：</font></span></b><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >如投</font><font face="华文仿宋" >/被</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >保险人（</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >家政</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >服务机构）</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >公司</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >名称</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >或</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >公司营业执照信息变更，需</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >联系</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >家政服务专员</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >进行</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >线下变更申请。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-bidi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:23.0000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >5、保单、</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >发票</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="text-indent:24.0000pt;mso-char-indent-count:2.0000;line-height:23.0000pt;
mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >线上录入投保</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >相关</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >信息后</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >，</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >保费</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >可</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >通过微信</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >、</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >支付宝、银行</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >卡</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >等方式</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >在线支付</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >保费，</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >支付</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >成功后，即可下载电子保单</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >或开具电子</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >发票。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="text-indent:24.0000pt;mso-char-indent-count:2.0000;line-height:23.0000pt;
mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >如</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >需</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >开具</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >增值税专用发票，</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >需联系</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >家政服务专员线下申请开具。</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="line-height:23.0000pt;mso-line-height-rule:exactly;" ><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >6、</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >业务咨询</font></span></b><b style="mso-bidi-font-weight:normal" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal  style="text-indent:18.0000pt;mso-char-indent-count:1.5000;line-height:23.0000pt;
mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >投保后如有问题可提供一对一专属服务咨询。电话：</font><font face="华文仿宋" >010</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" >-88980531</span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >转</font><font face="华文仿宋" >8046</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="text-indent:24.0000pt;mso-char-indent-count:2.0000;line-height:23.0000pt;
mso-line-height-rule:exactly;" ><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >咨询</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >时间：</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >工作</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >时间上午</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >8:30至</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >下午</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="华文仿宋" >17:00</font></span><span style="mso-spacerun:'yes';font-family:华文仿宋;mso-bidi-font-family:'Times New Roman';
font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p></div>
        </div>
    </a-modal>
</template>
<script>

    export default {

        data: function () {
            return {
                visible: false,

                pic:null,
                // 表头

                loadData: parameter => {

                },
                selectedRows: [],
                selectedRowKeys: [],
            }
        },
        computed: {

        },
        beforeCreate() {
        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
        },
        mounted() {

        },
        destroyed() {
        },
        methods: {
            show(data) {
                this.pic = data;
                this.visible = true;
            },
        },
        watch: {}
    }
</script>
<style>
@font-face{
font-family:"Times New Roman";
}

@font-face{
font-family:"宋体";
}

@font-face{
font-family:"Calibri";
}

@font-face{
font-family:"Wingdings";
}

@font-face{
font-family:"仿宋_GB2312";
}

p.MsoNormal{
mso-style-name:正文;
mso-style-parent:"";
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:justify;
text-justify:inter-ideograph;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;
mso-font-kerning:1.0000pt;
}

span.10{
font-family:Calibri;
}

span.15{
font-family:Calibri;
}

span.16{
font-family:Calibri;
font-size:9.0000pt;
}

span.17{
font-family:Calibri;
font-size:9.0000pt;
}

span.18{
font-family:Calibri;
font-size:10.5000pt;
}

span.19{
font-family:Calibri;
font-weight:bold;
}

span.20{
font-family:Calibri;
font-size:9.0000pt;
}

p.MsoCommentText{
mso-style-name:批注文字;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:left;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;
mso-font-kerning:1.0000pt;
}

p.MsoCommentSubject{
mso-style-name:批注主题;
mso-style-noshow:yes;
mso-style-parent:批注文字;
mso-style-next:批注文字;
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:left;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-weight:bold;
font-size:10.5000pt;
mso-font-kerning:1.0000pt;
}

p.MsoHeader{
mso-style-name:页眉;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
border-bottom:1.0000pt solid windowtext;
mso-border-bottom-alt:0.7500pt solid windowtext;
padding:0pt 0pt 1pt 0pt ;
layout-grid-mode:char;
mso-pagination:none;
text-align:center;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;
mso-font-kerning:1.0000pt;
}

p.MsoFooter{
mso-style-name:页脚;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
layout-grid-mode:char;
mso-pagination:none;
text-align:left;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;
mso-font-kerning:1.0000pt;
}

p.MsoAcetate{
mso-style-name:批注框文本;
mso-style-noshow:yes;
margin:0pt;
margin-bottom:.0001pt;
mso-pagination:none;
text-align:justify;
text-justify:inter-ideograph;
font-family:Calibri;
mso-fareast-font-family:宋体;
mso-bidi-font-family:'Times New Roman';
font-size:9.0000pt;
mso-font-kerning:1.0000pt;
}

span.msoIns{
mso-style-type:export-only;
mso-style-name:"";
text-decoration:underline;
text-underline:single;
color:blue;
}

span.msoDel{
mso-style-type:export-only;
mso-style-name:"";
text-decoration:line-through;
color:red;
}

table.MsoNormalTable{
mso-style-name:普通表格;
mso-style-parent:"";
mso-style-noshow:yes;
mso-tstyle-rowband-size:0;
mso-tstyle-colband-size:0;
mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt;
mso-para-margin:0pt;
mso-para-margin-bottom:.0001pt;
mso-pagination:widow-orphan;
font-family:'Times New Roman';
font-size:10.0000pt;
mso-ansi-language:#0400;
mso-fareast-language:#0400;
mso-bidi-language:#0400;
}
@page{mso-page-border-surround-header:no;
	mso-page-border-surround-footer:no;}@page Section0{
margin-top:72.0000pt;
margin-bottom:72.0000pt;
margin-left:90.0000pt;
margin-right:90.0000pt;
size:595.3000pt 841.9000pt;
layout-grid:15.6000pt;
}
div.Section0{page:Section0;}
</style>