import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import config from '@/config'


import {VueAxios} from './vueAxios'
import notification from 'ant-design-vue/es/notification'
import {ACCESS_TOKEN} from '@/store/mutation-types'

const baseServerHostUrl = process.env.NODE_ENV === 'development' ? config.baseServerHostUrl.dev : config.baseServerHostUrl.prod;
const webappUrl = process.env.NODE_ENV === 'development' ? config.webappUrl.dev : config.webappUrl.prod;


// 创建 axios 实例
const service = axios.create({
    baseURL: baseServerHostUrl, // api base_url
    timeout: 60000 // 请求超时时间
})

const err = (error) => {
    if (error.response) {
        const token = Vue.ls.get(ACCESS_TOKEN)
        if (error.response.status === 403) {
            notification.error({
                message: 'Forbidden',
                description: '没有权限访问'
            })
        }
        if (error.response.status === 401) {
            notification.error({
                message: 'Unauthorized',
                description: 'Authorization verification failed'
            })
            if (token) {
                store.dispatch('Logout').then(() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                })
            }
        }
    }
    return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (token) {
        console.log('test1');
        config.headers['x-access-token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }else{
        console.log('test2');
    }
    return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
    if (['SUCCESS', 'MESSAGE_SUCCESS'].indexOf(response.data.code) > -1) {

        if (response.data.code === 'MESSAGE_SUCCESS') {
            notification.success({
                message: '系统提示',
                description: response.data.message

            });
        }

        return response.data.data;
    } else {
        if (['NO_FOUND', 'FORBIDDEN', 'NOT_SUFFICIENT_FUNDS', 'NO_LOGIN'].indexOf(response.data.code) > -1) {
            notification.warning({
                message: '警告',
                description: response.data.message
            });
        } else {
            notification.error({
                message: '错误',
                description: response.data.message
            });
        }
        if (response.data.code === 'NO_LOGIN') {
            store.dispatch('Logout').then(() => {
                setTimeout(() => {
                    window.location.href=webappUrl+"/Login";
                }, 1500)
            })
        }

        return Promise.reject(response.data)
    }
}, err);

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, service)
    }
}

export {
    installer as VueAxios,
    service as axios
}
