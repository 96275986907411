//企业注册
<template>

    <div class="qui-layout">
        <div class="qui-header qui-header-black gracolor">
            <h2 class="qui-fs35">注册</h2>


        </div>
        <h2 align="center" class="qui-text-green qui-fs30 qui-mgt30">企业注册</h2>
        <a-form id="formLogin" class="user-layout-login" ref="formLogin" :form="form" @submit="handleSubmit">
        <div class="qui-bg-white qui-pdl15 qui-mgt30" id="outDiv">
            <div class="qui-row  qui-pd-0-30">
                <a-form-item>
                <div class="qui-col25 qui-bdr-bottom">
                    <div class="qui-formlabel">
                        公司名称
                    </div>
                </div>
                <div class="qui-col75 qui-bdr-bottom qui-pdl15">

                   <a-input class="qui-txt" placeholder="请输入公司名称"
                              v-decorator="[
                  'companyName',
                  {
                    rules: [{ required: true, message: '不能为空！' }],
                    }
                    ]"
                          />
                </div>
                </a-form-item>
            </div>

            <div class="qui-row qui-pd-0-30">
             <a-form-item>
                <div class="qui-col25 qui-bdr-bottom ">
                    <div class="qui-formlabel">
                        证件类型
                    </div>
                </div>
                <div class="qui-col65 qui-bdr-bottom qui-pdl15 ">
                     <a-select
                            v-decorator="['idType', {
                     rules: [{required: true, message: '不能为空！'}],
                     initialValue:'tysh'
                     }]"
                            placeholder="请选择证件类型"
                    >
                        <a-select-option value="tysh">统一社会信用代码</a-select-option>
                        <a-select-option value="sfz">身份证</a-select-option>
                    </a-select>
                </div>

                 </a-form-item>
            </div>
            <div class="qui-row  qui-pd-0-30">
             <a-form-item>
                <div class="qui-col25 qui-bdr-bottom">
                    <div class="qui-formlabel">
                        证件编码
                    </div>
                </div>
                <div class="qui-col75 qui-bdr-bottom qui-pdl15">
                    <a-input class="qui-txt" placeholder="请输入证件编码"
                                  v-decorator="[
                      'idCard',
                      {
                        rules: [{ required: true, message: '不能为空！' }],
                        }
                        ]"
                              />
                </div>
             </a-form-item>
            </div>
            <div class="qui-row qui-pd-0-30">
             <a-form-item>
                <div class="qui-col25  qui-bdr-bottom">
                    <div class="qui-formlabel">
                        手机号
                    </div>
                </div>
                <div class="qui-col75  qui-bdr-bottom qui-pdl15">
                     <a-input class="qui-txt" placeholder="请输入联系方式"
                                  v-decorator="[
                      'tel',
                      {
                        rules: [{ required: true, message: '不能为空！' }],
                        }
                        ]"
                              />
                </div>
                                 </a-form-item>

            </div>
            <div class="qui-row  qui-pd-0-30">
                 <a-form-item>
                    <div class="qui-col25 qui-bdr-bottom">
                        <div class="qui-formlabel">
                            电子邮箱
                        </div>
                    </div>
                    <div class="qui-col75 qui-bdr-bottom qui-pdl15">
                        <a-input type="text" class="qui-txt" placeholder="请输入邮箱"
                        v-decorator="['email', {
                             rules: [
                              {
                                type: 'email', message: '邮箱格式不正确',
                              }
                             ],
                             }]"
                          />
                    </div>
                                     </a-form-item>

            </div>
            <div class="qui-row qui-pd-0-30">
             <a-form-item>
                <div class="qui-col25 qui-bdr-bottom">
                    <div class="qui-formlabel">
                        公司地址
                    </div>
                </div>
                <div class="qui-col75 qui-bdr-bottom qui-pdl15">
                    <a-textarea :autosize="{ minRows: 2, maxRows: 6 }" class="qui-txt" placeholder="请输入公司地址"
                              v-decorator="[
                  'address',
                  {
                    rules: [{ required: true, message: '不能为空！' }],
                    }
                    ]"
                    />
                </div>
                                 </a-form-item>

            </div>

            <div id="callBackUrlDiv" class="qui-row  qui-pd-0-30" style="display:none">
             <a-form-item>
                <div class="qui-col25 qui-bdr-bottom">
                    <div class="qui-formlabel">
                        回调地址
                    </div>
                </div>
                <div class="qui-col75 qui-bdr-bottom qui-pdl15">
                    <a-input type="text" class="qui-txt" placeholder="请输入回调地址" id="callBackUrlInput"
                        v-decorator="['callBackUrl', {
                             rules: [
                              {
                                 message: '不能为空!',
                              }
                             ],
                             }]"
                    />
                </div>
                </a-form-item>

            </div>
        </div>

        <div class="qui-bg-white qui-mgt20 qui-pd-0-30">
            <div class="qui-mgt20 qui-fs20">
                <font color="#000000">上传营业执照和法人身份证</font><font color="#999">(正反面)</font>
            </div>
             <a-row :gutter="16">
                 <a-col :md="8">
                     <a-spin tip="图片正在上传" size="middle" v-if="pic1IsUploading==true">
                     </a-spin>
                     <a-form-item  :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                         <img v-if="pic1" shape="square"  size="small" :src="baseServerHostUrl+'/uploader/showImage?src='+pic1" style="max-width:100px;max-height:100px" />
                         <br/>
                         <a-upload :multiple="false" :fileList="fileList" :beforeUpload="beforeUploadAvatarPic1" accept="image/*"  @change="handleChange"
                                   v-decorator="[
                                `pic1`,
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: '请上传营业执照',
                                        },
                                      ],
                                      initialValue:pic1
                                    },
                                  ]">
                             <a-button>
                                 <a-icon type="upload"></a-icon>
                                 <span>营业执照<span style="color:red">*</span></span>
                             </a-button>
                         </a-upload>
                     </a-form-item>
                 </a-col>
                 <a-col :md="8" >
                     <a-spin tip="图片正在上传" size="middle" v-if="pic2IsUploading==true">
                     </a-spin>
                     <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                         <img v-if="pic2" shape="square"  size="small" :src="baseServerHostUrl+'/uploader/showImage?src='+pic2" style="max-width:100px;max-height:100px" />
                         <br/>
                         <a-upload :multiple="false" :fileList="fileList" :beforeUpload="beforeUploadAvatarPic2" accept="image/*" @change="handleChange"
                                   v-decorator="[
                                `pic2`,
                                  ]">
                             <a-button>
                                 <a-icon type="upload"></a-icon>
                                 <span>身份证正面</span>
                             </a-button>
                         </a-upload>
                     </a-form-item>
                 </a-col>
                 <a-col :md="8" >
                     <a-spin tip="图片正在上传" size="middle" v-if="pic3IsUploading==true">
                     </a-spin>
                     <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                         <img v-if="pic3" shape="square"  size="small" :src="baseServerHostUrl+'/uploader/showImage?src='+pic3" style="max-width:100px;max-height:100px" />
                         <br/>
                         <a-upload :multiple="false" :fileList="fileList" :beforeUpload="beforeUploadAvatarPic3" accept="image/*" @change="handleChange"
                                   v-decorator="[
                                `pic3`,
                                  ]">
                             <a-button>
                                 <a-icon type="upload"></a-icon>
                                 <span>身份证反面</span>
                             </a-button>
                         </a-upload>
                     </a-form-item>
                 </a-col>
             </a-row>
        </div>
        <div class="qui-pd-30 qui-mgt30 addborde">
            <a-button size="large" htmlType="submit"
            :loading=confirmLoading   :disabled= confirmLoading style="background-color:#09bb07;color:white"
            class="qui-btn qui-btn-full" >提交注册
                                        </a-button>
        </div>
        </a-form>
    </div>
</template>



<script>
      import {doRegister} from '@/api/register'
      import {uploaderHandle} from '@/api/uploader'
      import config from '@/config'

    export default {
        data() {
            return {
                baseServerHostUrl: process.env.NODE_ENV === 'development' ? config.baseServerHostUrl.dev : config.baseServerHostUrl.prod,
                visible: false,
                confirmLoading: false,
                form: this.$form.createForm(this),
                editData: {},
                pic1:null,
                pic2:null,
                pic3:null,
                fileList: [],
                enCodeParentId:null,
                callBackUrl:null,
                isThirdPlatform:null,

                pic1IsUploading:false,
                pic2IsUploading:false,
                pic3IsUploading:false,
            }
        },
        beforeCreate() {
            //组件实例刚刚被创建，data计算之前
        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
        },
        mounted() {
            var url = location.search;
             var theRequest = new Object();
             if (url.indexOf("?") != -1) {
              var str = url.substr(1);
              var strs = str.split("&");
              for(var i = 0; i < strs.length; i ++) {
               console.log("参数"+strs[i].split("=")[0]+"=="+unescape(strs[i].split("=")[1]));
               if(strs[i].split("=")[0]=="enCodeParentId"){
                    this.enCodeParentId=unescape(strs[i].split("=")[1]);
               }
               else if(strs[i].split("=")[0]=="isThirdPlatform"&&strs[i].split("=")[1]=="true"){
                    this.isThirdPlatform=unescape(strs[i].split("=")[1]);
               }
              }
             }
        },
        destroyed() {
        },
        methods: {
            edit(data) {
                this.form.resetFields();
                this.editData = Object.assign({}, data);
                if (this.editData.pic1) {
                    this.pic1 = this.editData.pic1;
                }
                if (this.editData.pic2) {
                    this.pic2 = this.editData.pic2;
                }
                if (this.editData.pic3) {
                    this.pic3 = this.editData.pic3;
                }
                this.visible = true
            },
            handleChange(info) {
                let fileList = [...info.fileList];
                fileList = fileList.slice(1);//设置上传数量
                // 2. read from response and show file link
                fileList = fileList.map((file) => {
                    if (file.response) {
                        // Component will show file.url as link
                        file.url = file.response.url;
                    }
                    return file;
                });
                this.fileList = fileList
            },
            beforeUploadAvatarPic1(importFile) {
                let _this = this;
                _this.pic1IsUploading = true;
                uploaderHandle(importFile).then(res => {
                    _this.pic1=res.src;
                    _this.pic1IsUploading = false;
                    _this.showPicSuccess("图片上传成功");
                    console.log(_this.pic1);
                });
                return false
            },
            beforeUploadAvatarPic2(importFile) {
                let _this = this;
                _this.pic2IsUploading = true;
                uploaderHandle(importFile).then(res => {
                    _this.pic2=res.src;
                    _this.pic2IsUploading = false;
                    _this.showPicSuccess("图片上传成功");
                     console.log(_this.pic2);
                });
                return false
            },
            beforeUploadAvatarPic3(importFile) {
                let _this = this;
                _this.pic3IsUploading = true;
                uploaderHandle(importFile).then(res => {
                    _this.pic3=res.src;
                    _this.pic3IsUploading = false;
                    _this.showPicSuccess("图片上传成功");
                     console.log(_this.pic3);
                });
                return false
            },
            handleSubmit: function (e){
                e.preventDefault();
                const {form: {validateFields}} = this;
                this.confirmLoading = true;
                let _this = this
                validateFields((errors, values) => {
                console.log(errors);
                    if (!errors) {
                        if(this.pic1IsUploading == true||this.pic2IsUploading == true||this.pic3IsUploading == true){
                        _this.showErrorMess("图片上传未完成，无法提交");
                        _this.confirmLoading = false;
                        return ;
                    }
                    //如果身份类型是身份证，校验身份证号位数
                    if(values.idType=='sfz'){
                       var identityTest = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                       //如果身份证号填写错误
                       if(!(identityTest.test(values.idCard)&&(values.idCard.length==15||values.idCard.length==18))){
                            _this.showErrorMess("身份证号格式错误");
                            _this.confirmLoading = false;
                            return ;
                        }
                    }
                    values.pic1 = this.pic1;
                    values.pic2 = this.pic2;
                    values.pic3 = this.pic3;
                    values.enCodeParentId = this.enCodeParentId;
                    values.isThirdPlatform = this.isThirdPlatform;
                    doRegister(values)
                        .then(res => {
                        _this.confirmLoading = false;
                        _this.$router.push({name:'Login'});
                    })
                    .finally(function() {
                        _this.confirmLoading = false
                        })
                    } else {
                        _this.confirmLoading = false
                    }
                    })
            },
             //弹出提示框
           showErrorMess(errorMess){
            this.$notification.open({
                 message: '提示',
                 description:errorMess,
               });
           },
           showPicSuccess(mess){
            this.$notification.open({
                 message: '提示',
                 description:mess,
                 duration: 1,
               });
           },
        }
    }
</script>
<style>
.ant-form-explain{
    float:right;
}
</style>
