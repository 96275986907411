//底部导航栏
<template>
         <div class="qui-fnav">
            <ul class="qui-fnav-wp imgend">
                <li :class="{'active':activeLi == 1}">
                    <a href="javascript:;" @click="goOtherPage('Insured')">
                        <sup><img src="../assets/imgs/fy06.png"/></sup>
                        <sub><img src="../assets/imgs/fy06-1.png"/></sub>
                        <span>在线投保</span>
                    </a>
                </li>
                <li :class="{'active':activeLi == 2}">
                    <a href="javascript:;" @click="goOtherPage('Introduction')">
                        <sup><img src="../assets/imgs/fy07.png"/></sup>
                        <sub><img src="../assets/imgs/fy07-1.png"/></sub>
                        <span>方案介绍</span>
                    </a>
                </li>
                <li :class="{'active':activeLi == 3}">
                    <a href="javascript:;" @click="goOtherPage('MyOrder')">
                        <sup><img src="../assets/imgs/fy08.png"/></sup>
                        <sub><img src="../assets/imgs/fy08-1.png"/></sub>
                        <span>我的订单</span>
                    </a>
                </li>
                <li :class="{'active':activeLi == 4}">
                    <a href="javascript:;" @click="goOtherPage('PersonalCenter')">
                        <sup><img src="../assets/imgs/fy09.png"/></sup>
                        <sub><img src="../assets/imgs/fy09-1.png"/></sub>
                        <span>个人中心</span>
                    </a>
                </li>
            </ul>
    </div>
</template>
<script>
    export default {

    data: function () {
        return {
            activeLi:2,
    }
    },
    created() {
        var url = window.location.href;
        console.log(url.indexOf("Introduction")!=-1);

        if(url.indexOf("EnterInsurance")!=-1||url.indexOf("FjcyryzrxItem")!=-1||url.indexOf("Insured")!=-1||url.indexOf("JzjgzrxItem")!=-1){
            this.activeLi=1;
        }
        else if(url.indexOf("Introduction")!=-1){
            this.activeLi=2;
        }
        else if(url.indexOf("Invoice")!=-1||url.indexOf("ModifyEmployee")!=-1||url.indexOf("MyOrder")!=-1||url.indexOf("OrderDetail")!=-1){
            this.activeLi=3;
        }
        else if(url.indexOf("ModifyPwd")!=-1||url.indexOf("PersonalCenter")!=-1||url.indexOf("UpdateUserInfo")!=-1){
            this.activeLi=4;
        }
        console.log(this.activeLi);
    },

    methods: {
                goOtherPage(pageName,activeIndex){
                    this.$router.push({name:pageName});
                },
            },
    }
</script>



