

import Vue from 'vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';
import {VueAxios} from './utils/request'
import App from './App.vue'
import Storage from 'vue-ls';
import router from './router'
import store from './store/'
import 'moment/locale/zh-cn'

//公用样式
import '@/assets/css/quickly-mobile.css'
import '@/assets/css/style_fy.css'
import '@/assets/js/jquery-1.8.3.min.js'
import '@/assets/js/flexible.min.js'
import VueClipboard from "vue-clipboard2";


Vue.config.productionTip = false

Vue.use(Antd)
Vue.use(VueAxios)
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')



let options = {
  namespace: 'vuejs__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
};

Vue.use(Storage, options);

if (process.env.NODE_ENV === 'production') {
  /*第二层if，根据.env文件中的VUE_APP_FLAG判断是生产环境还是测试环境*/
  if (process.env.VUE_APP_FLAG === 'pro') {
    //production 生产环境
    this.axios.defaults.baseURL = 'https://server.jiazhengxian.com';

  } else {
    //test 测试环境
    this.axios.defaults.baseURL = 'http://insuranceapi.jzx.dliyun.com';

  }
} else {
  //dev 开发环境
  this.axios.defaults.baseURL = 'http://localhost:8002';
}
