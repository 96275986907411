//保险方案介绍
<template>
    <div class="qui-layout">
        <div class="qui-header qui-header-black gracolor">
            <h2 class="qui-fs35">保险方案</h2>
            <div class="left">
                <a href="javascript:history.go(-1)"><i class="iconfont icon-fanhui"></i></a>
            </div>

         </div>

         <Loading></Loading>




        <div v-for="(data,index) in loadData" :value="data.id" :key="data.id">
            <div  v-if="data.insuranceProjectList.length>0">
            <div class="qui-fs25 qui-mgt60 price2" align="center">
                <p>{{index+1}}、<font color="#09bb07">{{data.title.slice(0,1)}}</font>{{data.title.slice(1,data.title.length)}}</p>
            </div>
            <div class="qui-fs25" align="center" v-for="project in data.insuranceProjectList" :value="project.id" :key="project.id">
                <font color="#09bb07">{{project.name}}</font>
                <img  class="qui-mgt10 imgwh2" :src="baseServerHostUrl+'/uploader/showImage?src='+project.pic" @click="placeOrder(data.id,project.id)"/>
            </div>
            </div>

        </div>

        <Bottom></Bottom>



    </div>
</template>
<script>
    import * as insurance from '@/api/insurance'
    import config from '@/config'
    import Bottom from './Bottom'
    import Loading from './Loading'

    export default {
         components: {Bottom,Loading},
         data: function () {
            return {
                baseServerHostUrl:
                                    process.env.NODE_ENV === 'development' ? config.baseServerHostUrl.dev : config.baseServerHostUrl.prod,
                loadData: [],
                activeIndex:this.$route.params.indexNum,
        }
        },
        computed: {

        },
        beforeCreate() {
            //组件实例刚刚被创建，data计算之前

        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成

            insurance.getAllInsuranceDetail().then(res => {
                   console.log(res);
                   for(var i=0;i<res.length;i++){
                        if(res[i].insuranceProjectList.length>0){
                            this.loadData.push(res[i]);
                        }
                   }
                   document.getElementById("loadingDiv").style.display="none";

                   console.log(this.loadData);
            });
        },

        methods: {
            placeOrder:function(insuranceId,projectId){
                console.log(insuranceId);
                console.log(projectId);
                this.$router.push({name:'EnterInsurance',params:{insuranceId:insuranceId,projectId:projectId}});
            },
        }
    }
</script>

<style>
    h2{
        color:white
    }
</style>




