//录入保单
<template>
    <div class="qui-layout">
        <a-form id="formLogin" class="user-layout-login"  :form="form" @submit="handleSubmit">
            <div class="qui-header qui-header-black gracolor">
                <h2 class="qui-fs35">录入保单</h2>
                <div class="left">
                    <a href="javascript:history.go(-1)"><i class="iconfont icon-fanhui"></i></a>
                </div>
            </div>
            <Loading v-if="isDisplay==false"></Loading>

            <div v-if="isDisplay==true" >
            <div class="qui-row" align="center">
                <h1 class="qui-fs30 qui-mgt30">{{insuranceTitleHead}}</h1>
                <p class="qui-text-green qui-fs25">{{insuranceTitleBottom}}</p>
            </div>

            <div class="qui-btngroup fy-mg" align="center" >
                <div class=" qui-btn qui-btn-sm" v-for="(data,index ) in insuranceProjectList" :value="data.id" :key="data.id"
                    @click="setActive" :data-index="index" :class="{'active':index == navActive}"
                >
                    {{data.name}}
                </div>

            </div>
            <div class="qui-row qui-pd-0-30 qui-mgt20">
                <div class="qui-col35  qui-fs30">
                    方案保费:<font color="#09bb07" class="fon130">{{selectedProjectPrice}}元</font>
                </div>
                <div class="qui-col50  qui-fs30 fon130r">
                    方案保额：<font color="#09bb07" class="fon130">{{selectedProjectAmount}}元</font>
                </div>
            </div>

            <div class="qui-row qui-pd-0-30 qui-mgt20">
                <div class="qui-col35  qui-fs30">
                    总保费:<font color="#09bb07" class="fon130">{{totalPrice}}元</font>
                </div>
                <div class="qui-col50  qui-fs30 fon130r">
                    总保额：<font color="#09bb07" class="fon130">{{totalAmount}}元</font>
                </div>
            </div>


            <div class="qui-bg-white qui-mgt20 qui-pd-0-30">
                <div class="qui-row  qui-bdr-bottom">
                  <img  class="img29" :src="baseServerHostUrl+'/uploader/showImage?src='+selectedProjectPic" />
                </div>

            </div>

            <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                <div class="qui-formlabel qui-col40 qui-text-black">
                    <font class="qui-important" >*</font>起保时间
                </div>
                <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                    <a-form-item class="qui-txt">
                            <a-date-picker
                            :disabledDate="disabledDate"
                                    v-decorator="[
                        'startDate',
                        {
                          rules: [{ required: true, message: '不能为空！' }],
                          initialValue:initDate
                          }
                      ]"
                            />
                    </a-form-item>
                </div>
            </div>

            <div class="qui-row qui-bg-white qui-mgt20 qui-bdr-bottom qui-pd-0-30 qui-text-black">
                <div class="qui-formlabel qui-col40">
                    雇员信息展示
                </div>
                <div class="qui-col60 qui-formlabel qui-text-grey" >
                    <select class="qui-select" @change="showEmployeeDiv" id="showEmployeeSelect" >
                        <option value ="true"  >是</option>
                        <option value ="false" >否</option>
                    </select>
                </div>
            </div>
            <div id="employeeDiv" style="display:block">
                <div  v-for="k in form.getFieldValue('keys')"
                                                           :key="k">
                <div class="qui-row qui-bg-white qui-pd-0-30 qui-bdr-bottom">
                    <div class="qui-col50 qui-fs30 qui-formlabel qui-text-black">
                        <a-icon type="minus-circle" />
                        <a href="javascript:;"  class="qui-formlabel qui-text-green " id="j_getVerifyCode" name="2"  @click="() => remove(k)" >  删除该雇员</a>
                    </div>
                </div>

                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>姓名
                    </div>

                     <a-form-item >
                        <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                         <a-input class="qui-txt" placeholder="请输入姓名"
                                    v-decorator="[
                        `employeeParam[${k}].name`,
                        {
                          rules: [{ required: true, message: '不能为空！' }],

                          }
                      ]"
                            />
                        </div>
                    </a-form-item >
                </div>

                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>证件类型
                    </div>

                    <a-form-item >
                       <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                        <a-select  name="insuredRoleListIdType"  v-decorator="[
                            `employeeParam[${k}].idType`,
                            {
                              rules: [{ required: true, message: '不能为空！' }],
                              initialValue:'sfz'
                              }

                           ]">
                            <a-select-option value="sfz">身份证</a-select-option>
                            <a-select-option value="hkb" >户口簿</a-select-option>
                            <a-select-option value="hz" >护照</a-select-option>
                        </a-select>
                       </div>
                   </a-form-item >


                </div>
                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>证件号码
                    </div>

                     <a-form-item >
                            <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                                 <a-input class="qui-txt" placeholder="请输入证件号码" @blur="calculationAge"
                                            v-decorator="[
                                `employeeParam[${k}].idCard`,
                                {
                                  rules: [{ required: true, message: '不能为空！' }],

                                  }
                              ]"
                                    />
                            </div>
                    </a-form-item >
                </div>
                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>工种
                    </div>

                    <div class="qui-col60 qui-formlabel qui-text-grey" >
                        <a-form-item >
                            <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                             <a-input class="qui-txt" placeholder="请输入工种"
                                        v-decorator="[
                            `employeeParam[${k}].jobType`,
                            {
                              rules: [{ required: true, message: '不能为空！' }],

                              }
                          ]"
                                />
                            </div>
                        </a-form-item >
                    </div>
                </div>
                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>性别
                    </div>

                    <a-form-item >
                       <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                        <a-select  name="insuredRoleList2sex"  v-decorator="[
                            `employeeParam[${k}].sex`,
                            {
                              rules: [{ required: true, message: '不能为空！' }],
                              initialValue:'female'
                              }
                           ]">
                            <a-select-option value="female">女</a-select-option>
                            <a-select-option value="male">男</a-select-option>
                        </a-select>
                       </div>
                   </a-form-item>
                </div>
                 <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>年龄
                    </div>

                    <a-form-item >
                        <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                         <a-input class="qui-txt" placeholder="请填写年龄:16~65" name="ageInput"
                                    v-decorator="[
                        `employeeParam[${k}].age`,
                        {
                          rules: [{ required: true, message: '不能为空！' }],

                          }
                      ]"
                            />
                        </div>
                    </a-form-item >
                </div>
                </div>
            </div>

            <div class="qui-row qui-bg-white" align="center">
                <div class="qui-col40 " align="right">

                </div>
                <div class="qui-col60" align="left">
                    <div class="qui-formlabel">
                        <a-icon type="plus-circle" />
                        <a  @click="add" class=" qui-text-green"> 添加雇员</a>
                    </div>
                </div>

            </div>

            <div class="header">
                投保人信息
            </div>

            <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30 qui-mgt10" >
                <div class="qui-formlabel qui-col40 qui-text-black">
                    <font class="qui-important">*</font>名称
                </div>

                <a-form-item >
                <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                 <a-input class="qui-txt" disabled="disabled"
                            v-decorator="[
                'policyholderParam.name',
                {
                  rules: [{ required: true, message: '不能为空！' }],
                  initialValue:userBaseInfo.companyName
                  }
              ]"
                    />
                </div>
                </a-form-item >
            </div>
            <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                <div class="qui-formlabel qui-col40 qui-text-black">
                    <font class="qui-important">*</font>证件类型
                </div>

                 <a-form-item >
                    <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                     <a-select disabled="disabled" v-decorator="[
                         'policyholderParam.idType',
                         {
                           rules: [{ required: true, message: '不能为空！' }],
                           initialValue:userBaseInfo.idType?userBaseInfo.idType.name:''
                           }
                        ]">
                        <a-select-option value="tysh">统一社会信用代码</a-select-option>
                        <a-select-option value="sfz">身份证</a-select-option>
                     </a-select>
                    </div>
                    </a-form-item >
            </div>

            <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                <div class="qui-formlabel qui-col40 qui-text-black">
                    <font class="qui-important">*</font>证件号码
                </div>

                <a-form-item >
                <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                     <a-input class="qui-txt" disabled="disabled"
                                v-decorator="[
                    'policyholderParam.idCard',
                    {
                      rules: [{ required: true, message: '不能为空！' }],
                      initialValue:userBaseInfo.idCard
                      }
                  ]"
                        />
                </div>
                </a-form-item >
            </div>
            <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                <div class="qui-formlabel qui-col40 qui-text-black">
                    <font class="qui-important">*</font>手机号码
                </div>

                 <a-form-item >
                    <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                         <a-input class="qui-txt" disabled="disabled"
                                    v-decorator="[
                        'policyholderParam.mobile',
                        {
                          rules: [{ required: true, message: '不能为空！' }],
                          initialValue:userBaseInfo.tel
                          }
                      ]"
                            />
                    </div>
                </a-form-item >
            </div>
            <div class="header">
                被保人信息
            </div>

             <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30 qui-mgt10" >
                <div class="qui-formlabel qui-col40 qui-text-black">
                    <font class="qui-important">*</font>名称
                </div>

                <a-form-item >
                <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                 <a-input class="qui-txt" disabled="disabled"
                            v-decorator="[
                'insuredParam.name',
                {
                  rules: [{ required: true, message: '不能为空！' }],
                  initialValue:userBaseInfo.companyName
                  }
              ]"
                    />
                </div>
                </a-form-item >
            </div>
            <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                <div class="qui-formlabel qui-col40 qui-text-black">
                    <font class="qui-important">*</font>证件类型
                </div>

                 <a-form-item >
                    <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                     <a-select  disabled="disabled" v-decorator="[
                         'insuredParam.idType',
                         {
                            rules: [{ required: true, message: '不能为空！' }],
                            initialValue:userBaseInfo.idType?userBaseInfo.idType.name:''
                            }
                         ]">
                         <a-select-option value="tysh">统一社会信用代码</a-select-option>
                         <a-select-option value="sfz">身份证</a-select-option>
                      </a-select>
                     </div>
                     </a-form-item >
            </div>

            <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                <div class="qui-formlabel qui-col40 qui-text-black">
                    <font class="qui-important">*</font>证件号码
                </div>

                <a-form-item >
                <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                     <a-input class="qui-txt" disabled="disabled"
                                v-decorator="[
                    'insuredParam.idCard',
                    {
                      rules: [{ required: true, message: '不能为空！' }],
                      initialValue:userBaseInfo.idCard
                      }
                  ]"
                        />
                </div>
                </a-form-item >
            </div>
            <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                <div class="qui-formlabel qui-col40 qui-text-black">
                    <font class="qui-important">*</font>手机号码
                </div>

                 <a-form-item >
                    <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                         <a-input class="qui-txt" disabled="disabled"
                                    v-decorator="[
                        'insuredParam.mobile',
                        {
                          rules: [{ required: true, message: '不能为空！' }],
                          initialValue:userBaseInfo.tel
                          }
                      ]"
                            />
                    </div>
                </a-form-item >
            </div>
            <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
            <div class="qui-formlabel qui-col40 qui-text-black">
                <font class="qui-important">*</font>邮箱
            </div>
                <a-form-item >
                <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                     <a-input class="qui-txt" disabled="disabled"
                                v-decorator="[
                    'insuredParam.insurantEmail',
                    {
                      rules: [{ type: 'email', message: '邮箱格式不正确',}],
                      initialValue:userBaseInfo.email
                      }
                  ]"
                        />
                </div>
                </a-form-item >
            </div>
            <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-30">
                <div class="qui-formlabel qui-col40 qui-text-black">
                    <font class="qui-important">*</font>被保人地址
                </div>

                <a-form-item >
                <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                     <a-textarea :autosize="{ minRows: 2, maxRows: 6 }" class="qui-txt" disabled="disabled" overflow-wrap="break-word"
                                v-decorator="[
                    'insuredParam.insurantAddress',
                    {
                      rules: [{ required: true, message: '不能为空！' }],
                      initialValue:userBaseInfo.address
                      }
                  ]"
                        />
                </div>
                </a-form-item >
            </div>


            <div class="qui-row contract qui-fs25">
                <label class="">
                    <label class="qui-checkbox">
                        <a-checkbox @change="checkBoxChange" id="agreeItem"></a-checkbox>

                    </label>
                    本人已阅读<a @click="showItem('jzjgzrx_item')" href="javascript:;" style="color: #09bb07;">《中国人民财产保险股份有限公司家政服务机构责任保险条款》</a>,<a @click="showItem('fjcyryzrx_item')" href="javascript:;" style="color: #09bb07;">《中国人民财产保险股份有限公司家政服务机构责任保险附加家政服务人员责任保险条款》</a>
                    ,<a @click="showItem('lpxz_item')" href="javascript:;" style="color: #09bb07;">《理赔须知》</a>,<a @click="showItem('tbxz_item')" href="javascript:;" style="color: #09bb07;">《投保须知》</a>,<a @click="showItem('cpxz_item')" href="javascript:;" style="color: #09bb07;">《产品须知》</a>
                </label>
            </div>
            <div class="qui-pd-30 qui-mgt30 addborde" align="center">
                <a-button  class="qui-btn qui-btn-full" size="large" :loading=confirmLoading  @click="handleSubmit" style="background-color:#09bb07;color:white"
                                :disabled= confirmLoading                               >确定
                                                    </a-button>
            </div>
            </div>
        </a-form>
        <jzjgzrx-item ref="jzjgzrxItem"></jzjgzrx-item>
        <fjcyryzrx-item ref="fjcyryzrxItem"></fjcyryzrx-item>
        <tbxz-item ref="tbxzItem"></tbxz-item>
        <lpxz-item ref="lpxzItem"></lpxz-item>
        <cpxz-item ref="cpxzItem"></cpxz-item>

         <Bottom ></Bottom>

    </div>
</template>


<script>
    import * as insurance from '@/api/insurance'
    import {uploaderHandle} from '@/api/uploader'
    import config from '@/config'
    import moment from 'moment'
    import JzjgzrxItem from './JzjgzrxItem'
    import FjcyryzrxItem from './FjcyryzrxItem'
    import TbxzItem from './TbxzItem'
    import CpxzItem from './CpxzItem'
    import LpxzItem from './LpxzItem'
    import Bottom from './Bottom'
    import Loading from './Loading'

    let id = 1;
    export default {


        components: {JzjgzrxItem,FjcyryzrxItem,Bottom,Loading , TbxzItem , CpxzItem , LpxzItem},
        data() {
            return {
                baseServerHostUrl:
                                  process.env.NODE_ENV === 'development' ? config.baseServerHostUrl.dev : config.baseServerHostUrl.prod,
                insuranceId:this.$route.params.insuranceId,
                projectId:this.$route.params.projectId,
                visible: false,
                confirmLoading: false,

                editData: {},
                insuranceProjectList: [],
                insuranceData:{},
                userBaseInfo:{},
                selectedProjectPrice:null,
                selectedProjectAmount:null,
                selectedProjectPic:null,
                navActive:null,
                initDate:null,
                inputDisabled:true,
                //invalidEmployeeIndexArray:[],
                userIdType:null,
                itemReadFlag:false,
                totalPrice:null,
                totalAmount:null,
                employeeCount:1,
                disabledDate:[],

                showEmployeeFlag:true,
                insuranceTitleHead:null,
                insuranceTitleBottom:null,

                isDisplay:false,


            }
        },
        beforeCreate() {
            //组件实例刚刚被创建，data计算之前
            this.insuranceId = this.$route.params.insuranceId;
            this.form = this.$form.createForm(this);
            this.form.getFieldDecorator('keys', { initialValue: [1], preserve: true });


        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
            //对某些数据进行初始化
            this.disabledDate =  current => current && current < moment().endOf('day');
            insurance.placeOrder(this.insuranceId).then(res => {
                   this.isDisplay=true;
                   //document.getElementById("loadingDiv").style.display="none";
                   this.hideLoading=true;
                   this.insuranceData = res.insurance;
                   this.insuranceTitleHead=this.insuranceData.title;
                   this.insuranceTitleBottom="";
                   this.insuranceProjectList = res.insurancesProjectList;
                   this.userBaseInfo = res.userBaseInfo;
                   this.userIdType = "'"+this.userBaseInfo.idType+"'";

                   if(this.projectId!=null){
                        for(var i=0;i<this.insuranceProjectList.length;i++){
                            if(this.insuranceProjectList[i].id==this.projectId){
                                this.navActive=i;
                                break;
                            }
                        }
                   }
                   else{
                        this.navActive = 0;
                   }
                   this.selectedProjectPrice = this.insuranceProjectList[this.navActive].price;
                   this.selectedProjectAmount = this.insuranceProjectList[this.navActive].amount;
                   this.selectedProjectPic = this.insuranceProjectList[this.navActive].pic;
                   this.costCalculate();

                   var dateTime = new Date();
                   dateTime=dateTime.setDate(dateTime.getDate()+1);
                   dateTime=new Date(dateTime);
                   this.initDate = moment(dateTime,'YYYY-MM-DD');

            });
        },
        mounted() {
            console.log(this.insuranceData);

        },
        destroyed() {
        },
        watch:{
            showEmployeeFlag: {
                  handler: function (val, oldVal) {
                        console.log(val);
                        if(val=='true'){
                            document.getElementById("employeeDiv").style.display="block";
                        }
                        else{
                            document.getElementById("employeeDiv").style.display="none";
                        }
                   },

                },
        },
        methods: {

            edit(data) {
                this.form.resetFields();
                this.editData = Object.assign({}, data);
                this.visible = true
            },
            checkBoxChange:function(event){
                var target = event.target;
                console.log(target);
                this.itemReadFlag=target.checked;

            },
            //选择套餐，改变被选中套餐的样式
            setActive:function(event){
                    var target = event.target;
                    var index = Number(target.getAttribute("data-index"));
                    this.selectedProjectPrice = this.insuranceProjectList[index].price;
                    this.selectedProjectAmount = this.insuranceProjectList[index].amount;
                    this.selectedProjectPic = this.insuranceProjectList[index].pic;
                    this.costCalculate();
                    this.navActive = index;
                    this.inputDisabled = false;
               },
               //雇员如果身份类型是身份证，填写好身份证号，将年龄计算出来
               calculationAge:function(event){
                    var target = event.target;
                    var domName = target.getAttribute("id");
                    var index = domName.replace(/[^0-9]/ig,"");
                    //判断身份类型是不是身份证，是则按照身份证号计算年龄
                    var idTypeDomId = "employeeParam["+index+"].idType";
                    var idTypeDom = document.getElementById(idTypeDomId);

                    if(idTypeDom.value=="sfz"){
                        var ageId = "employeeParam["+index+"].age";
                        var ageDom = document.getElementById(ageId);
                        console.log("idcard的value"+idTypeDomId);
                        var idCardId = "employeeParam["+index+"].idCard";
                        var idCardDom = document.getElementById(idCardId);
                        var identityTest = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                        //校验身份证号是否合法

                        if(!(identityTest.test(idCardDom.value)&&(idCardDom.value.length==15||idCardDom.value.length==18))){
                            idCardDom.style.color="red";
                        }
                        else{
                            idCardDom.style.color="black";
                        }
                        //如果合法,调用年龄计算函数计算年龄
                        var ageNum = this.getAge(idCardDom.value);
                        this.form.setFieldsValue({["employeeParam["+index+"].age"]: ageNum,});
                        if(ageNum>65||ageNum<16){
                            ageDom.style.color="red";
                        }
                        else{
                            ageDom.style.color="black";
                        }

                    }

               },
               printTest:function(arr){
                    console.log("employeeParam[8].test的名字"+arr);
               },
               //提交
               handleSubmit() {
                   const {form: {validateFields}} = this;

                   this.confirmLoading = true;
                   let _this = this
                   validateFields((errors, values) => {
                       if (!errors) {

                       if(!_this.itemReadFlag){
                            _this.showErrorMess("请阅读条款");
                            this.confirmLoading = false;
                            return ;
                       }

                       //根据身份证号计算出年龄
                       _this.checkAgeWithIdCard();
                       //检查所有年龄是否符合要求
                       if(!_this.checkAllAgeRange()){
                        _this.showErrorMess("年龄或者身份证号有问题，年龄范围需在16-65之内，且需与身份证号的年龄相对应");
                        this.confirmLoading = false;
                        return ;
                       }
                       values.policyholderParam.relation=0;
                       values.insuranceProjectId=this.insuranceProjectList[_this.navActive].id;
                       values.insuranceId=this.insuranceData.id;
                       values.totalPrice=this.totalPrice;
                       values.totalAmount=this.totalAmount;
                       values.totalSumAmount=this.totalAmount;
                       values.totalSumPremium=this.totalPrice;
                       values.payWay="Y";


                       insurance.createOrder(values)
                           .then(res => {
                           _this.confirmLoading = false;
                           this.$router.push({name:'MyOrder'});
                   })
                   .finally(function() {
                           _this.confirmLoading = false

                       })
                   } else {
                       _this.showErrorMess("雇员信息填写不完整");
                       _this.confirmLoading = false

                   }
                   })
           },
          //校验身份类型为身份证的雇员身份证号是否合法，并且该身份证号计算出的年龄是否正确，不正确则自动校正
          checkAgeNumRight:function(){
                console.log("test");
            },
          //校验所有年龄是否在规定的取值范围之内16-65
          checkAllAgeRange:function(){
            var ageDomArray = document.getElementsByName("ageInput");
            var flag = true;
            for(var i=0;i<ageDomArray.length;i++){
                //如果年龄取值范围不符合要求(虽然在进行所有校验之前,将年龄设置为系统计算出来的值，但是，这个地方取的值还是旧值)
                if(ageDomArray[i].value>65||ageDomArray[i].value<16){
                    console.log(ageDomArray[i].getAttribute("id")+"年龄不符合要求"+ageDomArray[i].value);
                    ageDomArray[i].style.color="red";
                    flag=false;
                }
                else{
                   ageDomArray[i].style.color="black";
                }
            }
            return flag;
          },
          //年龄计算
          getAge:function(identityCard) {
                   var len = (identityCard + "").length;
                   if (len == 0) {
                       return 0;
                   } else {
                       if ((len != 15) && (len != 18))//身份证号码只能为15位或18位其它不合法
                       {
                           return 0;
                       }
                   }
                   var strBirthday = "";
                   if (len == 18)//处理18位的身份证号码从号码中得到生日和性别代码
                   {
                       strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
                   }
                   if (len == 15) {
                       strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
                   }
                   //时间字符串里，必须是“/”
                   var birthDate = new Date(strBirthday);
                   var nowDateTime = new Date();
                   var age = nowDateTime.getFullYear() - birthDate.getFullYear();
                   //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
                   if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
                       age--;
                   }
                   return age;
               },
               checkAgeWithIdCard:function() {
                   console.log("进入年龄身份证号判断");
                   var idTypeDomArray = document.getElementsByName("insuredRoleListIdType");
                   var identityTest = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                   var flag = true;
                   for(var i=0;i<idTypeDomArray.length;i++){
                       //如果是身份证
                       if(idTypeDomArray[i].value=='sfz'){
                           var idTypeId=idTypeDomArray[i].getAttribute("id");
                           var index = idTypeId.replace(/[^0-9]/ig,"");
                           var idCardDomId = "employeeParam["+index+"].idCard";
                           var idCardDom = document.getElementById(idCardDomId);
                           var ageId = "employeeParam["+index+"].age";
                           var ageDom = document.getElementById(ageId);
                           //如果身份证号填写错误
                           if(!(identityTest.test(idCardDom.value)&&(idCardDom.value.length==15||idCardDom.value.length==18))){
                                idCardDom.style.color="red";
                                flag = false;
                            }
                            //根据身份证号计算年龄,如果计算出的年龄大于65或者小于16，将身份证号标红
                            else{
                                var ageNum = this.getAge(idCardDom.value);
                                this.form.setFieldsValue({["employeeParam["+index+"].age"]: ageNum,});
                                if(ageNum>65||ageNum<16){
                                    idCardDom.style.color="red";
                                    ageDom.style.color="red";
                                }
                                else{
                                    idCardDom.style.color="black";
                                    ageDom.style.color="black";
                                    console.log("正常检测");
                                }

                            }
                       }

                   }
                   return flag;
               },

               remove  (k) {
                 const { form } = this;
                 // can use data-binding to get
                 const keys = form.getFieldValue('keys');
                 // We need at least one passenger
                 if (keys.length === 1) {
                   return;
                 }

                 // can use data-binding to set
                 form.setFieldsValue({
                   keys: keys.filter(key => key !== k),
                 });

                 this.employeeCount = this.employeeCount-1;
                 this.costCalculate();
               },

               add  () {
                 const { form } = this;
                 // can use data-binding to get
                 const keys = form.getFieldValue('keys');
                 console.log("keys");
                 console.log(keys);
                 const nextKeys = keys.concat(++id);
                 // can use data-binding to set
                 // important! notify form to detect changes
                 form.setFieldsValue({
                   keys: nextKeys,
                 });

                 this.employeeCount = this.employeeCount+1;
                 this.costCalculate();
                 document.getElementById("showEmployeeSelect").value="true";
                 console.log("select的值");
                 console.log(document.getElementById("showEmployeeSelect").value);
                 this.showEmployeeFlag='true';
               },
               costCalculate:function(){
                    this.totalPrice = this.employeeCount*this.selectedProjectPrice;
                    this.totalAmount = this.employeeCount*this.selectedProjectAmount;
               },

               showItem:function(pageName){
                    if(pageName=="jzjgzrx_item"){
                        this.$refs.jzjgzrxItem.show(pageName);
                    } else if  (pageName=="tbxz_item") {
                        this.$refs.tbxzItem.show();
                    } else if (pageName=="cpxz_item") {
                        this.$refs.cpxzItem.show();
                    } else if (pageName=="lpxz_item") {
                        this.$refs.lpxzItem.show();
                    }
                    else{
                        this.$refs.fjcyryzrxItem.show();
                    }
               },

               showEmployeeDiv:function(event){
                    var target = event.target;
                    console.log(target.value);
                    this.showEmployeeFlag = target.value;
               },

               //弹出提示框
               showErrorMess(errorMess){
                this.$notification.open({
                     message: '提示',
                     description:errorMess,
                   });
               },
        }
    }
</script>

<style>
    .ant-input-disabled {
        background-color: #fafafa00;
        opacity: 1;
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.85);
    }
    .qui-text-underline {
        text-decoration: none;
    }
    .ant-form-item{
        margin-bottom: 0px
    }
</style>




