import {axios} from '@/utils/request'
//重置密码模块

//重置密码
export const resetPwd = (data) => {
    return axios.request({
        url: '/resetPwd',

        data: data,
        method: 'POST'
    })
};

//获取验证码
export const getVerificationCode = (tel) => {
    return axios.request({
        url: '/getVerificationCode',
        params: {tel},
        method: 'POST'
    })
};

//校验验证码
export const checkVerificationCode = (tel,verificationCode) => {
    return axios.request({
        url: '/cheakVerificationCode',
        params:{"tel":tel,"verificationCode":verificationCode},
        method: 'POST'
    })
};

//修改密码
export const modifyPwd = (data) => {
    return axios.request({
        url: '/modifyPwd',
        data: data,
        method: 'POST'
    })
};


