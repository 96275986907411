<template>
      <div class="qui-layout">
      <Loading ></Loading>

      <a-form id="formLogin" class="order-detail"  :form="form">
               <div class="qui-header qui-header-black gracolor">
                  <h2 class="qui-fs35">订单详情</h2>
                  <div class="left">
                      <a href="javascript:history.go(-1)"><i class="iconfont icon-fanhui"></i></a>
                  </div>

              </div>
                  <div id="employeeDiv" >

                      <div v-for="(rowDate,index ) in tableData"  :key="index" class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30" >
                          <div  class="qui-formlabel qui-col40 qui-text-black key-width">

                              <a-tag @click="showEmployeesDetail()"  color="blue" :style="{margin:'5px 0 0 0'}" v-if="tableData[index].key=='雇员名称'">
                                    雇员详情
                              </a-tag>
                              <span v-else>{{tableData[index].key}}</span>

                          </div>
                          <div  class="qui-formlabel qui-col40 qui-text-black value-width">
                            {{tableData[index].value}}
                        </div>
                      </div>

                      <div v-if="showEmployee==true" v-for="employee in data.employeeList"  :key="employee.id"  >
                        <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30" >
                            <div  class="qui-formlabel qui-col40 qui-text-black key-width">
                                <font class="qui-important"></font>姓名
                            </div>
                            <div  class="qui-formlabel qui-col40 qui-text-black value-width">
                              <font class="qui-important"></font>{{employee.name}}
                            </div>
                        </div>

                        <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30" >
                          <div  class="qui-formlabel qui-col40 qui-text-black key-width">
                                <font class="qui-important"></font>证件类型
                            </div>
                            <div  class="qui-formlabel qui-col40 qui-text-black value-width">
                              <font class="qui-important"></font>{{employee.idType.text}}
                          </div>
                        </div>

                        <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30" >
                          <div  class="qui-formlabel qui-col40 qui-text-black key-width">
                                <font class="qui-important"></font>证件号码
                            </div>
                            <div  class="qui-formlabel qui-col40 qui-text-black value-width">
                              <font class="qui-important"></font>{{employee.idCard}}
                          </div>
                        </div>

                        <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30" >
                          <div  class="qui-formlabel qui-col40 qui-text-black key-width">
                                <font class="qui-important"></font>工种
                            </div>
                            <div  class="qui-formlabel qui-col40 qui-text-black value-width">
                              <font class="qui-important"></font>{{employee.jobType}}
                          </div>
                        </div>

                        <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30" >
                          <div  class="qui-formlabel qui-col40 qui-text-black key-width">
                                <font class="qui-important"></font>性别
                            </div>
                            <div  class="qui-formlabel qui-col40 qui-text-black value-width">
                              <font class="qui-important"></font>{{employee.sex.text}}
                            </div>
                        </div>

                        <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30" >
                          <div  class="qui-formlabel qui-col40 qui-text-black key-width">
                                <font class="qui-important"></font>年龄
                            </div>
                            <div  class="qui-formlabel qui-col40 qui-text-black value-width">
                              <font class="qui-important"></font>{{employee.age}}
                            </div>
                        </div>

                      </div>






                  </div>
              </a-form>


            <div class="qui-header qui-header-black gracolor">
                <h2 class="qui-fs35">订单详情</h2>
                <div class="left">
                    <a href="javascript:history.go(-1)"><i class="iconfont icon-fanhui"></i></a>
                </div>

            </DIV>

            <Bottom ></Bottom>

    </div>
</template>

<script>

    import * as myOrder from '@/api/myOrder'
    import Bottom from './Bottom'
    import Loading from './Loading'

    export default {
        components: {Bottom,Loading},
        data() {
            return {
                visible: true,
                form: this.$form.createForm(this),
                confirmLoading: false,
                tableData: [
                ],
                data:{},
                startTime:null,
                endTime:null,
                showEmployee:false,
            }
        },
         beforeCreate(){
            myOrder.getOrderDetail(this.$route.params.orderId).then(res => {
            document.getElementById("loadingDiv").style.display="none";
            this.data = res;
            this.tableData = [
                   {key: '订单号', value: this.data.orderNumber},
                   {key: '保单号', value: this.data.policyNo},
                   {key: '批单号', value: this.data.endorseNo},
                   {key: '保险方案', value: this.data.insurance.title},
                   {key: '保险套餐', value: this.data.insuranceProject.name},
                   {key: '起保日期', value: this.data.startDate},
                   {key: '终保日期', value: this.data.endDate},
                   {key: '保额', value: parseFloat(this.data.totalSumAmount).toFixed(2)},
                   {key: '保费', value: parseFloat(this.data.totalSumPremium).toFixed(2)},
                   {key: '订单总金额', value: this.data.totalPrice?parseFloat(this.data.totalPrice).toFixed(2):null},
                   {key: '实际支付金额', value: parseFloat(this.data.payPrice).toFixed(2)},
                   {key: '支付状态', value: this.data.payStatus?this.data.payStatus.text:null},
                   {key: '支付方式', value: this.data.payType},
                   {key: '支付时间', value: this.data.payTime},
                   {key: '创建时间', value: this.data.createTime},
                   {key: '更新时间', value: this.data.updateTime},
                   {key: '投保人信息', value: ""},
                   {key: '名称', value: this.data.policyholder.name},
                   {key: '证件类型', value: this.data.policyholder.idType.text},
                   {key: '证件号', value: this.data.policyholder.idCard},
                   {key: '手机号', value: this.data.policyholder.mobile},
                   {key: '被保人信息', value: ""},
                   {key: '名称', value: this.data.insured.name},
                   {key: '证件类型', value: this.data.insured.idType.text},
                   {key: '证件号', value: this.data.insured.idCard},
                   {key: '手机号', value: this.data.insured.mobile},
                   {key: '邮箱', value: this.data.insured.insurantEmail},
                   {key: '地址', value: this.data.insured.insurantAddress},
                   {key: '雇员名称', value: this.data.allEmployeeName},

               ];

            });

        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成

        },
        methods: {

            //弹出雇员详情卡片
            showEmployeesDetail(){
                this.showEmployee=(!this.showEmployee);
            }

        }
    }
</script>
<style scoped>
    .order-detail .value-width {
        width: 50%;
    }
    .order-detail .key-width {
        width: 41%;
    }

    .ant-form-item{
            margin-bottom: 0px
        }

    .qui-form-label, .qui-formlabel{
        font-size:15px
    }

</style>
