//忘记密码
<template>
    <div class="qui-layout">
        <div class="qui-header qui-header-black gracolor">
            <h2 class="qui-fs35">密码重置</h2>
            <div class="left">
                <a href="javascript:history.go(-1)"><i class="iconfont icon-fanhui"></i></a>
            </div>

        </div>
        <a-form id="formLogin" class="user-layout-login" ref="formLogin" :form="form" @submit="handleSubmit">
        <div class="qui-bg-white qui-pd-0-30">
        <a-form-item>
            <div class="qui-row qui-bdr-bottom">
                <div class="qui-col100">
                    <a-input class="qui-txt" placeholder="请输入手机号" id="telInput"
                              v-decorator="[
                  'tel',
                  {
                    rules: [{ required: true, message: '手机号不能为空！' }],
                    }
                    ]"
                    />
                </div>
            </div>
        </a-form-item>
        <a-form-item>
            <div class="qui-row">
                <div class="qui-col60">
                    <a-input class="qui-txt" placeholder="请输入验证码"
                                  v-decorator="[
                      'verificationCode',
                      {
                        rules: [{ required: true, message: '验证码不能为空！' }],
                        }
                        ]"
                        />
                </div>
                <div class="qui-col40 " align="right">
                    <a href="javascript:;" class="qui-formlabel qui-text-green qui-text-underline" id="j_getVerifyCode" @click="getVerificationCode">点击获取验证码</a>
                </div>
            </div>
            </a-form-item>
        </div>
        <div class="qui-pd-30 qui-mgt40">
            <a-button  class="qui-btn qui-btn-full" size="large"   @click="handleSubmit"
             :loading=confirmLoading   :disabled= confirmLoading >提交
                                    </a-button>
        </div>
        </a-form>

    </div>
</template>
<script>
      import config from '@/config'
      import * as resetPwd from '@/api/resetPwd'



    export default {

        data() {
            return {
                baseServerHostUrl: process.env.NODE_ENV === 'development' ? config.baseServerHostUrl.dev : config.baseServerHostUrl.prod,
                visible: false,
                confirmLoading: false,
                form: this.$form.createForm(this),

            }
        },
        beforeCreate() {
            //组件实例刚刚被创建，data计算之前
        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
        },
        mounted() {

        },
        destroyed() {
        },
        methods: {


            getVerificationCode:function(){
                var telInput = document.getElementById("tel");
                if(!telInput.value){
                    this.showErrorMess("请输入手机号");
                }
                else{
                        resetPwd.getVerificationCode(telInput.value).then(res => {
                        console.log(res);
                    })
                }
            },

            handleSubmit: function (e){
            const {form: {validateFields}} = this;
            this.confirmLoading = true;
            let _this = this;
            validateFields((errors, values) => {
            console.log(errors);
                if (!errors) {
                resetPwd.checkVerificationCode(values.tel,values.verificationCode)
                .then(res => {
                    _this.confirmLoading = false;
                    _this.$router.push({name:'ResetPwd',params:{tel:values.tel}});
                })
                .finally(function() {
                        _this.confirmLoading = false
                    })
                } else {
                    _this.confirmLoading = false
                }
                })
            },
            //弹出提示框
           showErrorMess(errorMess){
            this.$notification.open({
                 message: '提示',
                 description:errorMess,
                 duration: 1,
               });
           },

        }
    }
</script>



