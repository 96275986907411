import {axios} from '@/utils/request'
//注册模块
export const doRegister = (data) => {
    console.log('test');
    console.log(data);
    return axios.request({
        url: '/doRegister',
        data: data,
        method: 'POST'
    })
};


