//我的订单
<template>
    <div class="qui-layout">
        <div class="qui-header qui-header-black gracolor">
            <h2 class="qui-fs35">我的订单</h2>
            <div class="left">
                <a href="javascript:history.go(-1)"><i class="iconfont icon-fanhui"></i></a>
            </div>

        </div>
        <div class="qui-row">
            <a-col :md="8" :sm="24">
                <a-form-item label="雇员证件">
                    <a-input v-model="queryParam.employeeCard" />
                </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
                <a-form-item label="雇员姓名">
                    <a-input v-model="queryParam.employeeName"/>
                </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
                <a-button icon="search" @click="findOrderList" style="float:right;">查询</a-button>
            </a-col>
        </div>
        <div class="qui-row">
        <Loading v-if="isDisplay==false"></Loading>
        <div  v-if="userOrderList.length == 0" style="text-align:center;font-size:15px"  >
              <router-link :to="{ name: 'Insured'}" >
                  暂无数据，点我下单
              </router-link>
         </div>


        <div class="qui-pd-0-30"
            v-for="(data,index ) in userOrderList"
            :value="data.id" :key="data.id"
            :data-index="index"
        >

            <div class="qui-row qui-bg-white qui-mgt20 qui-pd-0-30 order">
                    <div class="qui-fs30 qui-col80">
                        <img src="../assets/imgs/fy27.png" class="img27"/><font class="order1" color="#000000">家政机构责任险</font>
                    </div>

                    <div class="qui-col20 qui-fs30" align="right" v-if="data.payStatus.name=='paid'">
                        <font color="green" >交易成功</font>
                    </div>
                    <div class="qui-col20 qui-fs30" align="right" v-if="data.payStatus.name=='unpaid'">
                        <font color="red" >等待付款</font>
                    </div>

                </div>

                <div class="qui-row qui-mgt40">
                    <div class="qui-col30">
                         <img :src="baseServerHostUrl+'/uploader/showImage?src='+data.insurancePic" class="img28"/>
                    </div>

                    <div class="qui-col70">
                        <div class="qui-col100 qui-fs35 order2">
                            <font color="#000000">{{data.insuranceTitle}}</font>
                        </div>

                        <div class="qui-row">
                            <div class="qui-col60 order2">
                                按<font color="green" v-if="data.actionTime>=12">年</font>
                                <font color="green" v-if="data.actionTime<12">月</font>投保.<font color="green">&nbsp;{{data.insuranceProjectName}}
                                </font>
                            </div>
                            <div class="qui-col40" align="right">
                                <p class="order3">￥{{parseFloat(data.projectPrice).toFixed(2)}}</p>
                            </div>
                        </div>

                        <div class="order3">
                            有效期：{{data.actionTime}}个月
                        </div>
                    </div>
                </div>
                <div class="order3" align="right">
                    <p>共{{data.employeeCount}}个雇员   合计：￥{{parseFloat(data.totalPrice).toFixed(2)}}</p>
                </div>
                <div class="order3" align="right">
                    <p>创建日期：{{data.createTime}}</p>
                </div>
                <div class="order3" align="right">
                    <p v-if="data.expiredFlag!=null&&data.expiredFlag=='expiring'" ><font color="orange" ><a-icon type="exclamation-circle" />保险即将到期</font></p>
                    <p v-if="data.expiredFlag!=null&&data.expiredFlag=='expired'" ><font color="red" >保险已到期</font></p>
                </div>

                <div class="qui-row order4 qui-mgt40 qui-overflowvisible" align="right" >
                    <a href="javascript:;" @click="deleteOrder(data.id)" class="qui-btn qui-btn-bdr qui-btn-sm" v-if="data.payStatus.code==0">删除订单</a>
                    <a href="javascript:;" class="qui-btn qui-btn-bdr qui-btn-sm"
                        v-if="data.payStatus.name=='paid'&&data.invoiceDownloadUrl==null"
                        @click="invoicing(data.id)"
                     >开发票</a>

                    <a   class="qui-btn qui-btn-bdr qui-btn-sm"
                         v-if="data.payStatus.name=='unpaid'"
                         :href="data.payUrl"
                         target="_blank"
                    >支付</a>

                    <a   class="qui-btn qui-btn-bdr qui-btn-sm"
                         v-if="data.payStatus.name=='paid'&&data.actionTime>11"
                         href="javascript:;"
                         @click="modifyEmployee(data)"
                    >替换雇员</a>

                    <a class="qui-btn qui-btn-bdr qui-btn-sm"
                         v-if="data.insuranceLoadUrl"
                         :href="data.insuranceLoadUrl+'&r='+_random_"
                         target="_blank"
                    >下载保单</a>

                     <a   class="qui-btn qui-btn-bdr qui-btn-sm"
                         v-if="data.invoiceDownloadUrl"
                         :href="data.invoiceDownloadUrl"
                         target="_blank"
                     >下载发票</a>

                    <a href="javascript:;" @click="getOrderDetail(data.id)" class="qui-btn qui-btn-bdr qui-btn-sm" >查看详情</a>
                </div>
            </div>
        </div>

        <Bottom ></Bottom>
    </div>
</template>


<script>
    import * as myOrder from '@/api/myOrder'
    import config from '@/config'
    import Bottom from './Bottom'
    import Loading from './Loading'
    import moment from 'moment'

    export default {
        components: {Bottom,Loading},
        data() {
            return {
                baseServerHostUrl:
                                  process.env.NODE_ENV === 'development' ? config.baseServerHostUrl.dev : config.baseServerHostUrl.prod,
                visible: true,
                confirmLoading: false,
                userOrderList: [],
                activeIndex:this.$route.params.indexNum,
                expiringDateTime:null,
                expiredDateTime:null,
                queryParam: {},
                isDisplay:false,
                _random_:null,
            }
        },
        beforeCreate() {

        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
            //对某些数据进行初始化
            console.log(this.$route.params.accessToken);

            this.expiringDateTime = new Date();
            this.expiringDateTime=this.expiringDateTime.setDate(this.expiringDateTime.getDate()+5);
            this.expiringDateTime=new Date(this.expiringDateTime);
            this.expiredDateTime = new Date();
            this.expiredDateTime=this.expiredDateTime.setDate(this.expiredDateTime.getDate());
            this.expiredDateTime=new Date(this.expiredDateTime);
            this.findOrderList();
            this._random_= Math.random();
            
        },
        mounted() {

        },
        destroyed() {
        },
        methods: {
            remindExpired(endDate){
                endDate = endDate.replace(new RegExp(/-/gm) ,"/");
               var endDateTime=new Date(endDate);
               var result = endDateTime.getTime()<this.expiringDateTime.getTime();

               if(!(endDateTime.getTime()>this.expiredDateTime.getTime())){
                    return 'expired';
               }

               else if(endDateTime.getTime()<this.expiringDateTime.getTime()){
                    return 'expiring';
               }


            },
            deleteOrder(id) {
                myOrder.deleteOrder(id).then(res => {
                    location.reload();
                });
            },
            getOrderDetail(id) {
                    this.$router.push({name:'OrderDetail',params:{orderId:id}});
            },
            modifyEmployee(data) {
                this.$router.push({name:'ModifyEmployee',params:{data:data}});
            },
            invoicing(orderId) {
                this.$router.push({name:'Invoice',params:{orderId:orderId}});
            },
            findOrderList(){
                myOrder.getMyOrders(this.$route.params.accessToken,Object.assign(this.queryParam)).then(res => {
                    this.isDisplay=true;
                    this.userOrderList = res.userOrderList;
                });
            }

        }
    }
</script>



