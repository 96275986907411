export const asyncRouterMap = [

    {
        path: '*', redirect: '/404', hidden: true
    }
];

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
    {
        path: '/404',
        redirect: '/dashboard',
    }
]


