import {axios} from '@/utils/request'
import {deleteEmptyProperty} from '@/utils/util'
//我的订单模块

//得到所有订单
export const getMyOrders = (accessToken, data) => {
    console.log(accessToken);
    return axios.request({
        url: '/getMyOrders',
        params:{"accessToken":accessToken},
        data: data,
        method: 'POST'
    })
};

//删除订单
export const deleteOrder = (orderId) => {
    return axios.request({
        url: '/deleteOrder',
        params:{"orderId":orderId},
        method: 'POST'
    })
};

//得到订单细节
export const getOrderDetail = (orderId,orderNumber) => {

    return axios.request({
        url: '/getOrderDetail',
        params:{"orderId":orderId,"orderNumber":orderNumber},
        method: 'POST'
    })
};

//批改雇员
export const updateEmployees = (data) => {
    console.log("调用后台方法");
    return axios.request({
        url: '/updateEmployees',
        data:data,
        method: 'POST'
    })
};







