//个人中心
<template>
    <div class="qui-layout">
    <div class="qui-header qui-header-black gracolor">
        <h2 class="qui-fs35">个人中心</h2>
        <div class="left">
            <a href="javascript:history.go(-1)"><i class="iconfont icon-fanhui"></i></a>
        </div>
    </div>
    <Loading v-if="isDisplay==false"></Loading>
    <div v-if="isDisplay==true" >
        <div class="userhead" align="center">
            <div class="img">
                <img class="imga" src="../assets/imgs/fy20.png"/>

            </div>
            <div class="qui-mgt10">
                <font class="qui-fs25" color="#000"></font>
            </div>
            <div class=" qui-mgt10">
                <font class="qui-fs25" color="#000">{{this.userInfo.companyName}}</font>
            </div>
        </div>

        <div class="qui-bg-white qui-pd-0-30">
            <div class="qui-row qui-bdr-bottom ">
                <a href="#" class="qui-col100" @click="modifyPwd()">
                    <div class="qui-col10 qui-formlabel">
                        <img class="img14" src="../assets/imgs/fy15.png" />
                    </div>
                    <div class="qui-col80 qui-form-label" >
                        <a href="javascript:;" @click="modifyPwd()">修改密码</a>
                    </div>
                    <div class="qui-col10 qui-formlabel" align="right">
                        <img src="../assets/imgs/fy19.png" width="12px" height="25px"/>
                    </div>
                </a>
            </div>
        </div>


        <div  class="qui-bg-white  qui-pd-0-30">
            <div class="qui-row qui-bdr-bottom qui-mgt20">
                <a href="#" class="qui-col100" @click="modifyUserInfo('companyName')">
                    <div class="qui-col10 qui-formlabel">
                        <a-icon type="user" />
                    </div>
                    <div class="qui-col80 qui-form-label">
                        公司名称：{{this.userInfo.companyName}}
                    </div>
                    <div class="qui-col10 qui-formlabel " align="right">
                        <img src="../assets/imgs/fy19.png" width="12px" height="25px"/>
                    </div>
                </a>
            </div>
            <div class="qui-row qui-bdr-bottom">
                <a href="#" class="qui-col100" @click="modifyUserInfo('idCard')">
                    <div class="qui-col10 qui-formlabel">
                        <a-icon type="idcard" />
                    </div>
                    <div class="qui-col80 qui-form-label">
                        证件号码：{{this.userInfo.idCard}}
                    </div>
                    <div class="qui-col10 qui-formlabel" align="right">
                        <img src="../assets/imgs/fy19.png" width="12px" height="25px"/>
                    </div>
                </a>
            </div>
            <div class="qui-row qui-bdr-bottom">
                <a href="#" class="qui-col100" @click="modifyUserInfo('tel')">
                    <div class="qui-col10 qui-formlabel">
                        <a-icon type="mobile" />
                    </div>
                    <div class="qui-col80 qui-form-label">
                        手机号：{{this.userInfo.tel}}
                    </div>
                    <div class="qui-col10 qui-formlabel" align="right">
                        <img src="../assets/imgs/fy19.png" width="12px" height="25px"/>
                    </div>
                </a>
            </div>
            <div class="qui-row qui-bdr-bottom ">
                <a href="#" class="qui-col100" @click="modifyUserInfo('email')">
                    <div class="qui-col10 qui-formlabel">
                        <a-icon type="mail" />
                    </div>
                    <div class="qui-col80 qui-form-label">
                        电子邮箱：{{this.userInfo.email}}
                    </div>
                    <div class="qui-col10 qui-formlabel " align="right">
                        <img src="../assets/imgs/fy19.png" width="12px" height="25px"/>
                    </div>
                </a>
            </div>
            <div class="qui-row qui-bdr-bottom ">
                <a href="#" class="qui-col100" @click="modifyUserInfo('address')">
                    <div class="qui-col10 qui-formlabel">
                        <a-icon type="environment" />
                    </div>
                    <div class="qui-col80 qui-form-label">
                        公司地址：{{this.userInfo.address}}
                    </div>
                    <div class="qui-col10 qui-formlabel " align="right">
                        <img src="../assets/imgs/fy19.png" width="12px" height="25px"/>
                    </div>
                </a>
            </div>

            <div class="qui-row qui-bdr-bottom " v-if="this.userInfo.pic1">
                <a href="#" class="qui-col100" @click="modifyUserInfo('pic1')">
                    <div class="qui-col10 qui-formlabel">
                        <a-icon type="picture" />
                    </div>
                    <div class="qui-col80 qui-form-label">
                         营业执照：
                        <img @click="modifyUserInfo('pic1')"  style="max-width:40px;max-height:40px" :src="baseServerHostUrl+'/uploader/showImage?src='+this.userInfo.pic1" />
                    </div>
                    <div class="qui-col10 qui-formlabel " align="right">
                        <img src="../assets/imgs/fy19.png" width="12px" height="25px"/>
                    </div>
                </a>
            </div>

            <div class="qui-row qui-bdr-bottom " v-if="this.userInfo.pic2">
                <a href="#" class="qui-col100" @click="modifyUserInfo('pic2')">
                    <div class="qui-col10 qui-formlabel">
                        <a-icon type="picture" />
                    </div>
                    <div class="qui-col80 qui-form-label">
                        身份证正面：<img @click="modifyUserInfo('pic2')"  style="max-width:40px;max-height:40px" :src="baseServerHostUrl+'/uploader/showImage?src='+this.userInfo.pic2" />
                    </div>
                    <div class="qui-col10 qui-formlabel " align="right">
                        <img src="../assets/imgs/fy19.png" width="12px" height="25px"/>
                    </div>
                </a>
            </div>

            <div class="qui-row qui-bdr-bottom " v-if="this.userInfo.pic3">
                <a href="#" class="qui-col100" @click="modifyUserInfo('pic3')">
                    <div class="qui-col10 qui-formlabel">
                        <a-icon type="picture" />
                    </div>
                    <div class="qui-col80 qui-form-label">
                        身份证反面：<img @click="modifyUserInfo('pic3')"  style="max-width:40px;max-height:40px" :src="baseServerHostUrl+'/uploader/showImage?src='+this.userInfo.pic3" />
                    </div>
                    <div class="qui-col10 qui-formlabel " align="right">
                        <img src="../assets/imgs/fy19.png" width="12px" height="25px"/>
                    </div>
                </a>
            </div>

            <div class="qui-row qui-bdr-bottom " v-if="this.subordinateLink!=null">
                <a href="#" class="qui-col100" >
                    <div class="qui-col10 qui-formlabel">
                        <a-icon type="link" />
                    </div>
                    <div class="qui-col80 qui-form-label link-style" width="100%"  height="400px" >
                        分享链接：
                        <a-button type="button" style="height: 0.4rem;line-height: 0.4rem;border-radius: 0.1rem;background: #1890ff;font-size: 0.20rem;border: none;color: rgb(255, 255, 255);"
                                  size="samll"
                                v-clipboard:copy="subordinateLink"
                                v-clipboard:success="onCopy"
                                v-clipboard:error="onError">点击复制
                        </a-button>
                        {{this.subordinateLink}}
                    </div>

                </a>
            </div>



            <div class="qui-pd-30 qui-mgt30 addborde" align="center">
                <a-button  class="qui-btn qui-btn-full" size="large"   @click="handleLogout" style="background-color:#09bb07;color:white"
                 :loading=confirmLoading   :disabled= confirmLoading    >退出登录
                </a-button>
            </div>

        </div>


    </div>
        <Bottom></Bottom>
        <userInfo-pic ref="userInfoPic" ></userInfo-pic>
        <update-userInfo ref="updateUserInfo" @ok="handleOK"></update-userInfo>
    </div>

</template>
 <script>
    import config from '@/config'
    import * as personalCenter from '@/api/personalCenter'
    import UserInfoPic from './UserInfoPic'
    import UpdateUserInfo from './UpdateUserInfo'
    import Bottom from './Bottom'
    import {mapActions, mapGetters} from 'vuex'
    import * as oauth from '@/api/oauth'
    import Loading from './Loading'
    import VueClipboard from 'vue-clipboard2'

     export default {

        components: {UserInfoPic,UpdateUserInfo,Bottom,Loading},
        data: function () {
            return {
                baseServerHostUrl:
                     process.env.NODE_ENV === 'development' ? config.baseServerHostUrl.dev : config.baseServerHostUrl.prod,
                userInfo: [],
                fileList: [],
                subordinateLink: null,
                confirmLoading: false,
                activeIndex:this.$route.params.indexNum,
                isDisplay:false,
        }
        },
        computed: {},
        beforeCreate() {
            //组件实例刚刚被创建，data计算之前
        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
            console.log("创建");
            personalCenter.getUserInfo().then(res => {
                   this.isDisplay=true;
                   //document.getElementById("loadingDiv").style.display="none";
                   this.userInfo = res.userBaseInfo;
                   this.subordinateLink = res.subordinateLink;
                   console.log(res);
            });
        },
        mounted() {

        },
        destroyed() {
        },

         methods: {
             showErrorMess(errorMess){
                 this.$notification.open({
                     message: '提示',
                     description:errorMess,
                 });
             },
             onCopy: function (e) {
                 this.showErrorMess('成功复制分享链接:  ' + e.text+"  到粘贴板")
             },
             onError: function (e) {
                 alert('复制失败')
             },

             ...mapActions(['Logout']),
             ...mapGetters(['oauthInfo']),
             modifyPwd(value){
                this.$router.push({name:'ModifyPwd'});
             },
             showPic:function(record){
               this.$refs.userInfoPic.show(record)
             },
             modifyUserInfo(updateName){
                this.$refs.updateUserInfo.init(updateName,this.userInfo);
             },
             handleOK(goFirst) {
              this.isDisplay=false;
              //document.getElementById("loadingDiv").style.display="block";
              personalCenter.getUserInfo().then(res => {
              //document.getElementById("loadingDiv").style.display="none";
              this.isDisplay=true;
                     this.userInfo = res.userBaseInfo;
                     this.subordinateLink = res.subordinateLink;
                     console.log(res);
              });
               },
             handleLogout:function(){
                   this.confirmLoading = true;
                   this.Logout({})
                       .then(res => {
                       this.confirmLoading = false;
                       let token = localStorage.getItem("token");
                       if(token != null){
                           localStorage.removeItem("token")
                       }
                       this.$router.push({name:'Login'});
               })
               .finally(function() {
                       this.confirmLoading = false
                   })

             },
         }
     }
 </script>

<style scoped>
    .link-style{
        overflow-x:scroll;
        overflow-y:auto;
    }
    h2{
        color:black
    }

    .qui-form-label, {
            font-size:15px
        }
</style>

