//修改雇员
<template>
    <div class="qui-layout">
        <a-form id="formLogin" class="user-layout-login"  :form="form" @submit="handleSubmit">
         <div class="qui-header qui-header-black gracolor">
            <h2 class="qui-fs35" >替换雇员</h2>
            <div class="left">
                <a href="javascript:history.go(-1)"><i class="iconfont icon-fanhui"></i></a>
            </div>

        </div>
            <Loading v-if="isDisplay==false"></Loading>
            <div v-if="isDisplay==true" id="employeeDiv" style="display:block">
                <div  v-for="(employeeData,index) in employeeList" :key="index">
                <div class="qui-row qui-bg-white qui-pd-0-30 qui-bdr-bottom" >
                    <div class="qui-col50 qui-fs30 qui-formlabel qui-text-black" data-index="index" >
                        <a-checkbox @change="onChange(index)" style="color: red;">点此替换：{{employeeData.name}}</a-checkbox>
                    </div>
                </div>

                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>姓名
                    </div>

                     <a-form-item >
                        <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                         <a-input  type="hidden"
                              v-decorator="[
                                  `employeeParam[${index}].id`,
                                  {
                                    rules: [{ required: true, message: '不能为空！' }],
                                    initialValue:employeeData.id
                                    }
                                ]"
                          />
                         <a-input class="qui-txt" placeholder="请输入姓名"
                                      v-decorator="[
                        `employeeParam[${index}].name`,
                        {
                          rules: [{ required: true, message: '不能为空！' }],
                          initialValue:employeeData.name
                          }
                      ]"
                            />
                        </div>
                    </a-form-item >

                </div>

                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>证件类型
                    </div>

                    <a-form-item >
                       <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                        <a-select  name="insuredRoleListIdType"  v-decorator="[
                            `employeeParam[${index}].idType`,
                            {
                              rules: [{ required: true, message: '不能为空！' }],
                              initialValue:employeeData.idType.name

                              }

                           ]">
                            <a-select-option value="sfz">身份证</a-select-option>
                            <a-select-option value="hkb" >户口簿</a-select-option>
                            <a-select-option value="hz" >护照</a-select-option>
                        </a-select>
                       </div>
                   </a-form-item >


                </div>
                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>证件号码
                    </div>

                     <a-form-item >
                            <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                                 <a-input class="qui-txt" placeholder="请输入证件号码"  @blur="calculationAge"
                                            v-decorator="[
                                `employeeParam[${index}].idCard`,
                                {
                                  rules: [{ required: true, message: '不能为空！' }],
                                  initialValue:employeeData.idCard
                                  }
                              ]"
                                    />
                            </div>
                    </a-form-item >
                </div>
                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>工种
                    </div>

                    <div class="qui-col60 qui-formlabel qui-text-grey" >
                        <a-form-item >
                            <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                             <a-input class="qui-txt"  placeholder="请输入工种"
                                        v-decorator="[
                            `employeeParam[${index}].jobType`,
                            {
                              rules: [{ required: true, message: '不能为空！' }],
                              initialValue:employeeData.jobType
                              }
                          ]"
                                />
                            </div>
                        </a-form-item >
                    </div>
                </div>
                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>性别
                    </div>

                    <a-form-item >
                       <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                        <a-select  name="insuredRoleList2sex"  v-decorator="[
                            `employeeParam[${index}].sex`,
                            {
                              rules: [{ required: true, message: '不能为空！' }],
                              initialValue:employeeData.sex.name
                              }
                           ]">
                            <a-select-option value="female">女</a-select-option>
                            <a-select-option value="male">男</a-select-option>
                        </a-select>
                       </div>
                   </a-form-item>
                </div>
                 <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>年龄
                    </div>

                    <a-form-item >
                        <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                         <a-input class="qui-txt"  placeholder="请填写年龄:16~65" name="ageInput"
                                    v-decorator="[
                        `employeeParam[${index}].age`,
                        {
                          rules: [{ required: true, message: '不能为空！' }],
                          initialValue:employeeData.age
                          }
                      ]"
                            />
                        </div>
                    </a-form-item >
                </div>
                </div>
            </div>



            <div  v-if="isDisplay==true" class="qui-pd-30 qui-mgt30 addborde" align="center">
                <a-form-item >
                     <a-input type="hidden"
                                v-decorator="[
                    'id',
                    {
                      rules: [{ required: true, message: '不能为空！' }],
                      initialValue:data.id
                      }
                  ]"
                        />
                </a-form-item >
                <a-button  class="qui-btn qui-btn-full" size="large"   @click="handleSubmit" style="background-color:#09bb07;color:white"
                     :loading=confirmLoading   :disabled= confirmLoading   >替换勾选雇员
                </a-button>

            </div>
        </a-form>

         <Bottom></Bottom>
    </div>
</template>


<script>
    import * as myOrder from '@/api/myOrder'
    import {uploaderHandle} from '@/api/uploader'
    import config from '@/config'
    import Bottom from './Bottom'
    import Loading from './Loading'



    export default {
        components: {Bottom,Loading},
        data() {
            return {
                baseServerHostUrl:
                                  process.env.NODE_ENV === 'development' ? config.baseServerHostUrl.dev : config.baseServerHostUrl.prod,
                visible: true,
                confirmLoading: false,
                form: this.$form.createForm(this),
                data:{},
                changeIndex:null,
                employeeList:null,
                isDisplay:false,

            }
        },
        beforeCreate() {

            //组件实例刚刚被创建，data计算之前

        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
            //对某些数据进行初始化
             console.log(this.$route.params.data);
            myOrder.getOrderDetail(this.$route.params.data.id).then(res => {
               this.isDisplay=true;
               //document.getElementById("loadingDiv").style.display="none";
               this.data = res;
               this.employeeList=res.employeeList;
               console.log(this.loadData);
            });
            console.log(this.data);
            this.changeIndex = new Map();
        },
        mounted() {

        },
        destroyed() {

        },
        methods: {
             //将需要修改的雇员下标存储到map集合中
             onChange(index){
                var target = event.target;
                if(target.checked){
                    this.changeIndex.set(index,index);
                }
                else{
                    this.changeIndex.delete(index);
                }
                console.log(this.changeIndex);
             },

             handleSubmit() {
                const {form: {validateFields}} = this;
                console.log("进入提交");
                this.confirmLoading = true;
                let _this = this
                validateFields((errors, values) => {
                    if (!errors) {
                    console.log("校验成功");
                    console.log(values);

                    //根据身份证号计算出年龄
                   _this.checkAgeWithIdCard();
                   //检查所有年龄是否符合要求
                   if(!_this.checkAllAgeRange()){
                    _this.showErrorMess("年龄有问题(范围16-65)");
                    this.confirmLoading = false;
                    return false;
                   }

                   //将需要修改的雇员信息整理传递到后台
                   var replaceEmployees = new Array();
                   console.log(_this.changeIndex);
                   for (var [key, value] of _this.changeIndex) {
                     replaceEmployees.push(values.employeeParam[key]);
                   }
                   console.log(replaceEmployees);
                   //如果用户未选择任何雇员被修改,弹出提示框
                   if(replaceEmployees.length==0){
                        this.showErrorMess("你未选择批改任何雇员，批改前请勾选");
                        this.confirmLoading = false;
                        return ;
                   }

                   values.employeeParam=replaceEmployees;
                   console.log("更改后雇员信息");
                   console.log(values);

                   console.log("进入方法前");

                    myOrder.updateEmployees(values)
                        .then(res => {
                        _this.confirmLoading = false;
                        this.$router.push({name:'MyOrder'});
                })
                .finally(function() {
                        _this.confirmLoading = false
                    })
                } else {
                    _this.confirmLoading = false
                }
            })
            },


            calculationAge:function(event){
                var target = event.target;
                var domName = target.getAttribute("id");
                var index = domName.replace(/[^0-9]/ig,"");
                //判断身份类型是不是身份证，是则按照身份证号计算年龄
                var idTypeDomId = "employeeParam["+index+"].idType";
                var idTypeDom = document.getElementById(idTypeDomId);

                if(idTypeDom.value=="sfz"){
                    var ageId = "employeeParam["+index+"].age";
                    var ageDom = document.getElementById(ageId);
                    console.log("idcard的value"+idTypeDomId);
                    var idCardId = "employeeParam["+index+"].idCard";
                    var idCardDom = document.getElementById(idCardId);
                    var identityTest = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                    //校验身份证号是否合法

                    if(!(identityTest.test(idCardDom.value)&&(idCardDom.value.length==15||idCardDom.value.length==18))){
                        idCardDom.style.color="red";
                    }
                    else{
                        idCardDom.style.color="black";
                    }
                    //如果合法,调用年龄计算函数计算年龄
                    var ageNum = this.getAge(idCardDom.value);
                    this.form.setFieldsValue({["employeeParam["+index+"].age"]: ageNum,});
                    if(ageNum>65||ageNum<16){
                        ageDom.style.color="red";
                    }
                    else{
                        ageDom.style.color="black";
                    }

                }

           },
            checkAgeWithIdCard:function() {
              console.log("进入年龄身份证号判断");
              var idTypeDomArray = document.getElementsByName("insuredRoleListIdType");
              var identityTest = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
              var flag = true;
              for(var i=0;i<idTypeDomArray.length;i++){
                  //如果是身份证
                  if(idTypeDomArray[i].value=='sfz'){
                      var idTypeId=idTypeDomArray[i].getAttribute("id");
                      var index = idTypeId.replace(/[^0-9]/ig,"");
                      var idCardDomId = "employeeParam["+index+"].idCard";
                      var idCardDom = document.getElementById(idCardDomId);
                      var ageId = "employeeParam["+index+"].age";
                      var ageDom = document.getElementById(ageId);
                      //如果身份证号填写错误
                      if(!(identityTest.test(idCardDom.value)&&(idCardDom.value.length==15||idCardDom.value.length==18))){
                           idCardDom.style.color="red";
                           flag = false;
                       }
                       //根据身份证号计算年龄,如果计算出的年龄大于65或者小于16，将身份证号标红
                       else{
                           var ageNum = this.getAge(idCardDom.value);
                           this.form.setFieldsValue({["employeeParam["+index+"].age"]: ageNum,});
                           if(ageNum>65||ageNum<16){
                               idCardDom.style.color="red";
                               ageDom.style.color="red";
                           }
                           else{
                               idCardDom.style.color="black";
                               ageDom.style.color="black";
                               console.log("正常检测");
                           }

                       }
                  }

              }
              return flag;
          },
          //校验所有年龄是否在规定的取值范围之内16-65
            checkAllAgeRange:function(){
              var ageDomArray = document.getElementsByName("ageInput");
              var flag = true;
              for(var i=0;i<ageDomArray.length;i++){
                  //如果年龄取值范围不符合要求(虽然在进行所有校验之前,将年龄设置为系统计算出来的值，但是，这个地方取的值还是旧值)
                  if(ageDomArray[i].value>65||ageDomArray[i].value<16){
                      console.log(ageDomArray[i].getAttribute("id")+"年龄不符合要求"+ageDomArray[i].value);
                      ageDomArray[i].style.color="red";
                      flag=false;
                  }
                  else{
                     ageDomArray[i].style.color="black";
                  }
              }
              return flag;
            },
             //年龄计算
          getAge:function(identityCard) {
               var len = (identityCard + "").length;
               if (len == 0) {
                   return 0;
               } else {
                   if ((len != 15) && (len != 18))//身份证号码只能为15位或18位其它不合法
                   {
                       return 0;
                   }
               }
               var strBirthday = "";
               if (len == 18)//处理18位的身份证号码从号码中得到生日和性别代码
               {
                   strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
               }
               if (len == 15) {
                   strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
               }
               //时间字符串里，必须是“/”
               var birthDate = new Date(strBirthday);
               var nowDateTime = new Date();
               var age = nowDateTime.getFullYear() - birthDate.getFullYear();
               //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
               if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
                   age--;
               }
               return age;
          },
          showErrorMess(errorMess){
              this.$notification.open({
                   message: '提示',
                   description:errorMess,
                 });
             },
        }
    }
</script>



