//开发票
<template>
    <div class="qui-layout">
        <a-form id="formLogin" class="user-layout-login"  :form="form" @submit="handleSubmit">
         <div class="qui-header qui-header-black gracolor">
            <h2 class="qui-fs35">开具发票</h2>
            <div class="left">
                <a href="javascript:history.go(-1)"><i class="iconfont icon-fanhui"></i></a>
            </div>

         </div>

            <Loading v-if="isDisplay==false"></Loading>

            <div v-if="isDisplay==true">
            <div  class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>发票抬头
                    </div>

                     <a-form-item >
                        <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                         <a-input class="qui-txt" placeholder="请输入发票抬头"
                                      v-decorator="[
                        'invoiceTitle',
                        {
                          rules: [{ required: true, message: '发票抬头不能为空！' }],
                          initialValue:data.invoiceTitle
                          }
                      ]"
                            />
                        </div>
                    </a-form-item >
                </div>
                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>纳税人识别号
                    </div>

                     <a-form-item >
                        <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                         <a-input class="qui-txt" placeholder="请输入纳税人识别号"
                                      v-decorator="[
                        'taxpayerIdentificationNumber',
                        {
                          rules: [{ required: true, message: '纳税人识别号不能为空！' }],
                          initialValue:data.taxpayerIdentificationNumber
                          }
                      ]"
                            />
                        </div>
                    </a-form-item >
                </div>
                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>手机号
                    </div>

                     <a-form-item >
                        <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                         <a-input class="qui-txt" placeholder="请输入手机号"
                                      v-decorator="[
                        'phone',
                        {
                          rules: [{ required: true, message: '手机号不能为空！' }],
                          initialValue:data.phone
                          }
                      ]"
                            />
                        </div>
                    </a-form-item >
                </div>
                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important">*</font>邮箱
                    </div>

                     <a-form-item >
                        <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                         <a-input class="qui-txt" placeholder="请输入邮箱"
                                      v-decorator="[
                        'email',
                        {
                          rules: [{ required: true, message: '邮箱不能为空！' }],
                          initialValue:data.email
                          }
                      ]"
                            />
                        </div>
                    </a-form-item >
                </div>
                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important"></font>地址+手机号
                    </div>

                     <a-form-item >
                        <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                         <a-input class="qui-txt" placeholder="请输入地址+手机号"
                                      v-decorator="[
                        'addressAndPhone',
                        {

                          }
                      ]"
                            />
                        </div>
                    </a-form-item >
                </div>
                <div class="qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30">
                    <div class="qui-formlabel qui-col40 qui-text-black">
                        <font class="qui-important"></font>开户行+账号
                    </div>

                     <a-form-item >
                        <div class="qui-col60 qui-formlabel qui-text-grey" align="right">
                         <a-input class="qui-txt" placeholder="请输入开户行+账号"
                                      v-decorator="[
                        'bankAccount',
                        {

                          }
                      ]"
                            />
                        </div>
                    </a-form-item >
                </div>
            <div class="qui-row contract qui-fs25">
                <label class="">
                    <a id="infoA" href="javascript:;" style="color: grey;">提示：开户行+账号 和  地址+手机号需同时填写，缺一不可，或二者都不填写</a>
                </label>
            </div>

            <div class="qui-pd-30 qui-mgt30 addborde" align="center">
                <a-form-item >
                     <a-input type="hidden"
                                v-decorator="[
                    'orderId',
                    {
                      rules: [{ required: true, message: '订单Id不能为空！' }],
                      initialValue:data.orderId
                      }
                  ]"
                        />
                </a-form-item >
                <a-button  class="qui-btn qui-btn-full" size="large"   @click="handleSubmit"  style="background-color:#09bb07;color:white"
                     :loading=loadingCir   :disabled= confirmLoading   id="submitButton" >确定
                </a-button>

            </div>
            </div>
        </a-form>

         <Bottom></Bottom>

    </div>
</template>


<script>
    import * as invoice from '@/api/invoice'
    import config from '@/config'
    import Bottom from './Bottom'
    import Loading from './Loading'


    export default {
        components: {Bottom,Loading},
        data() {
            return {
                baseServerHostUrl:
                                  process.env.NODE_ENV === 'development' ? config.baseServerHostUrl.dev : config.baseServerHostUrl.prod,
                visible: true,
                confirmLoading: true,
                loadingCir: false,
                form: this.$form.createForm(this),
                data: {},
                orderId:this.$route.params.orderId,
                isDisplay:false,
            }
        },
        beforeCreate() {

            invoice.getPreInfo(this.$route.params.orderId).then(res => {
            this.confirmLoading = false;
            //document.getElementById("submitButton").style.display="block";
            this.isDisplay=true;
            //document.getElementById("loadingDiv").style.display="none";
            console.log(res);
            this.data = res;
        });

        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
            //对某些数据进行初始化
            console.log(this.data);
        },
        mounted() {

        },
        destroyed() {

        },
        methods: {
             handleSubmit() {
                const {form: {validateFields}} = this;

                this.confirmLoading = true;
                this.loadingCir = true;
                let _this = this;

                validateFields((errors, values) => {
                    if (!errors) {
                    if(_this.checkTogether()==false){
                        console.log("二缺一");
                        _this.confirmLoading = false;
                        _this.loadingCir = false;
                        return ;
                    }
                    console.log(values);
                    invoice.createInvoice(values)
                        .then(res => {
                    _this.confirmLoading = false;
                    _this.loadingCir = false;
                    //弹出提示框
                   _this.showErrorMess("发票开具成功，在我的订单中下载即可");

                    _this.$router.push({name:'MyOrder'});

                })
                .finally(function() {
                        _this.confirmLoading = false
                        _this.loadingCir = false
                    })
                } else {
                    _this.confirmLoading = false
                    _this.loadingCir = false
                }
            })
            },
            //检验开户行+账号 与  手机号+地址 是不是一起填写
            checkTogether(){
                var bankAccountDom = document.getElementById("bankAccount");

                var addressAndPhone = document.getElementById("addressAndPhone");
                if(bankAccountDom.value.replace(/(^s*)|(s*$)/g, "").length>0){
                    if(addressAndPhone.value.replace(/(^s*)|(s*$)/g, "").length==0){
                        document.getElementById("infoA").style.color="red";
                        return false;
                    }
                    else{
                        document.getElementById("infoA").style.color="grey";
                        return true;
                    }
                }
                if(addressAndPhone.value.replace(/(^s*)|(s*$)/g, "").length>0){
                    if(bankAccountDom.value.replace(/(^s*)|(s*$)/g, "").length==0){
                       document.getElementById("infoA").style.color="red";
                       return false;
                   }
                   else{
                       document.getElementById("infoA").style.color="grey";
                       return true;
                   }
                }
            },

            //弹出提示框
           showErrorMess(errorMess){
            this.$notification.open({
                 message: '提示',
                 description:errorMess,
               });
           },

        }
    }
</script>



