var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qui-layout"},[_vm._m(0),_c('h2',{staticClass:"qui-text-green qui-fs30 qui-mgt30",attrs:{"align":"center"}},[_vm._v("企业注册")]),_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"qui-bg-white qui-pdl15 qui-mgt30",attrs:{"id":"outDiv"}},[_c('div',{staticClass:"qui-row qui-pd-0-30"},[_c('a-form-item',[_c('div',{staticClass:"qui-col25 qui-bdr-bottom"},[_c('div',{staticClass:"qui-formlabel"},[_vm._v(" 公司名称 ")])]),_c('div',{staticClass:"qui-col75 qui-bdr-bottom qui-pdl15"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'companyName',
              {
                rules: [{ required: true, message: '不能为空！' }],
                }
                ]),expression:"[\n              'companyName',\n              {\n                rules: [{ required: true, message: '不能为空！' }],\n                }\n                ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入公司名称"}})],1)])],1),_c('div',{staticClass:"qui-row qui-pd-0-30"},[_c('a-form-item',[_c('div',{staticClass:"qui-col25 qui-bdr-bottom"},[_c('div',{staticClass:"qui-formlabel"},[_vm._v(" 证件类型 ")])]),_c('div',{staticClass:"qui-col65 qui-bdr-bottom qui-pdl15"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['idType', {
                 rules: [{required: true, message: '不能为空！'}],
                 initialValue:'tysh'
                 }]),expression:"['idType', {\n                 rules: [{required: true, message: '不能为空！'}],\n                 initialValue:'tysh'\n                 }]"}],attrs:{"placeholder":"请选择证件类型"}},[_c('a-select-option',{attrs:{"value":"tysh"}},[_vm._v("统一社会信用代码")]),_c('a-select-option',{attrs:{"value":"sfz"}},[_vm._v("身份证")])],1)],1)])],1),_c('div',{staticClass:"qui-row qui-pd-0-30"},[_c('a-form-item',[_c('div',{staticClass:"qui-col25 qui-bdr-bottom"},[_c('div',{staticClass:"qui-formlabel"},[_vm._v(" 证件编码 ")])]),_c('div',{staticClass:"qui-col75 qui-bdr-bottom qui-pdl15"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'idCard',
                  {
                    rules: [{ required: true, message: '不能为空！' }],
                    }
                    ]),expression:"[\n                  'idCard',\n                  {\n                    rules: [{ required: true, message: '不能为空！' }],\n                    }\n                    ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入证件编码"}})],1)])],1),_c('div',{staticClass:"qui-row qui-pd-0-30"},[_c('a-form-item',[_c('div',{staticClass:"qui-col25 qui-bdr-bottom"},[_c('div',{staticClass:"qui-formlabel"},[_vm._v(" 手机号 ")])]),_c('div',{staticClass:"qui-col75 qui-bdr-bottom qui-pdl15"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'tel',
                  {
                    rules: [{ required: true, message: '不能为空！' }],
                    }
                    ]),expression:"[\n                  'tel',\n                  {\n                    rules: [{ required: true, message: '不能为空！' }],\n                    }\n                    ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入联系方式"}})],1)])],1),_c('div',{staticClass:"qui-row qui-pd-0-30"},[_c('a-form-item',[_c('div',{staticClass:"qui-col25 qui-bdr-bottom"},[_c('div',{staticClass:"qui-formlabel"},[_vm._v(" 电子邮箱 ")])]),_c('div',{staticClass:"qui-col75 qui-bdr-bottom qui-pdl15"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {
                         rules: [
                          {
                            type: 'email', message: '邮箱格式不正确',
                          }
                         ],
                         }]),expression:"['email', {\n                         rules: [\n                          {\n                            type: 'email', message: '邮箱格式不正确',\n                          }\n                         ],\n                         }]"}],staticClass:"qui-txt",attrs:{"type":"text","placeholder":"请输入邮箱"}})],1)])],1),_c('div',{staticClass:"qui-row qui-pd-0-30"},[_c('a-form-item',[_c('div',{staticClass:"qui-col25 qui-bdr-bottom"},[_c('div',{staticClass:"qui-formlabel"},[_vm._v(" 公司地址 ")])]),_c('div',{staticClass:"qui-col75 qui-bdr-bottom qui-pdl15"},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'address',
              {
                rules: [{ required: true, message: '不能为空！' }],
                }
                ]),expression:"[\n              'address',\n              {\n                rules: [{ required: true, message: '不能为空！' }],\n                }\n                ]"}],staticClass:"qui-txt",attrs:{"autosize":{ minRows: 2, maxRows: 6 },"placeholder":"请输入公司地址"}})],1)])],1),_c('div',{staticClass:"qui-row qui-pd-0-30",staticStyle:{"display":"none"},attrs:{"id":"callBackUrlDiv"}},[_c('a-form-item',[_c('div',{staticClass:"qui-col25 qui-bdr-bottom"},[_c('div',{staticClass:"qui-formlabel"},[_vm._v(" 回调地址 ")])]),_c('div',{staticClass:"qui-col75 qui-bdr-bottom qui-pdl15"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['callBackUrl', {
                         rules: [
                          {
                             message: '不能为空!',
                          }
                         ],
                         }]),expression:"['callBackUrl', {\n                         rules: [\n                          {\n                             message: '不能为空!',\n                          }\n                         ],\n                         }]"}],staticClass:"qui-txt",attrs:{"type":"text","placeholder":"请输入回调地址","id":"callBackUrlInput"}})],1)])],1)]),_c('div',{staticClass:"qui-bg-white qui-mgt20 qui-pd-0-30"},[_c('div',{staticClass:"qui-mgt20 qui-fs20"},[_c('font',{attrs:{"color":"#000000"}},[_vm._v("上传营业执照和法人身份证")]),_c('font',{attrs:{"color":"#999"}},[_vm._v("(正反面)")])],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"md":8}},[(_vm.pic1IsUploading==true)?_c('a-spin',{attrs:{"tip":"图片正在上传","size":"middle"}}):_vm._e(),_c('a-form-item',{attrs:{"label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[(_vm.pic1)?_c('img',{staticStyle:{"max-width":"100px","max-height":"100px"},attrs:{"shape":"square","size":"small","src":_vm.baseServerHostUrl+'/uploader/showImage?src='+_vm.pic1}}):_vm._e(),_c('br'),_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `pic1`,
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: '请上传营业执照',
                                    },
                                  ],
                                  initialValue:_vm.pic1
                                },
                              ]),expression:"[\n                            `pic1`,\n                                {\n                                  rules: [\n                                    {\n                                      required: true,\n                                      message: '请上传营业执照',\n                                    },\n                                  ],\n                                  initialValue:pic1\n                                },\n                              ]"}],attrs:{"multiple":false,"fileList":_vm.fileList,"beforeUpload":_vm.beforeUploadAvatarPic1,"accept":"image/*"},on:{"change":_vm.handleChange}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_c('span',[_vm._v("营业执照"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])],1)],1)],1)],1),_c('a-col',{attrs:{"md":8}},[(_vm.pic2IsUploading==true)?_c('a-spin',{attrs:{"tip":"图片正在上传","size":"middle"}}):_vm._e(),_c('a-form-item',{attrs:{"label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[(_vm.pic2)?_c('img',{staticStyle:{"max-width":"100px","max-height":"100px"},attrs:{"shape":"square","size":"small","src":_vm.baseServerHostUrl+'/uploader/showImage?src='+_vm.pic2}}):_vm._e(),_c('br'),_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `pic2`,
                              ]),expression:"[\n                            `pic2`,\n                              ]"}],attrs:{"multiple":false,"fileList":_vm.fileList,"beforeUpload":_vm.beforeUploadAvatarPic2,"accept":"image/*"},on:{"change":_vm.handleChange}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_c('span',[_vm._v("身份证正面")])],1)],1)],1)],1),_c('a-col',{attrs:{"md":8}},[(_vm.pic3IsUploading==true)?_c('a-spin',{attrs:{"tip":"图片正在上传","size":"middle"}}):_vm._e(),_c('a-form-item',{attrs:{"label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[(_vm.pic3)?_c('img',{staticStyle:{"max-width":"100px","max-height":"100px"},attrs:{"shape":"square","size":"small","src":_vm.baseServerHostUrl+'/uploader/showImage?src='+_vm.pic3}}):_vm._e(),_c('br'),_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `pic3`,
                              ]),expression:"[\n                            `pic3`,\n                              ]"}],attrs:{"multiple":false,"fileList":_vm.fileList,"beforeUpload":_vm.beforeUploadAvatarPic3,"accept":"image/*"},on:{"change":_vm.handleChange}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_c('span',[_vm._v("身份证反面")])],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"qui-pd-30 qui-mgt30 addborde"},[_c('a-button',{staticClass:"qui-btn qui-btn-full",staticStyle:{"background-color":"#09bb07","color":"white"},attrs:{"size":"large","htmlType":"submit","loading":_vm.confirmLoading,"disabled":_vm.confirmLoading}},[_vm._v("提交注册 ")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qui-header qui-header-black gracolor"},[_c('h2',{staticClass:"qui-fs35"},[_vm._v("注册")])])
}]

export { render, staticRenderFns }