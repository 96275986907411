var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qui-layout"},[_c('a-form',{staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"qui-header qui-header-black gracolor"},[_c('h2',{staticClass:"qui-fs35"},[_vm._v("开具发票")]),_c('div',{staticClass:"left"},[_c('a',{attrs:{"href":"javascript:history.go(-1)"}},[_c('i',{staticClass:"iconfont icon-fanhui"})])])]),(_vm.isDisplay==false)?_c('Loading'):_vm._e(),(_vm.isDisplay==true)?_c('div',[_c('div',{staticClass:"qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col40 qui-text-black"},[_c('font',{staticClass:"qui-important"},[_vm._v("*")]),_vm._v("发票抬头 ")],1),_c('a-form-item',[_c('div',{staticClass:"qui-col60 qui-formlabel qui-text-grey",attrs:{"align":"right"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'invoiceTitle',
                    {
                      rules: [{ required: true, message: '发票抬头不能为空！' }],
                      initialValue:_vm.data.invoiceTitle
                      }
                  ]),expression:"[\n                    'invoiceTitle',\n                    {\n                      rules: [{ required: true, message: '发票抬头不能为空！' }],\n                      initialValue:data.invoiceTitle\n                      }\n                  ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入发票抬头"}})],1)])],1),_c('div',{staticClass:"qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col40 qui-text-black"},[_c('font',{staticClass:"qui-important"},[_vm._v("*")]),_vm._v("纳税人识别号 ")],1),_c('a-form-item',[_c('div',{staticClass:"qui-col60 qui-formlabel qui-text-grey",attrs:{"align":"right"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'taxpayerIdentificationNumber',
                    {
                      rules: [{ required: true, message: '纳税人识别号不能为空！' }],
                      initialValue:_vm.data.taxpayerIdentificationNumber
                      }
                  ]),expression:"[\n                    'taxpayerIdentificationNumber',\n                    {\n                      rules: [{ required: true, message: '纳税人识别号不能为空！' }],\n                      initialValue:data.taxpayerIdentificationNumber\n                      }\n                  ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入纳税人识别号"}})],1)])],1),_c('div',{staticClass:"qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col40 qui-text-black"},[_c('font',{staticClass:"qui-important"},[_vm._v("*")]),_vm._v("手机号 ")],1),_c('a-form-item',[_c('div',{staticClass:"qui-col60 qui-formlabel qui-text-grey",attrs:{"align":"right"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'phone',
                    {
                      rules: [{ required: true, message: '手机号不能为空！' }],
                      initialValue:_vm.data.phone
                      }
                  ]),expression:"[\n                    'phone',\n                    {\n                      rules: [{ required: true, message: '手机号不能为空！' }],\n                      initialValue:data.phone\n                      }\n                  ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入手机号"}})],1)])],1),_c('div',{staticClass:"qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col40 qui-text-black"},[_c('font',{staticClass:"qui-important"},[_vm._v("*")]),_vm._v("邮箱 ")],1),_c('a-form-item',[_c('div',{staticClass:"qui-col60 qui-formlabel qui-text-grey",attrs:{"align":"right"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'email',
                    {
                      rules: [{ required: true, message: '邮箱不能为空！' }],
                      initialValue:_vm.data.email
                      }
                  ]),expression:"[\n                    'email',\n                    {\n                      rules: [{ required: true, message: '邮箱不能为空！' }],\n                      initialValue:data.email\n                      }\n                  ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入邮箱"}})],1)])],1),_c('div',{staticClass:"qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col40 qui-text-black"},[_c('font',{staticClass:"qui-important"}),_vm._v("地址+手机号 ")],1),_c('a-form-item',[_c('div',{staticClass:"qui-col60 qui-formlabel qui-text-grey",attrs:{"align":"right"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'addressAndPhone',
                    {

                      }
                  ]),expression:"[\n                    'addressAndPhone',\n                    {\n\n                      }\n                  ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入地址+手机号"}})],1)])],1),_c('div',{staticClass:"qui-row qui-bg-white qui-bdr-bottom qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col40 qui-text-black"},[_c('font',{staticClass:"qui-important"}),_vm._v("开户行+账号 ")],1),_c('a-form-item',[_c('div',{staticClass:"qui-col60 qui-formlabel qui-text-grey",attrs:{"align":"right"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'bankAccount',
                    {

                      }
                  ]),expression:"[\n                    'bankAccount',\n                    {\n\n                      }\n                  ]"}],staticClass:"qui-txt",attrs:{"placeholder":"请输入开户行+账号"}})],1)])],1),_c('div',{staticClass:"qui-row contract qui-fs25"},[_c('label',{},[_c('a',{staticStyle:{"color":"grey"},attrs:{"id":"infoA","href":"javascript:;"}},[_vm._v("提示：开户行+账号 和 地址+手机号需同时填写，缺一不可，或二者都不填写")])])]),_c('div',{staticClass:"qui-pd-30 qui-mgt30 addborde",attrs:{"align":"center"}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'orderId',
                {
                  rules: [{ required: true, message: '订单Id不能为空！' }],
                  initialValue:_vm.data.orderId
                  }
              ]),expression:"[\n                'orderId',\n                {\n                  rules: [{ required: true, message: '订单Id不能为空！' }],\n                  initialValue:data.orderId\n                  }\n              ]"}],attrs:{"type":"hidden"}})],1),_c('a-button',{staticClass:"qui-btn qui-btn-full",staticStyle:{"background-color":"#09bb07","color":"white"},attrs:{"size":"large","loading":_vm.loadingCir,"disabled":_vm.confirmLoading,"id":"submitButton"},on:{"click":_vm.handleSubmit}},[_vm._v("确定 ")])],1)]):_vm._e()],1),_c('Bottom')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }