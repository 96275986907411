var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qui-layout"},[_vm._m(0),_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('div',{staticClass:"qui-row qui-bdr-bottom qui-bg-white qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col30"},[_vm._v(" 旧密码 ")]),_c('div',{staticClass:"qui-col60"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'oldPwd',
          {
            rules: [{ required: true, message: '旧密码不能为空！' }],
            }
            ]),expression:"[\n          'oldPwd',\n          {\n            rules: [{ required: true, message: '旧密码不能为空！' }],\n            }\n            ]"}],staticClass:"qui-txt",attrs:{"type":"password","placeholder":"请输入旧密码"}})],1)])]),_c('a-form-item',[_c('div',{staticClass:"qui-row qui-bdr-bottom qui-bg-white qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col30"},[_vm._v(" 新密码 ")]),_c('div',{staticClass:"qui-col60"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'newPwd',
              {
                rules: [{ required: true, message: '新密码不能为空！' }],
                }
                ]),expression:"[\n              'newPwd',\n              {\n                rules: [{ required: true, message: '新密码不能为空！' }],\n                }\n                ]"}],staticClass:"qui-txt",attrs:{"type":"password","placeholder":"请输入新密码"}})],1)])]),_c('a-form-item',[_c('div',{staticClass:"qui-row qui-bdr-bottom qui-bg-white qui-pd-0-30"},[_c('div',{staticClass:"qui-formlabel qui-col30"},[_vm._v(" 确认密码 ")]),_c('div',{staticClass:"qui-col60"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'confirmPwd',
          {
            rules: [{ required: true, message: '确认密码不能为空！' }],
            }
            ]),expression:"[\n          'confirmPwd',\n          {\n            rules: [{ required: true, message: '确认密码不能为空！' }],\n            }\n            ]"}],staticClass:"qui-txt",attrs:{"type":"password","placeholder":"确认密码"}})],1)])]),_c('div',{staticClass:"qui-pd-30 qui-mgt30 addborde"},[_c('a-button',{staticClass:"qui-btn qui-btn-full",staticStyle:{"background-color":"#09bb07","color":"white"},attrs:{"size":"large","htmlType":"submit","loading":_vm.confirmLoading,"disabled":_vm.confirmLoading}},[_vm._v("提交 ")])],1)],1),_c('Bottom')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qui-header qui-header-black gracolor"},[_c('h2',{staticClass:"qui-fs35"},[_vm._v("修改密码")]),_c('div',{staticClass:"left"},[_c('a',{attrs:{"href":"javascript:history.go(-1)"}},[_c('i',{staticClass:"iconfont icon-fanhui"})])])])
}]

export { render, staticRenderFns }