import {axios} from '@/utils/request'

export const getCaptcha = () => {
    return axios.request({
        url: '/getCaptcha',
        method: 'GET',
    })
};

export const login = ({loginAccount, loginPassword, captcha, accessToken}) => {
    const data = {
        loginAccount, loginPassword, captcha, accessToken
    };
    return axios.request({
        url: '/login',
        data: data,
        method: 'POST',
    })
};

export const logout = () => {
    return axios.request({
        url: '/logout',
        method: 'GET',
    })
};

export const getOauthInfo = () => {
    return axios.request({
        url: '/oauth/getOauthInfo',
        method: 'GET',
    })
};


export const getOauthInfoHandler = () => {
    return axios.request({
        url: '/getOauthInfo',
        method: 'GET',
    })
};



export const changePassword = (data) => {
    return axios.request({
        url: '/oauth/changePassword',
        data: data,
        method: 'POST',
    })
};
