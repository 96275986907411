import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import Login from '@/views/Login.vue' //登陆
import Registered from '@/views/Registered.vue' //注册
import CheckVerification from '@/views/CheckVerification.vue'  //校验验证码
import PersonalCenter from '@/views/PersonalCenter.vue'  //个人中心
import ModifyPwd from '@/views/ModifyPwd.vue'  //修改密码
import ResetPwd from '@/views/ResetPwd.vue'  //重置密码
import ModifyNickname from '@/views/ModifyNickname.vue'  //修改昵称
import Insured from '@/views/Insured.vue'  //投保
import FjcyryzrxItem from '@/views/FjcyryzrxItem.vue'  //附加险
import JzjgzrxItem from '@/views/JzjgzrxItem.vue'   //责任险
import OrderDetail from '@/views/OrderDetail.vue'   //订单详情
import ModifyEmployee from '@/views/ModifyEmployee.vue'   //批改雇员
import Invoice from '@/views/Invoice.vue'   //开发票
import EnterInsurance from '@/views/EnterInsurance.vue'  //录入保单
import MyOrder from '@/views/MyOrder.vue'  //我的订单
import Index from '@/views/Index.vue'  //首页

import Introduction from '@/views/Introduction.vue'  //方案介绍

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Introduction
        },
        {
            path: '/Login',
            name: 'Login',
            component: Login
        },
        {
            path: '/Register',
            name: 'Register',
            component: Registered
        },
        {
            path: '/CheckVerification',
            name: 'CheckVerification',
            component: CheckVerification
        },
        {
            path: '/PersonalCenter',
            name: 'PersonalCenter',
            component: PersonalCenter,
        },
        {
            path: '/ModifyPwd',
            name: 'ModifyPwd',
            component: ModifyPwd
        },
        {
            path: '/ResetPwd',
            name: 'ResetPwd',
            component: ResetPwd
        },
        {
            path: '/ModifyNickname',
            name: 'ModifyNickname',
            component: ModifyNickname
        },
        {
            path: '/Insured',
            name: 'Insured',
            component: Insured
        },
        {
            path: '/FjcyryzrxItem',
            name: 'FjcyryzrxItem',
            component: FjcyryzrxItem
        },
        {
            path: '/JzjgzrxItem',
            name: 'JzjgzrxItem',
            component: JzjgzrxItem
        },
        {
            path: '/OrderDetail',
            name: 'OrderDetail',
            component: OrderDetail
        },
        {
            path: '/ModifyEmployee',
            name: 'ModifyEmployee',
            component: ModifyEmployee
        },
        {
            path: '/Invoice',
            name: 'Invoice',
            component: Invoice
        },
        {
            path: '/EnterInsurance',
            name: 'EnterInsurance',
            component: EnterInsurance
        },
        {
            path: '/MyOrder',
            name: 'MyOrder',
            component: MyOrder
        },
        {
            path: '/Index',
            name: 'Index',
            component: Index
        },
        {
            path: '/Introduction',
            name: 'Introduction',
            component: Introduction
        },
        {
            path: '/Registered',
            name: 'Registered',
            component: Registered
        },
        {
            path: '/PaySuccess',
            name: 'PaySuccess',
            component: () => import('@/views/PaySuccess'),
        },
    ]
})
