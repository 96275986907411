<template>
    <a-modal
            :title="'编辑'"
            :width="550"
            :height="500"
            v-model="visible"
            :footer='null'>
        <div>
            <a-form :form="form">
                <div class="qui-bg-white qui-pd-0-30">
                <a-form-item v-if="this.updateName=='companyName'">
                    <div class="qui-row qui-bdr-bottom">
                        <div class="qui-col100">
                            <a-input class="qui-txt" placeholder="请输入公司名"
                                      v-decorator="[
                          'companyName',
                          {
                            rules: [{ required: true, message: '公司名不能为空！' }],
                            initialValue:this.userInfo.companyName
                            }
                            ]"
                            />
                        </div>
                    </div>
                </a-form-item>
                <a-form-item v-if="this.updateName=='tel'">
                    <div class="qui-row qui-bdr-bottom">
                        <div class="qui-col100">
                            <a-input class="qui-txt" placeholder="请输手机号"
                                      v-decorator="[
                          'tel',
                          {
                            rules: [{ required: true, message: '手机号不能为空！' }],
                            initialValue:userInfo.tel
                            }
                            ]"
                            />
                        </div>
                    </div>
                </a-form-item>
                <a-form-item v-if="this.updateName=='email'">
                    <div class="qui-row qui-bdr-bottom">
                        <div class="qui-col100">
                            <a-input class="qui-txt" placeholder="请输入电子邮箱"
                                      v-decorator="[
                          'email',
                          {
                            rules: [{ type: 'email', message: '邮箱格式不正确' }],
                            initialValue:userInfo.email
                            }
                            ]"
                            />
                        </div>
                    </div>
                </a-form-item>
                <a-form-item v-if="this.updateName=='address'">
                    <div class="qui-row qui-bdr-bottom">
                        <div class="qui-col100">
                            <a-input class="qui-txt" placeholder="请输入公司地址"
                                      v-decorator="[
                          'address',
                          {
                            rules: [{ required: true, message: '公司地址不能为空！' }],
                            initialValue:userInfo.address
                            }
                            ]"
                            />
                        </div>
                    </div>
                </a-form-item>
                <a-form-item v-if="this.updateName=='idCard'">
                    <div class="qui-row qui-bdr-bottom">
                        <div class="qui-col100">
                            <a-select
                                    v-decorator="['idType', {
                             rules: [{required: true, message: '不能为空！'}],
                             initialValue:userInfo.idType.name
                             }]"
                                    placeholder="请选择证件类型"
                            >
                                <a-select-option value="tysh">统一社会信用代码</a-select-option>
                                <a-select-option value="sfz">身份证</a-select-option>
                            </a-select>
                        </div>
                    </div>
                </a-form-item>
                <a-form-item v-if="this.updateName=='idCard'">
                    <div class="qui-row qui-bdr-bottom">
                        <div class="qui-col100">
                            <a-input class="qui-txt" placeholder="请输入证件号码"
                                      v-decorator="[
                          'idCard',
                          {
                            rules: [{ required: true, message: '证件号码不能为空！' }],
                            initialValue:userInfo.idCard
                            }
                            ]"
                            />
                        </div>
                    </div>
                </a-form-item>
                <a-form-item v-if="this.updateName=='pic1'">
                    <div class="qui-row qui-bdr-bottom">
                        <div class="qui-col100">
                            <a-upload :multiple="false" :fileList="fileList" :beforeUpload="beforeUploadAvatarPic1" accept="image/*" @change="handleChange"
                               v-decorator="[
                            `pic1`,
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: '不能为空',
                                    },
                                  ],
                                },
                              ]">
                             <a-button>
                                 <a-icon type="upload"></a-icon>
                                 <span v-if="userInfo.pic1">重新上传</span>
                                 <span v-else>点击上传</span>
                             </a-button>
                         </a-upload>
                         <img v-if="userInfo.pic1" shape="square"  size="large" :src="baseServerHostUrl+'/uploader/showImage?src='+pic1" style="max-width:300px;max-height:100px" />
                        </div>
                    </div>
                </a-form-item>
                <a-form-item v-if="this.updateName=='pic2'">
                    <div class="qui-row qui-bdr-bottom">
                        <div class="qui-col100">
                            <a-upload :multiple="false" :fileList="fileList" :beforeUpload="beforeUploadAvatarPic2" accept="image/*" @change="handleChange"
                               v-decorator="[
                            `pic2`,
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: '不能为空',
                                    },
                                  ],
                                },
                              ]">
                             <a-button>
                                 <a-icon type="upload"></a-icon>
                                 <span v-if="userInfo.pic2">重新上传</span>
                                 <span v-else>点击上传</span>
                             </a-button>
                         </a-upload>
                         <img v-if="userInfo.pic2" shape="square"  size="small" :src="baseServerHostUrl+'/uploader/showImage?src='+pic2" style="max-width:300px;max-height:100px" />
                        </div>
                    </div>
                </a-form-item>
                <a-form-item v-if="this.updateName=='pic3'">
                    <div class="qui-row qui-bdr-bottom">
                        <div class="qui-col100">
                            <a-upload :multiple="false" :fileList="fileList" :beforeUpload="beforeUploadAvatarPic3" accept="image/*" @change="handleChange"
                               v-decorator="[
                            `pic3`,
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: '不能为空',
                                    },
                                  ],
                                },
                              ]">
                             <a-button>
                                 <a-icon type="upload"></a-icon>
                                 <span v-if="userInfo.pic3">重新上传</span>
                                 <span v-else>点击上传</span>
                             </a-button>
                         </a-upload>
                         <img v-if="userInfo.pic3" shape="square"  size="small" :src="baseServerHostUrl+'/uploader/showImage?src='+pic3" style="max-width:300px;max-height:100px" />
                        </div>
                    </div>
                </a-form-item>

                </div>
                 <div class="qui-pd-30 qui-mgt30 addborde">
                    <a-button  class="qui-btn qui-btn-full" size="large"   @click="handleSubmit" style="background-color:#09bb07;color:white"
                    :loading=confirmLoading   :disabled= confirmLoading    >提交
                    </a-button>
                </div>
            </a-form>
        </div>
    </a-modal>
</template>
<script>

    import {ACCESS_TOKEN} from '@/store/mutation-types'
    import config from '@/config'
    import Vue from 'vue'
    import {uploaderHandle} from '@/api/uploader'
    import {updateUserInfo}  from '@/api/personalCenter'

    export default {

        data: function () {
            return {
                form: this.$form.createForm(this),
                visible: false,
                baseServerHostUrl:
                    process.env.NODE_ENV === 'development' ? config.baseServerHostUrl.dev : config.baseServerHostUrl.prod,
                pic:null,
                updateName:null,
                confirmLoading: false,
                userInfo:null,
                pic1:null,
                pic2:null,
                pic3:null,
                fileList: [],

            }
        },
        computed: {
            accessToken: function () {
                return Vue.ls.get(ACCESS_TOKEN);
            }
        },
        beforeCreate() {
        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
        },
        mounted() {

        },
        destroyed() {
        },
        methods: {
            handleSubmit: function (e){

                const {form: {validateFields}} = this;
                this.confirmLoading = true;
                console.log("我进入提及");
                let _this = this;
                console.log("提交");
                validateFields((errors, values) => {
                console.log(errors);
                    if (!errors) {
                    //如果身份类型是身份证，校验身份证号位数
                    if(values.idType=='sfz'){
                       var identityTest = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                       //如果身份证号填写错误
                       if(!(identityTest.test(values.idCard)&&(values.idCard.length==15||values.idCard.length==18))){
                            _this.showErrorMess("身份证号格式错误");
                            _this.confirmLoading = false;
                            return ;
                        }
                    }
                    values.pic1 = this.pic1;
                    values.pic2 = this.pic2;
                    values.pic3 = this.pic3;

                    updateUserInfo(values)
                    .then(res => {

                        _this.visible = false;
                        _this.confirmLoading = false;
                        _this.$emit('ok');
                    })
                    .finally(function() {
                            _this.confirmLoading = false
                        })
                    } else {
                        _this.confirmLoading = false
                    }
                    })
                },

            init(name,data) {
                this.updateName = name;
                this.userInfo = data;
                this.visible = true;
                this.pic1=data.pic1;
                this.pic2=data.pic2;
                this.pic3=data.pic3;

                console.log(this.userInfo);
                console.log("名称"+this.updateName);
            },
            beforeUploadAvatarPic1(importFile) {
                let _this = this;
                _this.confirmLoading = true;
                uploaderHandle(importFile).then(res => {
                    _this.pic1=res.src;
                    _this.confirmLoading = false;
                    _this.showPicSuccess("图片上传成功");
                    console.log(_this.pic1);
                });
                return false
            },
            beforeUploadAvatarPic2(importFile) {
                let _this = this;
                _this.confirmLoading = true;
                uploaderHandle(importFile).then(res => {
                    _this.pic2=res.src;
                    _this.confirmLoading = false;
                    _this.showPicSuccess("图片上传成功");
                     console.log(_this.pic2);
                });
                return false
            },
            beforeUploadAvatarPic3(importFile) {
                let _this = this;
                _this.confirmLoading = true;
                uploaderHandle(importFile).then(res => {
                    _this.pic3=res.src;
                    _this.confirmLoading = false;
                    _this.showPicSuccess("图片上传成功");
                     console.log(_this.pic3);
                });
                return false
            },
            handleChange(info) {
                let fileList = [...info.fileList];
                fileList = fileList.slice(1);//设置上传数量
                // 2. read from response and show file link
                fileList = fileList.map((file) => {
                    if (file.response) {
                        // Component will show file.url as link
                        file.url = file.response.url;
                    }
                    return file;
                });
                this.fileList = fileList
            },
             //弹出提示框
           showErrorMess(errorMess){
            this.$notification.open({
                 message: '提示',
                 description:errorMess,
               });
           },
           showPicSuccess(mess){
               this.$notification.open({
                    message: '提示',
                    description:mess,
                    duration: 1,
                  });
              },
        },
        watch: {}
    }
</script>
