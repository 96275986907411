//投保
<template>
    <div class="qui-layout">
        <div class="qui-header qui-header-black gracolor">
            <h2 class="qui-fs35">在线投保</h2>


        </div>

        <Loading></Loading>

        <div class="qui-pd-0-30">
            <ul v-for="data in loadData" :value="data.id" :key="data.id">
                <li class="qui-mgt20" v-if="data.insuranceProjectList.length>0">
                    <div class="" @click="placeOrder(data.id)">
                        <img  class="img29" :src="baseServerHostUrl+'/uploader/showImage?src='+data.pic" />
                    </div>
                    <div class="qui-row qui-mgt30" >
                        <div class="qui-col80">
                            <div class="qui-fs30">
                                {{data.title}}
                            </div>
                            <font color="#999" class="qui-fs23 plan" v-if="data.info.length > 22">
                                {{data.info.substring(0,22)}}...
                            </font>
                             <font color="#999" class="qui-fs23 plan" v-if="data.info.length <= 22">
                                {{data.info}}
                            </font>
                        </div>
                        <div class="qui-col20" align="right" >
                            <a href="javascript:;" class="qui-btn qui-btn-sm qui-pd-0-30 qui-mgt10" @click="placeOrder(data.id)">下单</a>
                        </div>
                    </div>
                    <hr style="height:6px;border:none;border-top:1px solid #09bb07;" />
                </li>
            </ul>
        </div>

        <Bottom ></Bottom>
    </div>
</template>

<script>
    import * as insurance from '@/api/insurance'
    import config from '@/config'
    import Bottom from './Bottom'
    import Loading from './Loading'


    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, "", document.URL);
    window.addEventListener('popstate', function () {
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, "", document.URL);
    });

    export default {
        components: {Bottom,Loading},
        data: function () {
            return {
                baseServerHostUrl:
                                    process.env.NODE_ENV === 'development' ? config.baseServerHostUrl.dev : config.baseServerHostUrl.prod,

                loadData: [],
                activeIndex:this.$route.params.indexNum,
        }
        },
        computed: {},
        beforeCreate() {
            //组件实例刚刚被创建，data计算之前
        },
        created() {
            //组件实例创建完成，属性已绑定，但DOM还未生成
            console.log("创建");
            insurance.getAllInsuranceDetail().then(res => {
                   document.getElementById("loadingDiv").style.display="none";
                   this.loadData = res;
                   console.log(this.loadData);
            });
        },
        mounted() {

        },
        destroyed() {
        },


        methods: {
            placeOrder:function(insuranceId){
                this.$router.push({name:'EnterInsurance',params:{insuranceId:insuranceId}});
            },

        }
    }
</script>
